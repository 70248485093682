import {gql} from "@apollo/client";

export const SAVE_CALCULATION_COLLECTION = gql`
    mutation SaveCalculationCollection($id: ID!, $title: String!) {
        saveCalculationCollection(calculationCollectionId: $id, title: $title) {
            id
            title
        }
    }
`;
