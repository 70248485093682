import {gql} from "@apollo/client";

export const PUMP_LIST = gql`
    query PumpList($start: Int, $limit: Int, $pumpType: [PumpType], $frequency: PumpFrequency) {
        pumpList(start: $start, limit: $limit, pumpType: $pumpType, frequency: $frequency) {
            total
            pumps {
                  id
                  name
                  flangeDiameter
                  backingPumpCount
                  pumpType
                  mainPump {
                    id
                    name
                    pumpType
                    flangeDiameter
                  }
                  backingPump {
                    id
                    name
                    pumpType
                  }
            }
        }
    }
`;
