import React from "react";
import {Steps} from "antd"
import "../../styles/steps.less"
import {useTranslation} from "react-i18next";
import { Grid } from 'antd';
import './index.css'
import {Step} from "../../types";

interface Props {
    stepConfig: Step[]
    index: number,
    onChangeStep: (step: any) => void,
    finishedSteps: string[],
    disabled?: boolean,
}

export default function StepProgressBar({stepConfig, index, onChangeStep, finishedSteps, disabled}: Props) {
    const { Step } = Steps;
    const {t} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const lastFinishedStep = finishedSteps.slice(-1)[0];
    const nextStepIndex = stepConfig.findIndex((e) => e.id === lastFinishedStep) + 1;

    const getStepStatus = (stepIndex: number, stepId: string) => {
        // if all steps are finished the result also will be marked finished
        if (finishedSteps.length === stepConfig.length - 1) {
            return "finish";
        } else if (stepIndex === index && !finishedSteps.includes(stepId)) {
            return "process";
        } else if (finishedSteps.includes(stepId)) {
            return "finish";
        }else {
            return "wait";
        }

    }

    if (!screens.lg) {
        const currentPosition = index;
        const completePercentage = Math.round((finishedSteps.length / (stepConfig.length - 1)) * 100)

        return <div className="wrapper">
            <div className="flex-row flex-space-between step-bar-text-wrapper">
                <span className="font-bold">{t('Step')} {index + 1} {t('of')} {stepConfig.length}</span>
                <span>{completePercentage}% {t('completed')}</span>
            </div>
            <ul className="progressbar">
                {stepConfig.map((item, index) => {
                    return <li
                        key={index}
                        className={`link ${currentPosition === index ? 'current' : ''} ${getStepStatus(index, item.id)}`}
                        onClick={() => onChangeStep(index)}
                    />
                })}
            </ul>
        </div>
    }

    return (
        <div>
            <Steps
                progressDot
                current={index}
                onChange={onChangeStep}
                size={screens.lg ? 'default' : 'small'}
            >
                {stepConfig.map((item, dex) => {
                    return <Step
                        title={screens.lg ? t(item.progressName) : dex+1}
                        key={item.id}
                        disabled={disabled || (!finishedSteps.includes(item.id) && dex > nextStepIndex)}
                        status={getStepStatus(dex, item.id)}
                    />
                })}
            </Steps>
        </div>
    )
}
