import React from 'react';
import {Row, Col, Grid} from "antd";
import {useTranslation} from "react-i18next";
import FinishedStepsOverview from "../FinishedStepsOverview";
import {stepConfig} from "../../config/fixedPumpSystemChamberEvacuationStepConfig";

interface Props {
    calculationValues: any,
}

const CalculationValuesList = ({calculationValues}: Props) => {

    const {t} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const chamberStepChamberVolume = stepConfig.find(step => step.id === "chamberStepChamberVolume")
    const chamberStepDesorptionRate = stepConfig.find(step => step.id === "chamberStepDesorptionRate")
    const chamberStepLeakRate = stepConfig.find(step => step.id === "chamberStepLeakRate")
    const pumpSystem = stepConfig.find(step => step.id === "pumpSystem")

    return (
        <Row>
            <Col span={screens.lg ? 6 : 12}>
                <div className={"font-bold mb-8"}>{t('Chamber')}</div>
                <FinishedStepsOverview
                    calculationValues={calculationValues}
                    calculationMode={"fixedPumpSystem"}
                    calculationType={"chamberEvacuation"}
                    finishedSteps={[]}
                    stepConfig={stepConfig}
                    specificStepSummaryList={chamberStepChamberVolume}
                />
            </Col>
            <Col span={screens.lg ? 6 : 12}>
                <div className={"font-bold mb-8"}>{t('Desorption')}</div>
                <FinishedStepsOverview
                    calculationValues={calculationValues}
                    calculationMode={"fixedPumpSystem"}
                    calculationType={"chamberEvacuation"}
                    finishedSteps={[]}
                    stepConfig={stepConfig}
                    specificStepSummaryList={chamberStepDesorptionRate}
                />
            </Col>
            <Col span={screens.lg ? 6 : 12} className={screens.lg ? "" : "mt-32"}>
                <div className={"font-bold mb-8"}>{t('Leak rate')}</div>
                <FinishedStepsOverview
                    calculationValues={calculationValues}
                    calculationMode={"fixedPumpSystem"}
                    calculationType={"chamberEvacuation"}
                    finishedSteps={[]}
                    stepConfig={stepConfig}
                    specificStepSummaryList={chamberStepLeakRate}
                />
            </Col>
            <Col span={screens.lg ? 6 : 12} className={screens.lg ? "" : "mt-32"}>
                <div className={"font-bold mb-8"}>{t('Pump')}</div>
                <FinishedStepsOverview
                    calculationValues={calculationValues}
                    calculationMode={"fixedPumpSystem"}
                    calculationType={"chamberEvacuation"}
                    finishedSteps={[]}
                    stepConfig={stepConfig}
                    specificStepSummaryList={pumpSystem}
                />
            </Col>
        </Row>
    );
}

export default CalculationValuesList;
