import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import i18n from 'i18next';
import {getJwtExpireTime} from "../utils/parseJWT";

import {getCurrentEnv} from "../utils/getCurrentEnv";

const httpLink = createHttpLink({
    uri: '/graphql',
    fetch,
});

const authLink = setContext((_, { headers }) => {
    const token = window.sessionStorage.getItem('token');
    const tokenExpireTime = getJwtExpireTime(token);
    const env = getCurrentEnv();

    // testing and staging are htaccess protected and browsers like Safari will override the Authorization header with the Basic one from the htaccess
    if (env !== "production") {
        return {
            headers: {
                ...headers,
                "authorization-gql": token && tokenExpireTime*1000 > Date.now() ? `Bearer ${token}`: "",
                "accept-language": i18n.language || "en"
            }
        }
    }

    return {
        headers: {
            ...headers,
            Authorization: token && tokenExpireTime*1000 > Date.now() ? `Bearer ${token}`: "",
            "accept-language": i18n.language || "en"
        }
    }
});

const VacuumCalculatorApolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        addTypename: false
    }),
});

export default VacuumCalculatorApolloClient;
