import {Step} from "../types";

export const stepConfig: Step[] = [
    {
        id: "chamberStepChamberVolume",
        title: "Volume calculation of the chamber",
        subtitle: "vc_chamberStepChamberVolume_subtitle",
        progressName: "Chamber",
        component: "ChamberVolumeContent",
        validate: ["chamberVolumeGeometry", "chamberSurface", "chamberVolume"],
        svgHighlight: "chamber",
        attributes: [
            {
                id: "chamberVolumeGeometry",
                label: "Geometry",
                options: ["cubic", "cylindrical", "spherical", "custom"],
                type: "string"
            },
            {
                id: "chamberVolumeLength",
                label: "Length",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                dependingOn: ["cubic"]
            },
            {
                id: "chamberVolumeWidth",
                label: "Width",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                dependingOn: ["cubic"]
            },
            {
                id: "chamberVolumeHeight",
                label: "Height",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                dependingOn: ["cubic", "cylindrical"]

            },
            {
                id: "chamberVolumeRadius",
                label: "Radius",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                dependingOn: ["cylindrical", "spherical"]
            },
            {
                id: "chamberSizeUnits",
                label: "Unit",
                options: ["cm", "mm", "m", "in",],
                type: "string",
                dependingOn: ["cubic", "cylindrical", "spherical"]
            },
            {
                id: "chamberVolume",
                label: "Calculated volume",
                units: [
                    {display: "l", value: "l"},
                    {display: "m³", value: "m3"},
                    {display: "cm³", value: "cm3"},
                    {display: "mm³", value: "mm3"},
                ],
                type: "number"
            },
            {
                id: "chamberSurface",
                label: "Calculated surface",
                units: [
                    {display: "m²", value: "m2"},
                    {display: "cm²", value: "cm2"},
                    {display: "mm²", value: "mm2"},
                    {display: "in²", value: "in2"},
                ],
                type: "number"
            }
        ]
    },
    {
        id: "chamberStepDesorptionRate",
        title: "The desorption rate (outgassing rate)",
        subtitle: [
            "vc_chamberStepDesorptionRate_subtitle_1",
            "vc_chamberStepDesorptionRate_subtitle_2",
            "vc_chamberStepDesorptionRate_subtitle_3",
            "vc_chamberStepDesorptionRate_subtitle_4"
        ],
        progressName: "Desorption",
        component: "ChamberDesorptionRateContent",
        validate: ["desorption"],
        attributes: [
            {
                id: "desorptionRate",
                label: "Desorptionsrate",
                units: [
                    {display: "mbar*l/(s*cm²)", value: "mbar_l_s_cm2"},
                    {display: "Pa*m³/(s*m²)", value: "pa_m3_s_m2"},
                    {display: "Torr*l/(s*cm²)", value: "torr_l_s_cm2"},
                ],
                type: "number"
            },
            {
                id: "desorptionSurfaceName",
                label: "Name",
                type: "string"
            },
            {
                id: "desorptionSurfaceSize",
                label: "Size",
                units: [
                    {display: "m²", value: "m2"},
                    {display: "cm²", value: "cm2"},
                    {display: "mm²", value: "mm2"},
                    {display: "in²", value: "in2"},
                ],
                type: "number"
            }
        ]
    },
    {
        id: "chamberStepLeakRate",
        title: "The leak rate",
        subtitle: "vc_chamberStepLeakRate_subtitle",
        progressName: "ChamberLeakRate",
        component: "ChamberLeakRateContent",
        validate: ["leakRate"],
        attributes: [
            {
                id: "leakRate",
                label: "Leak rate",
                units: [
                    {display: "mbar*l/s", value: "mbar_l_s"},
                    {display: "Pa*m³/s", value: "pa_m3_s"},
                ],
                type: "number"
            }
        ]
    },
    {
        id: "pumpSystem",
        title: "Configure pump system",
        progressName: "Pump",
        component: "ChamberPumpSelectionContent",
        validate: ["pumpFrequency", "backingPumpCount", "backingPumpCount"],
        attributes: [
            {
                id: "pumpFrequency",
                label: "Mains frequency",
                options: ["f50hz", "f60hz"],
                type: "string",
                facetFilterId: "0",
                col: 8,
                colMobile: 24
            },
            {
                id: "pumpingStationCount",
                label: "Count",
                type: "number",
                col: 3,
                colMobile: 8,
                optional: true
            },
            {
                id: "pumpingStation",
                label: "Predefined pumping stations",
                type: "string",
                pumpQueryTypes: ["turboPumpingStation", "rootsPumpingStation"],
                col: 13,
                colMobile: 16,
                optional: true
            },
            {
                id: "turboPipeLength",
                label: "Length",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                pumpSystemType: "turboPipe",
                pumpSystemLabel: "Pipe (High Vacuum Pump)",
                col: 12
            },
            {
                id: "turboPipeDiameter",
                label: "Diameter",
                units: [
                    {display: "mm", value: "mm"},
                    {display: "cm", value: "cm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                pumpSystemType: "turboPipe",
                pumpSystemLabel: "Pipe",
                optional: true,
                col: 12
            },
            {
                id: "turboPumpCount",
                label: "Count",
                type: "number",
                pumpSystemType: "turboPump",
                pumpSystemLabel: "High Vacuum Pump",
                col: 5
            },
            {
                id: "turboPump",
                label: "Pump",
                type: "string",
                pumpSystemType: "turboPump",
                pumpSystemLabel: "High Vacuum Pump",
                pumpQueryTypes: ["turboPump"],
                col: 19
            },
            {
                id: "primaryPipeLength",
                label: "Length",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                pumpSystemType: "primaryPipe",
                pumpSystemLabel: "Pipe",
                col: 8
            },
            {
                id: "primaryPipeDiameter",
                label: "Diameter",
                units: [
                    {display: "mm", value: "mm"},
                    {display: "cm", value: "cm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                pumpSystemType: "primaryPipe",
                pumpSystemLabel: "Pipe",
                optional: true,
                col: 8
            },
            {
                id: "primaryPipeElbowCount",
                label: "No. elbows",
                type: "number",
                pumpSystemType: "primaryPipe",
                pumpSystemLabel: "Pipe",
                optional: true,
                col: 8
            },
            {
                id: "rootsPumpCount",
                label: "Count",
                type: "number",
                pumpSystemType: "rootsPump",
                pumpSystemLabel: "Roots Booster",
                col: 5
            },
            {
                id: "rootsPump",
                label: "Pump",
                type: "string",
                pumpSystemType: "rootsPump",
                pumpSystemLabel: "Roots Booster",
                pumpQueryTypes: ["rootsPump"],
                col: 19
            },
            {
                id: "backingPumpCount",
                label: "Count",
                type: "number",
                minCount: 1,
                pumpSystemType: "backingPump",
                pumpSystemLabel: "Backing Pump",
                col: 5
            },
            {
                id: "backingPump",
                label: "Pump",
                type: "string",
                pumpSystemType: "backingPump",
                pumpSystemLabel: "Backing Pump",
                pumpQueryTypes: ["primaryPumpDry", "primaryPumpOil", "multiStageRoots"],
                col: 19
            },
        ]
    },
    {
        id: "chamberResult",
        title: "",
        subtitle: "",
        progressName: "ChamberResult",
        component: "",
        validate: [],
        attributes: []
    }
]
