export const unitConfig: any = [
    {
        display: "Pa",
        value: "Pa"
    },
    {
        display: "mbar",
        value: "mbar"
    },
    {
        display: "Torr",
        value: "Torr"
    },
    {
        display: "hPa",
        value: "hpa"
    },
    {
        display: "s",
        value: "s"
    },
    {
        display: "min",
        value: "min"
    },
    {
        display: "h",
        value: "h"
    },
    {
        display: "d",
        value: "d"
    },
    {
        display: "m",
        value: "m"
    },
    {
        display: "mm",
        value: "mm"
    },
    {
        display: "cm",
        value: "cm"
    },
    {
        display: "in",
        value: "in"
    },
    {
        display: "m²",
        value: "m2"
    },
    {
        display: "cm²",
        value: "cm2"
    },
    {
        display: "mm²",
        value: "mm2"
    },
    {
        display: "in²",
        value: "in2"
    },
    {
        display: "m³",
        value: "m3"
    },
    {
        display: "l",
        value: "l"
    },
    {
        display: "cm³",
        value: "cm3"
    },
    {
        display: "mm³",
        value: "mm3"
    },
    {
        display: "mbar*l/(s*cm²)",
        value: "mbar_l_s_cm2"
    },
    {
        display: "Pa*m³/(s*m²)",
        value: "pa_m3_s_m2"
    },
    {
        display: "Torr*l/(s*cm²)",
        value: "torr_l_s_cm2"
    },
    {
        display: "mbar*l/s",
        value: "mbar_l_s"
    },
    {
        display: "Pa*m³/s",
        value: "pa_m3_s"
    },
    {
        display: "m³/h",
        value: "m3_h"
    },
    {
        display: "l/s",
        value: "l_s"
    },
    {
        display: "sccm",
        value: "sccm"
    }
]

