import React from "react";
import {Button, Collapse} from "@dreebit/pv-components";
import {useTranslation} from "react-i18next";
import {Step, StepAttribute} from "../../types";
import CollapseAttributeList from "../CollapseAttributeList";
import {useHistory} from "react-router-dom";
import _ from "lodash";
import './index.css';
import {Grid} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {DataActionType} from "../../redux/reducers";
import {useDispatch, useSelector} from "react-redux";
import {getOpenedSummaryTabs} from "../../redux/selectors/openenedSummaryTabs";
import {useQuery} from "@apollo/client";
import {PUMP} from "../../graphql/queries/pump";

interface Props {
    calculationValues: any,
    calculationId?: string,
    calculationMode: string,
    calculationType: string,
    resultButton?: {
        show: boolean,
        onClick: () => void
    },
    finishedSteps: string[],
    editable?: boolean
    stepConfig: Step[],
    specificStepSummaryList?: Step
}

export default function FinishedStepsOverview({calculationValues, calculationId, calculationMode, calculationType, resultButton, finishedSteps, editable = true, stepConfig, specificStepSummaryList}: Props) {

    const dispatch = useDispatch();
    const openedTabs = useSelector(getOpenedSummaryTabs);
    const {t} = useTranslation();
    const history = useHistory();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const turboPump = useQuery(PUMP, {
        variables: {
            id: _.get(calculationValues, 'turboPump.value')
        },
        skip: !calculationValues.turboPump
    })
    const rootsPump = useQuery(PUMP, {
        variables: {
            id: _.get(calculationValues, 'rootsPump.value')
        },
        skip: !calculationValues.rootsPump
    })
    const backingPump = useQuery(PUMP, {
        variables: {
            id: _.get(calculationValues, 'backingPump.value')
        },
        skip: !calculationValues.backingPump
    })
    const pumpingStation = useQuery(PUMP, {
        variables: {
            id: _.get(calculationValues, 'pumpingStation.value')
        },
        skip: !calculationValues.pumpingStation
    })

    const _changeRoute = (stepId: string) => {
        if (calculationId) {
            history.push(`/${calculationMode}/${calculationType}/${calculationId}/${stepId}`)
        }
    }

    const _generateSummary = (attribute: StepAttribute, valueAndUnit: any) => {
        if (!valueAndUnit.value) {
            return null;
        }

        let reading = valueAndUnit?.value;

        // match pump ids with corresponding name
        if (attribute.id === "turboPump") {
            reading = _.get(turboPump, 'data.pump.name', valueAndUnit?.value)
        }
        if (attribute.id === "rootsPump") {
            reading = _.get(rootsPump, 'data.pump.name', valueAndUnit?.value)
        }
        if (attribute.id === "backingPump") {
            reading = _.get(backingPump, 'data.pump.name', valueAndUnit?.value)
        }
        if (attribute.id === "pumpingStation") {
            reading = _.get(pumpingStation, 'data.pump.name', valueAndUnit?.value)
        }

        if (typeof reading === "number" && reading < 1e-2) {
            reading = reading.toExponential()
        }

        if (valueAndUnit?.unit) {
            reading = reading + ' ' + t(valueAndUnit.unit)
        }

        if (typeof reading === "boolean") {
            return {
                label: attribute.label,
                reading: reading ?  <CheckOutlined /> : <CloseOutlined />,
                category: _.get(attribute, 'category', null )
            }
        }

        if (["turboPump", "rootsPump", "backingPump", "pumpingStation"].includes(attribute.id)) {
            const getCountString = () => {
                if (attribute.id === "turboPump" && calculationValues.turboPumpCount?.value) {
                    return `${calculationValues.turboPumpCount?.value}x `
                }
                if (attribute.id === "rootsPump" && calculationValues.rootsPumpCount?.value) {
                    return `${calculationValues.rootsPumpCount?.value}x `
                }
                if (attribute.id === "backingPump" && calculationValues.backingPumpCount?.value) {
                    return `${calculationValues.backingPumpCount?.value}x `
                }
                if (attribute.id === "pumpingStation" && calculationValues.pumpingStationCount?.value) {
                    return `${calculationValues.pumpingStationCount?.value}x `
                }

                return ''
            }

            return {
                label: _.get(attribute, 'pumpSystemLabel', attribute.label),
                reading: getCountString() + reading,
                category: _.get(attribute, 'category', null )
            }
        }

        if (["turboPumpCount", "rootsPumpCount", "backingPumpCount", "pumpingStationCount"].includes(attribute.id)) {
            return null;
        }

        return {
            label: attribute.label,
            reading: reading,
            category: _.get(attribute, 'category', null )
        };
    }

    const _handleOpenedTabs = (id: string) => {
        dispatch({
            type: DataActionType.SET_SUMMARY_TAB,
            payload: id
        });
    }

    if (specificStepSummaryList) {

        const attributes: { label: string; reading: any; }[] = [];

        specificStepSummaryList.attributes.forEach(attribute => {
            if (calculationValues && !_.isNil(calculationValues[attribute.id])) {

                const summary = _generateSummary(attribute, calculationValues[attribute.id]);

                if (!_.isNil(summary)) {
                    attributes.push(summary)
                }
            } else if (specificStepSummaryList.id === "chamberStepDesorptionRate" && calculationValues.desorption) {
                const summary = _generateSummary(attribute, calculationValues.desorption[0][attribute.id]);

                if (!_.isNil(summary)) {
                    attributes.push(summary)
                }
            }
        })

        return <div>
            <CollapseAttributeList
                attributes={attributes || []}
            />
            <div className={"mt-8"}>
                {
                    specificStepSummaryList.id === "chamberStepDesorptionRate" && calculationValues.desorption ?
                        calculationValues.desorption?.length === 2 ?
                            <span className={'full-width moreEntries'}>
                            {`...${calculationValues.desorption.length - 1} ${t('more entry')}`}
                        </span>
                            : calculationValues.desorption?.length > 2 ?
                                <span className={'full-width moreEntries'}>
                                {`...${calculationValues.desorption.length - 1} ${t('more entries')}`}
                            </span>
                                : null
                        : null
                }
            </div>
        </div>
    }

    return (
        <div>
            {
                finishedSteps.length ?
                    <div className={screens.lg ? '' : 'flex-row flex-center'}>
                        <h5>{t('Your input data')}:</h5>
                    </div>
                : null
            }
            {
                finishedSteps.map((item) => {
                    let finishedStep = stepConfig.find(step => step.id === item)

                    const attributes: { label: string; reading: any; }[] = [];

                    if (finishedStep) {
                        finishedStep.attributes.forEach(attribute => {
                            if (calculationValues && !_.isNil(calculationValues[attribute.id])) {

                                const summary = _generateSummary(attribute, calculationValues[attribute.id]);

                                if (!_.isNil(summary)) {
                                    attributes.push(summary)
                                }
                            } else if (item === "chamberStepDesorptionRate" && calculationValues.desorption) {
                                const summary = _generateSummary(attribute, calculationValues.desorption[0][attribute.id]);

                                if(!_.isNil(summary)) {
                                    attributes.push(summary)
                                }
                            }
                        })

                        return <div className={'mb-16'} key={finishedStep.id}>
                            <Collapse
                                className={"finished-step-collapse"}
                                title={t(finishedStep.progressName || '')}
                                onEditClick={() => _changeRoute(finishedStep?.id || '')}
                                stepId={finishedStep.id}
                                editable={editable}
                                onChange={() => _handleOpenedTabs(finishedStep?.id || '')}
                                defaultActiveKey={openedTabs}
                            >
                                <div className={'flex-col full-width'}>

                                    <CollapseAttributeList
                                        attributes={attributes || []}
                                    />
                                    {
                                        item === "chamberStepDesorptionRate" ?
                                            calculationValues.desorption?.length === 2 ?
                                                <span className={'full-width moreEntries'}>
                                                {`...${calculationValues.desorption.length - 1} ${t('more entry')}`}
                                            </span>
                                                : calculationValues.desorption?.length > 2 ?
                                                    <span className={'full-width moreEntries'}>
                                                {`...${calculationValues.desorption.length - 1} ${t('more entries')}`}
                                            </span>
                                                    : null
                                            : null
                                    }
                                </div>
                            </Collapse>
                        </div>
                    }
                })
            }
            {
                resultButton?.show ?
                    <Button
                        type={"tertiary"}
                        className={`full-width mt-16 mb-32`}
                        onClick={resultButton.onClick}
                        icon={"arrow_forward"}
                    >
                        {t('To the result')}
                    </Button>
                : null
            }

        </div>
    )
}
