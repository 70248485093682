import {Step} from "../types";

export const stepConfig: Step[] = [
    {
        id: "chamberStepTargetPressure",
        title: "Target pressure and target time",
        subtitle: "vc_chamberStepTargetPressure_subtitle",
        progressName: "Target pressure & time",
        component: "ChamberTargetPressureContent",
        validate: ["targetPressure", "ignoreTargetTime"],
        attributes: [
            {
                id: "targetPressure",
                label: "Target pressure",
                units: [
                    {display: "mbar", value: "mbar"},
                    {display: "Torr", value: "Torr"},
                    {display: "hPa", value: "hpa"},
                    {display: "Pa", value: "Pa"},
                ],
                type: "number"
            },
            {
                id: "targetTime",
                label: "Target time",
                units: [
                    {display: "s", value: "s"},
                    {display: "min", value: "min"},
                    {display: "h", value: "h"},
                    {display: "d", value: "d"}
                ],
                type: "number"
            },
            {
                id: "ignoreTargetTime",
                label: "Target time not relevant",
                type: "boolean",
                defaultValue: false,
            }
        ]
    },
    {
        id: "chamberStepChamberVolume",
        title: "Volume calculation of the chamber",
        subtitle: "vc_chamberStepChamberVolume_subtitle",
        progressName: "Chamber",
        component: "ChamberVolumeContent",
        validate: ["chamberVolumeGeometry", "chamberSurface", "chamberVolume"],
        svgHighlight: "chamber",
        attributes: [
            {
                id: "chamberVolumeGeometry",
                label: "Geometry",
                options: ["cubic", "cylindrical", "spherical", "custom"],
                type: "string"
            },
            {
                id: "chamberVolumeLength",
                label: "Length",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                dependingOn: ["cubic"]
            },
            {
                id: "chamberVolumeWidth",
                label: "Width",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                dependingOn: ["cubic"]
            },
            {
                id: "chamberVolumeHeight",
                label: "Height",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                dependingOn: ["cubic", "cylindrical"]

            },
            {
                id: "chamberVolumeRadius",
                label: "Radius",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                dependingOn: ["cylindrical", "spherical"]
            },
            {
                id: "chamberSizeUnits",
                label: "Unit",
                options: ["cm", "mm", "m", "in",],
                type: "string",
                dependingOn: ["cubic", "cylindrical", "spherical"]
            },
            {
                id: "chamberVolume",
                label: "Calculated volume",
                units: [
                    {display: "l", value: "l"},
                    {display: "m³", value: "m3"},
                    {display: "cm³", value: "cm3"},
                    {display: "mm³", value: "mm3"},
                ],
                type: "number"
            },
            {
                id: "chamberSurface",
                label: "Calculated surface",
                units: [
                    {display: "m²", value: "m2"},
                    {display: "cm²", value: "cm2"},
                    {display: "mm²", value: "mm2"},
                    {display: "in²", value: "in2"},
                ],
                type: "number"
            }
        ]
    },
    {
        id: "chamberStepDesorptionRate",
        title: "The desorption rate (outgassing rate)",
        subtitle: [
            "vc_chamberStepDesorptionRate_subtitle_1",
            "vc_chamberStepDesorptionRate_subtitle_2",
            "vc_chamberStepDesorptionRate_subtitle_3",
            "vc_chamberStepDesorptionRate_subtitle_4"
        ],
        progressName: "Desorption",
        component: "ChamberDesorptionRateContent",
        validate: ["desorption"],
        attributes: [
            {
                id: "desorptionRate",
                label: "Desorptionsrate",
                units: [
                    {display: "mbar*l/(s*cm²)", value: "mbar_l_s_cm2"},
                    {display: "Pa*m³/(s*m²)", value: "pa_m3_s_m2"},
                    {display: "Torr*l/(s*cm²)", value: "torr_l_s_cm2"},
                ],
                type: "number"
            },
            {
                id: "desorptionSurfaceName",
                label: "Name",
                type: "string"
            },
            {
                id: "desorptionSurfaceSize",
                label: "Size",
                units: [
                    {display: "m²", value: "m2"},
                    {display: "cm²", value: "cm2"},
                    {display: "mm²", value: "mm2"},
                    {display: "in²", value: "in2"},
                ],
                type: "number"
            }
        ]
    },
    {
        id: "chamberStepLeakRate",
        title: "The leak rate",
        subtitle: "vc_chamberStepLeakRate_subtitle",
        progressName: "ChamberLeakRate",
        component: "ChamberLeakRateContent",
        validate: ["leakRate"],
        attributes: [
            {
                id: "leakRate",
                label: "Leak rate",
                units: [
                    {display: "mbar*l/s", value: "mbar_l_s"},
                    {display: "Pa*m³/s", value: "pa_m3_s"},
                ],
                type: "number"
            }
        ]
    },
    {
        id: "chamberStepPipeLength",
        title: "The pipe length",
        subtitle: "vc_chamberStepPipeLength_subtitle",
        progressName: "Pipe",
        component: "ChamberPipeLengthContent",
        validate: ["primaryPipeDefault", "turboPipeDefault"],
        svgHighlight: "pipe",
        attributes: [
            {
                id: "primaryPipeDefault",
                label: "Calculate with standard value",
                type: "boolean",
                defaultValue: true,
                category: {
                    key: 'primary',
                    title: 'Piping between primary pump and chamber'
                }
            },
            {
                id: "primaryPipeLength",
                label: "Length",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                category: {
                    key: 'primary',
                    title: 'Piping between primary pump and chamber'
                }
            },
            {
                id: "primaryPipeElbowCount",
                label: "Number of elbows",
                type: "number",
                optional: true,
                category: {
                    key: 'primary',
                    title: 'Piping between primary pump and chamber'
                }
            },
            {
                id: "turboPipeDefault",
                label: "No piping between turbopump and chamber",
                type: "boolean",
                defaultValue: true,
                category: {
                    key: 'turbo',
                    title: 'Piping between turbo pump and chamber'
                }
            },
            {
                id: "turboPipeLength",
                label: "Length",
                units: [
                    {display: "cm", value: "cm"},
                    {display: "mm", value: "mm"},
                    {display: "m", value: "m"},
                    {display: "in", value: "in"},
                ],
                type: "number",
                category: {
                    key: 'turbo',
                    title: 'Piping between turbo pump and chamber'
                }
            }
        ]
    },
    {
        id: "chamberStepPump",
        title: "Selection of pumps",
        subtitle: "vc_chamberStepPump_subtitle",
        progressName: "ChamberPump",
        component: "ChamberPumpContent",
        validate: ["pumpGasType", "pumpFrequency"],
        svgHighlight: "pump",
        attributes: [
            {
                id: "pumpGasType",
                label: "Gas type",
                options: ["air"],
                type: "string",
                disabled: true,
                col: 12
            },
            {
                id: "pumpFrequency",
                label: "Mains frequency",
                options: ["f50hz", "f60hz"],
                type: "string",
                facetFilterId: "0",
                col: 12
            },
            {
                id: "pumpingPrinciple",
                label: "Pump principle backing pump",
                options: ["oilLubricated", "dry"],
                type: "string",
                optional: true,
                facetFilterId: "3986",
                col: 24
            },
            {
                id: "enforceSameCount",
                label: "enforceSameCount_label",
                type: "boolean",
                optional: true,
                col: 24
            }
        ]
    },
    {
        id: "chamberResult",
        title: "",
        subtitle: "",
        progressName: "ChamberResult",
        component: "",
        validate: [],
        attributes: []
    }
]
