import _ from "lodash";
import {Step} from "../types";

export default function useChamberStep(stepConfig: any, stepId?: string): Step{

    if (!stepId){
        return _.chain(stepConfig).first().value();
    }

    return _.chain(stepConfig).find({id: stepId}).value()

}
