// @ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import "./index.css"
import _ from "lodash";
import {Button, InfoBox} from "@dreebit/pv-components";
import {Checkbox, Col, Grid, Modal, Row} from "antd";
import {getSolutionTitle} from "../../utils/getSolutionTitle";
import FinishedStepsOverview from "../FinishedStepsOverview";
import getFinishedSteps from "../../utils/getFinishedSteps";
import {useTranslation} from "react-i18next";
import SuctionSpeedChart from "../SuctionSpeedChart";
import EvacuationTimeChart from "../EvacuationTimeChart";
import ChartSettingsForm from "../ChartSettingsForm";
import {
    gasFlowChartSetting,
    pressureChartSetting,
    suctionSpeedChartSetting,
    timeChartSetting
} from "../../config/chartSettings";
import {convertPressure, convertSuctionSpeed, convertTime} from "../../utils/unitConverter";
import PumpingStationSwitch from "../PumpingStationSwitch";
import ResultSortSelect from "../ResultSortSelect";
import {useMutation} from "@apollo/client";
import {UPDATE_CALCULATION_REPORT_CONFIG} from "../../graphql/mutations/updateCalculationReportConfig";
import ResultActionButtons from "../ResultActionButtons";
import {useHistory} from "react-router-dom";
import SolutionCheckboxList from "../SolutionCheckboxList";
import SaveCalculationForm from "../SaveCalculationForm";
import CreateReportForm from "../CreateReportForm";
import {SAVE_CALCULATION_COLLECTION} from "../../graphql/mutations/saveCalculationCollection";
import { time } from 'units-converter';
import {formatChartOptions} from "../../utils/formatNumericInput";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";
import {useSelector} from "react-redux";
import {isLoggedIn} from "../../redux/selectors/user";
import {KeycloakService} from "@dreebit/pv-components";
import GasFlowChart from "../GasFlowChart";
import {Sort} from "../ChamberResultContent";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import LegalNotice from "../LegalNotice";
import ContactForm from "../ContactForm";

interface Props {
    stepConfig: Step[],
    calculationMode: string,
    calculationType: string,
    calculationCollection: any,
    onBackToResult?: () => void,
    calculationReportConfig: any,
    sort?: Sort,
    onChangeSort?: (value: string) => void,
    pumpType?: string,
    onChangePumpType?: (value: string) => void,
    showGasCurve?: boolean,
    hideSortAndType?: boolean,
    onMobileContent: (content: string) => void
}

export default function ChamberResultPumpCurveContent({stepConfig, calculationMode, calculationType, calculationCollection, onBackToResult, calculationReportConfig, sort, onChangeSort, pumpType, onChangePumpType, showGasCurve, hideSortAndType, onMobileContent}: Props) {

    const {t, i18n} = useTranslation()
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const history = useHistory();

    const [isContactModalVisible, setContactModalVisible] = useState(false);
    const toggleContactFormVisibility = () => {
        setContactModalVisible(!isContactModalVisible);
    };

    const keycloak = KeycloakService.getInstance();
    const loggedIn = useSelector(isLoggedIn);

    const isComparisonList = calculationMode === "fixedPumpSystem"

    useEffect(() => {
        if (!loggedIn) {
            toast.warning(<ToastContent
                status={"warning"}
                headline={t('You are not signed in')}
                text={t('no_login_curve_data_text')}
                link={{
                    text: t('Sign in'),
                    onClick: () => keycloak.login({
                        locale: i18n?.language
                    })
                }}
            />, {
                autoClose: false
            })
        }
    }, [loggedIn]);

    const [showEvacuationTimePanel, setShowEvacuationTimePanel] = useState(_.get(calculationReportConfig, 'pumpDownChartOptions.active', true))
    const [showSuctionSpeedPanel, setShowSuctionSpeedPanel] = useState(_.get(calculationReportConfig, 'suctionSpeedChartOptions.active', false))
    const [showGasFlowPanel, setShowGasFlowPanel] = useState(_.get(calculationReportConfig, 'gasFlowChartOptions.active', false))

    const [isReportModalVisible, setReportModalVisible] = useState(false);
    const [isSaveModalVisible, setSaveModalVisible] = useState(false);

    const solutions = _.get(calculationCollection, 'solutionList.solutions', []);

    // define the array of solution indizes which is displayed
    const [activeSolutionIndizes, setActiveSolutionIndizes] = useState(calculationReportConfig?.activeSolutionIds);

    const _changeActiveSolutions = (id) => {
        if(activeSolutionIndizes.includes(id)) {
            setActiveSolutionIndizes(activeSolutionIndizes.filter((x) => x !== id))
        } else {
            setActiveSolutionIndizes([...activeSolutionIndizes, id])
        }
    }

    const calculationId = _.get(calculationCollection, 'calculations[0].id', undefined)
    const calculationValues = _.get(calculationCollection, 'calculations[0].values', [])
    const finishedSteps = getFinishedSteps(calculationValues, stepConfig)

    const evacuationChildRef = useRef();
    const evacuationXRef = useRef();
    const evacuationYRef = useRef();

    const suctionSpeedChildRef = useRef();
    const suctionSpeedXRef = useRef();
    const suctionSpeedYRef = useRef();

    const gasFlowChildRef = useRef();
    const gasFlowXRef = useRef();
    const gasFlowYRef = useRef();

    // 1013 mbar is one atmosphere
    const maxPressure = 1050;
    const maxTime = _.get(calculationValues, 'targetTime.value') * 1.4;

    const initialEvacuationOptions = _.get(calculationReportConfig, 'initialPumpDownChartOptions');
    const pumpDownChartOptions = _.cloneDeep(_.get(calculationReportConfig, 'pumpDownChartOptions'))
    const [evacuationSettings, setEvacuationSettings] = useState(pumpDownChartOptions)

    const initialSuctionSpeedSettings = _.get(calculationReportConfig, 'initialSuctionSpeedChartOptions')
    const suctionSpeedChartOptions = _.cloneDeep(_.get(calculationReportConfig, 'suctionSpeedChartOptions'))
    const [suctionSpeedSettings, setSuctionSpeedSettings] = useState(suctionSpeedChartOptions)
    const [suctionSpeedScaleType, setSuctionSpeedScaleType]: "logarithmic" | "linear" = useState("logarithmic")

    const initialGasFlowOptions = _.get(calculationReportConfig, 'initialGasFlowChartOptions');
    const gasFlowChartOptions = _.cloneDeep(_.get(calculationReportConfig, 'gasFlowChartOptions'))
    const [gasFlowSettings, setGasFlowSettings] = useState(gasFlowChartOptions)


    const [loading, setLoading] = useState(false);

    const _updateSettings = (xRef, yRef, type) => {
        setLoading(true);
        let newSettings = type === 'evacuation' ? _.cloneDeep(evacuationSettings) : _.cloneDeep(suctionSpeedSettings);

        xRef.current.validateFields().then(values => {
            // Values need to be formatted to correct Floats --> the Input with Comma-notation needs the Method parseLocalizedFloat to format decimals correctly
            newSettings.xUnit = values.unit;
            newSettings.xMin = parseLocalizedFloat(values.min);
            newSettings.xMax = type === 'evacuation' ?
                parseLocalizedFloat(values.max) || convertTime(maxTime, values.unit)
                : parseLocalizedFloat(values.max) || convertPressure(maxPressure, values.unit);
            yRef.current.validateFields().then(values => {
                newSettings.yUnit = values.unit;
                newSettings.yMin = parseLocalizedFloat(values.min);
                newSettings.yMax = parseLocalizedFloat(values.max);
            }).then(() => {
                if (type === 'evacuation') {
                    setEvacuationSettings(newSettings);
                } else if (type === 'suctionSpeed') {
                    setSuctionSpeedSettings(newSettings);
                } else if (type === 'gasFlow') {
                    setGasFlowSettings(newSettings);
                }
            }).finally(() => {
                const suctionSpeedChartConfig = suctionSpeedChildRef?.current?.returnChartConfig();
                const pumpDownChartConfig = evacuationChildRef?.current?.returnChartConfig();

                updateReportConfig({
                    variables: {
                        calculationReportConfigId: _.get(calculationReportConfig, 'id'),
                        input: {
                            solutionType: pumpType,
                            activeSolutionIds: activeSolutionIndizes,
                            suctionSpeedChartConfig: suctionSpeedChartConfig,
                            pumpDownChartConfig: pumpDownChartConfig,
                            suctionSpeedChartOptions: suctionSpeedSettings,
                            pumpDownChartOptions: formatChartOptions(evacuationSettings)
                        }
                    }
                })

                setLoading(false);
            })
        })
    }

    const colorConfig = [
        '#DD1541',
        '#FFA64D',
        '#66cc77',
        '#d3e21d',
        '#501fde',
        '#14ccd8',
        '#b54ed9',
        '#ea48c6',
        '#b7680b',
        '#000000'
    ]

    let solutionsWithColors = solutions.map((x: any, index:number) => ({
        ...x,
        curves: x.curves.map(curve => ({
            ...curve,
            borderColor: colorConfig[index]
        }))
    }))
    let onlyActiveSolutions = solutionsWithColors.filter((solution) => activeSolutionIndizes.includes(solution.id));

    let evacuationTimeDataSets = _.flatten(onlyActiveSolutions.map(solution => solution.curves)).filter((curve: any) => curve.curveType === "p_eff");
    let suctionSpeedDatasets = _.flatten(onlyActiveSolutions.map(solution => solution.curves)).filter((curve: any) => curve.curveType === "S_eff");
    let gasFlowDataSets = _.flatten(onlyActiveSolutions.map(solution => solution.curves)).filter((curve: any) => curve.curveType === "gas_flow");

    if (!loading) {
        evacuationTimeDataSets = evacuationTimeDataSets.map((dataset: any, index:number) => ({
            ...dataset,
            label: getSolutionTitle(onlyActiveSolutions[index], t),
            data: dataset.data.map((datapoint) => ({
                x: convertTime(datapoint.x, evacuationSettings.xUnit),
                y: convertPressure(datapoint.y, evacuationSettings.yUnit)
            }))
        }))

        suctionSpeedDatasets = suctionSpeedDatasets.map((dataset: any, index:number) => ({
            ...dataset,
            data: dataset.data.map((datapoint) => ({
                x: convertPressure(datapoint.x, suctionSpeedSettings.xUnit),
                y: convertSuctionSpeed(datapoint.y, suctionSpeedSettings.yUnit)
            }))
        }))

        gasFlowDataSets = gasFlowDataSets.map((dataset: any, index:number) => ({
            ...dataset,
            data: dataset.data.map((datapoint) => ({
                x: datapoint.x,
                y: convertPressure(datapoint.y, gasFlowSettings.yUnit)
            }))
        }))
    }

    const evacuationTimeChartData = {
        datasets: evacuationTimeDataSets
    };
    const suctionSpeedChartData = {
        datasets: suctionSpeedDatasets
    };
    const gasFlowChartData = {
        datasets: gasFlowDataSets
    };

    const [updateReportConfig] = useMutation(UPDATE_CALCULATION_REPORT_CONFIG);
    const [saveCalculation] = useMutation(SAVE_CALCULATION_COLLECTION);

    const _createReport = (values: any) => {
        const pumpDownChartConfig = evacuationChildRef?.current?.returnChartConfig();
        const suctionSpeedChartConfig = suctionSpeedChildRef?.current?.returnChartConfig();
        const gasFlowChartConfig = gasFlowChildRef?.current?.returnChartConfig();

        const chartDatasets = _.get(JSON.parse(pumpDownChartConfig || suctionSpeedChartConfig || gasFlowChartConfig), 'data.datasets', [])
        const activeColors = chartDatasets.map((dataset) => {
            return dataset.borderColor
        })

        let windowReference = window.open();

        updateReportConfig({
            variables: {
                calculationReportConfigId: _.get(calculationReportConfig, 'id'),
                input: {
                    title: values.title || null,
                    comment: values.comment || null,
                    solutionType: pumpType,
                    activeSolutionIds: activeSolutionIndizes,
                    activeSolutionColors: activeColors,
                    pumpDownChartConfig: pumpDownChartConfig,
                    suctionSpeedChartConfig: suctionSpeedChartConfig,
                    gasFlowChartConfig: gasFlowChartConfig,
                    pumpDownChartOptions: {
                        ...evacuationSettings,
                        active: showEvacuationTimePanel
                    },
                    suctionSpeedChartOptions: {
                        ...suctionSpeedSettings,
                        active: showSuctionSpeedPanel
                    },
                    gasFlowChartOptions: {
                        ...gasFlowSettings,
                        active: showGasFlowPanel
                    }
                }
            }
        }).then((res) => {
            windowReference.location = _.get(calculationCollection, 'sheetUrl');
        })
    }

    const _saveCalculation = (values: any) => {
        const suctionSpeedChartConfig = suctionSpeedChildRef?.current?.returnChartConfig();
        const pumpDownChartConfig = evacuationChildRef?.current?.returnChartConfig();

        updateReportConfig({
            variables: {
                calculationReportConfigId: _.get(calculationReportConfig, 'id'),
                input: {
                    solutionType: pumpType,
                    activeSolutionIds: activeSolutionIndizes,
                    suctionSpeedChartConfig: suctionSpeedChartConfig,
                    pumpDownChartConfig: pumpDownChartConfig,
                    suctionSpeedChartOptions: suctionSpeedSettings,
                    pumpDownChartOptions: evacuationSettings
                }
            }
        })

        saveCalculation({
            variables: {
                id: _.get(calculationCollection, 'id'),
                title: _.get(values, 'title')
            },
            refetchQueries: ["CalculationCollectionList"]
        }).then((res) => {
            setSaveModalVisible(false)

            toast.success(<ToastContent
                status={"success"}
                text={t('Calculation was saved successfully')}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

            toast.error(<ToastContent
                status={"error"}
                headline={t('Error saving calculation')}
                text={`${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    }

    let targetTime = undefined;
    let targetPressure = undefined;

    if (_.get(calculationValues, 'targetTime.value')) {
        targetTime = time(_.get(calculationValues, 'targetTime.value')).from(_.get(calculationValues, 'targetTime.unit')).to("s").value
    }
    if (_.get(calculationValues, 'targetPressure.value')) {
        targetPressure = convertPressure(_.get(calculationValues, 'targetPressure.value'), "mbar", _.get(calculationValues, 'targetPressure.unit'))
    }

    return (
        <div className={'pv-40'}>
            {/* Solution selection */}
            <Row justify={'space-between'} gutter={40}>
                <Col xs={24} md={17}>
                    <LegalNotice className={'pb-16'}/>
                    {
                        hideSortAndType ? null :
                            <Row justify={'space-between'} align={"middle"} className={'pb-24'}>
                                <Col flex={screens.lg ? "none" : "5"}>
                                    <PumpingStationSwitch
                                        value={pumpType}
                                        onChange={onChangePumpType}
                                        pumpingStationDisabled={!_.get(calculationCollection, 'solutionList.pumpingStationFilter')}
                                        disabled={calculationMode === "fixedPumpSystem" && calculationType === "chamberEvacuation"}
                                    />
                                </Col>
                                <Col flex={screens.lg ? "none" : "5"}>
                                    <ResultSortSelect
                                        value={`${sort.key}_${sort.direction}`}
                                        onChange={onChangeSort}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>

                    }

                    <p>
                        {t('pump_curves_info_1')}
                    </p>
                    <p style={{marginTop: "-8px", paddingBottom: "8px"}}>
                        {t('gas_ballast_hint')}
                    </p>

                    <SolutionCheckboxList
                        solutionList={solutions}
                        activeSolutionIndizes={activeSolutionIndizes}
                        colorConfig={colorConfig}
                        onChange={(id) => _changeActiveSolutions(id)}
                        calculationCollection={calculationCollection}
                        isComparisonList={isComparisonList}
                        calculationMode={calculationMode}
                        calculationType={calculationType}
                    />

                    <ResultActionButtons
                        className={'mt-16'}
                        buttons={["back", "save"]}
                        onBack={onBackToResult}
                        onSave={(loggedIn && calculationId) ? () => setSaveModalVisible(true) : undefined}
                        showSaveHint={!(loggedIn && calculationId)}
                    />
                </Col>
                <Col xs={24} md={7}>
                    {
                        !isComparisonList ?
                            <div className={screens.lg ? 'ml-32' : 'mt-24'}>
                                <FinishedStepsOverview
                                    stepConfig={stepConfig}
                                    finishedSteps={finishedSteps}
                                    calculationValues={calculationValues}
                                    calculationId={calculationId}
                                    calculationMode={calculationMode}
                                    calculationType={calculationType}
                                    editable={!!calculationId}
                                />
                            </div>
                        :
                            <div className={screens.lg ? '' : 'mt-24'}>
                                <div className={'mb-24'}>
                                    <InfoBox
                                        heading={t('need_help_title')}
                                        infoBoxText={t('need_help_subtitle')}
                                        infoBoxButtonLabel={t('Contact us')}
                                        onClick={screens.md ? toggleContactFormVisibility : () => onMobileContent("contactForm")}
                                        topRightIcon={"contact_support"}
                                        buttonIcon={"mail_outline"}
                                    />
                                </div>
                            </div>
                    }
                </Col>
            </Row>

            {/* evacuation time curves */}
            <div className={"flex-col mt-24"}>
                <div className={"flex-row flex-align-items-center"}>
                    <Checkbox className={"panel-checkbox"} checked={showEvacuationTimePanel} onChange={() => setShowEvacuationTimePanel(!showEvacuationTimePanel)} />
                    <span className={'font-bold collapse-title pl-8'}>{t('Evacuation curve')}</span>
                </div>

                {
                    showEvacuationTimePanel &&
                    <Row justify={'space-between'} gutter={40}>
                        <Col xs={24} md={17}>
                            <p>
                                {t('pump_curves_info_evacuation_time')}
                            </p>

                            <EvacuationTimeChart
                                ref={evacuationChildRef}
                                chartData={evacuationTimeChartData}
                                settings={evacuationSettings}
                                targetPressure={targetPressure}
                                targetTime={targetTime}
                            />
                        </Col>
                        <Col xs={24} md={7}>
                            <h5>{t('Pressure')}</h5>
                            <ChartSettingsForm
                                ref={evacuationYRef}
                                defaultValues={{
                                    unit: evacuationSettings.yUnit,
                                    min: evacuationSettings.yMin,
                                    max: evacuationSettings.yMax
                                }}
                                chartSetting={pressureChartSetting}
                            />
                            <h5>{t('Time')}</h5>
                            <ChartSettingsForm
                                ref={evacuationXRef}
                                defaultValues={{
                                    unit: evacuationSettings.xUnit,
                                    min: evacuationSettings.xMin,
                                    max: evacuationSettings.xMax
                                }}
                                chartSetting={timeChartSetting}
                            />

                            <Button
                                className={'full-width'}
                                type={'primary'}
                                onClick={() => {_updateSettings(evacuationXRef, evacuationYRef, 'evacuation')}}
                            >
                                {t('Update chart')}
                            </Button>
                            <Button
                                className={'full-width mt-16'}
                                type={'tertiary'}
                                onClick={() => {
                                    setEvacuationSettings(initialEvacuationOptions);
                                    evacuationChildRef?.current?.resetZoom();
                                    evacuationXRef?.current?.resetFields();
                                    evacuationYRef?.current?.resetFields();
                                }}
                            >
                                {t('Reset values')}
                            </Button>
                        </Col>
                    </Row>
                }
            </div>

            {/* suction speed curves */}
            <div className={"flex-col pv-40"}>
                <div className={"flex-row flex-align-items-center"}>
                    <Checkbox className={"panel-checkbox"} checked={showSuctionSpeedPanel} onChange={() => setShowSuctionSpeedPanel(!showSuctionSpeedPanel)} />
                    <span className={'font-bold collapse-title pl-8'}>{t('Suction speed curve')}</span>
                </div>

                {
                    showSuctionSpeedPanel &&
                    <Row justify={'space-between'} gutter={40}>
                        <Col xs={24} md={17}>
                            <p>
                                {t('pump_curves_info_suction_speed')}
                            </p>

                            <SuctionSpeedChart
                                ref={suctionSpeedChildRef}
                                chartData={suctionSpeedChartData}
                                settings={suctionSpeedSettings}
                                yScaleType={suctionSpeedScaleType}
                            />

                            <div className={"pt-16"}>
                                <Checkbox
                                    checked={suctionSpeedScaleType === "logarithmic"}
                                    onChange={() => {
                                        if (suctionSpeedScaleType === "logarithmic") {
                                            setSuctionSpeedScaleType("linear")
                                        } else {
                                            setSuctionSpeedScaleType("logarithmic")
                                        }
                                    }}
                                >
                                    {t("Logarithmic representation of the suction speed")}
                                </Checkbox>
                            </div>

                        </Col>
                        <Col xs={24} md={7}>
                            <h5>{t('Suction speed')}</h5>
                            <ChartSettingsForm
                                ref={suctionSpeedYRef}
                                defaultValues={{
                                    unit: suctionSpeedSettings.yUnit,
                                    min: suctionSpeedSettings.yMin,
                                    max: suctionSpeedSettings.yMax
                                }}
                                chartSetting={suctionSpeedChartSetting}
                            />
                            <h5>{t('Pressure')}</h5>
                            <ChartSettingsForm
                                ref={suctionSpeedXRef}
                                defaultValues={{
                                    unit: suctionSpeedSettings.xUnit,
                                    min: suctionSpeedSettings.xMin,
                                    max: suctionSpeedSettings.xMax
                                }}
                                chartSetting={pressureChartSetting}
                            />

                            <Button
                                className={'full-width'}
                                type={'primary'}
                                onClick={() => _updateSettings(suctionSpeedXRef, suctionSpeedYRef, 'suctionSpeed')}
                            >
                                {t('Update chart')}
                            </Button>
                            <Button
                                className={'full-width mt-16'}
                                type={'tertiary'}
                                onClick={() => {
                                    setSuctionSpeedSettings(initialSuctionSpeedSettings);
                                    suctionSpeedChildRef?.current?.resetZoom();
                                    suctionSpeedXRef?.current?.resetFields();
                                    suctionSpeedYRef?.current?.resetFields();
                                }}
                            >
                                {t('Reset values')}
                            </Button>
                        </Col>
                    </Row>
                }
            </div>


            {/* gas flow curves */}
            {
                showGasCurve &&
                    <div className={"flex-col pv-40"}>
                        <div className={"flex-row flex-align-items-center"}>
                            <Checkbox className={"panel-checkbox"} checked={showGasFlowPanel} onChange={() => setShowGasFlowPanel(!showGasFlowPanel)} />
                            <span className={'font-bold collapse-title pl-8'}>{t('Gas flow curve')}</span>
                        </div>

                        {
                            showGasFlowPanel &&
                            <Row justify={'space-between'} gutter={40}>
                                <Col xs={24} md={17}>
                                    <p>
                                        {t('pump_curves_info_gas_flow')}
                                    </p>

                                    <GasFlowChart
                                        ref={gasFlowChildRef}
                                        chartData={gasFlowChartData}
                                        settings={gasFlowSettings}
                                    />

                                </Col>
                                <Col xs={24} md={7}>
                                    <h5>{t('Gas flow')}</h5>
                                    <ChartSettingsForm
                                        ref={gasFlowYRef}
                                        defaultValues={{
                                            unit: gasFlowSettings.yUnit,
                                            min: gasFlowSettings.yMin,
                                            max: gasFlowSettings.yMax
                                        }}
                                        chartSetting={gasFlowChartSetting}
                                    />
                                    <h5>{t('Pressure')}</h5>
                                    <ChartSettingsForm
                                        ref={gasFlowXRef}
                                        defaultValues={{
                                            unit: gasFlowSettings.xUnit,
                                            min: gasFlowSettings.xMin,
                                            max: gasFlowSettings.xMax
                                        }}
                                        chartSetting={pressureChartSetting}
                                    />

                                    <Button
                                        className={'full-width'}
                                        type={'primary'}
                                        onClick={() => _updateSettings(gasFlowXRef, gasFlowYRef, 'gasFlow')}
                                    >
                                        {t('Update chart')}
                                    </Button>
                                    <Button
                                        className={'full-width mt-16'}
                                        type={'tertiary'}
                                        onClick={() => {
                                            setSuctionSpeedSettings(initialGasFlowOptions);
                                            gasFlowChildRef?.current?.resetZoom();
                                            gasFlowXRef?.current?.resetFields();
                                            gasFlowYRef?.current?.resetFields();
                                        }}
                                    >
                                        {t('Reset values')}
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </div>
            }

            <Row justify={'space-between'} gutter={40}>
                <Col xs={24} md={17}>
                    <ResultActionButtons
                        className={'mt-40'}
                        buttons={["back", "newCalculation", "downloadReport", "save"]}
                        onBack={onBackToResult}
                        onDownloadReport={() => setReportModalVisible(true)}
                        onNewCalculation={() => history.push('/')}
                        onSave={(loggedIn && calculationId) ? () => setSaveModalVisible(true) : undefined}
                        showSaveHint={!(loggedIn && calculationId)}
                    />
                </Col>
            </Row>
            <Modal
                title={t("Choose a name and comment for the report")}
                visible={isReportModalVisible}
                footer={null}
                onCancel={() => setReportModalVisible(false)}
            >
                <CreateReportForm
                    initialValues={{
                        title: _.get(calculationReportConfig, 'title', null),
                        comment: _.get(calculationReportConfig, 'comment', null),
                    }}
                    onCreateReport={_createReport}
                    onCancel={() => setReportModalVisible(false)}
                />
            </Modal>
            <Modal
                title={t("Save the calculation")}
                visible={isSaveModalVisible}
                footer={null}
                onCancel={() => setSaveModalVisible(false)}
            >
                <SaveCalculationForm
                    onSave={_saveCalculation}
                    onCancel={() => setSaveModalVisible(false)}
                />
            </Modal>
            <Modal
                title={t('Contact us')}
                visible={isContactModalVisible}
                onCancel={toggleContactFormVisibility}
                footer={null}
                width={720}
            >
                <ContactForm
                    resultId={_.get(calculationCollection, 'id')}
                    resultPdf={_.get(calculationCollection, 'sheetUrl')}
                    onSuccess={toggleContactFormVisibility}
                    onCancel={toggleContactFormVisibility}
                />
            </Modal>
        </div>
    )
}
