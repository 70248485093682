// @ts-nocheck
import React, {useRef} from "react";
import './index.css';
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import {Carousel, Button} from "@dreebit/pv-components";
import DesorptionForm from "../DesorptionForm";
import {IDesorptionFormData} from "../../utils/convertDesorptionRateForm";
import {Step} from "../../types";

export interface Props {
    surfaces: IDesorptionFormData[],
    onAddSurface: () => void,
    onSaveSurface: (values: any) => void,
    onDeleteSurface: (index: number) => void,
    onChangeSurfaceIndex: (index: number) => void,
    onList: () => void,
    onValuesChange?: any,
    onChangeDesorptionUnit?: (value: string) => void,
    isDefaultDesorption?: boolean,
    sliderRef?: any,
    step?: Step,
    fieldValidations: any[],
    currentUser?: any
}

export default function DesorptionCarousel ({onDeleteSurface, onSaveSurface, sliderRef, surfaces, onAddSurface, isDefaultDesorption, onChangeSurfaceIndex, onList, onValuesChange, onChangeDesorptionUnit, step, fieldValidations, currentUser}: Props) {
    const {t} = useTranslation();

    return <div className={''}>
        <Carousel
            beforeChange={(from: any, to: any) => {
                onChangeSurfaceIndex(to);
            }}
            ref={sliderRef}
        >
            {surfaces.map((surface, index) => {
                return <DesorptionForm
                    key={index}
                    surface={surface}
                    onValuesChange={onValuesChange}
                    onChangeDesorptionUnit={onChangeDesorptionUnit}
                    onSaveSurface={onSaveSurface}
                    onDeleteSurface={onDeleteSurface}
                    onList={onList}
                    index={index}
                    isDefaultDesorption={isDefaultDesorption}
                    step={step}
                    fieldValidations={fieldValidations}
                    currentUser={currentUser}
                />
            })}
        </Carousel>
        <div style={{marginLeft: '30px', marginRight: '30px'}}>
            <Button
                type={'tertiary'}
                htmlType={"button"}
                className={'full-width mt-24 add-surface-button'}
                onClick={onAddSurface}
                icon={"add"}
            >
                {t('Add surface')}
            </Button>
        </div>
    </div>
}
