import {gql} from "@apollo/client";

export const CURRENT_USER = gql`
    query CurrentUser {
        currentUser {
            id
            name
            firstName
            lastName
            registrationStatusCode
            email
            phone
            orgUnit {
                id
                name
                address {
                    id
                    name
                    street
                    number
                    postalCode
                    town
                    country
                    region
                }
                salesEngineer {
                    id
                    name
                    phone
                    email
                }
            }
        }
    }
`;
