import {gql} from "@apollo/client";

export const CALCULATION = gql`
    query Calculation($id: ID!) {
        calculation(calculationId: $id) {
            id
            calculationCollection {
                id
                calculations {
                    id
                }
            }
            insert
            update
            calculationType
            calculationMode
            valid
            access {
                id
                read
                write
                remove
            }
            values {
                targetPressure {
                    unit
                    value
                }
                targetTime {
                    unit
                    value
                }
                ignoreTargetTime {
                    value
                }
                chamberSurface {
                    unit
                    value
                }
                chamberVolume {
                    unit
                    value
                }
                chamberVolumeGeometry {
                    value
                }
                chamberVolumeHeight {
                    unit
                    value
                }
                chamberVolumeLength {
                    unit
                    value
                }
                chamberVolumeWidth {
                    unit
                    value
                }
                chamberVolumeRadius {
                    unit
                    value
                }
                desorption {
                    desorptionRate {
                        unit
                        value
                    }
                    desorptionSurfaceName {
                        value
                    }
                    desorptionSurfaceSize {
                        unit
                        value
                    }
                }
                leakRate {
                    unit
                    value
                }
                primaryPipeDefault {
                    value
                }
                primaryPipeLength {
                    value
                    unit
                }
                primaryPipeDiameter {
                    value
                    unit
                }
                primaryPipeElbowCount {
                    value
                }
                turboPipeDefault {
                    value
                }
                turboPipeLength {
                    value
                    unit
                }
                turboPipeDiameter {
                    unit
                    value
                }
                enforceSameCount {
                    value
                }
                pumpFrequency {
                    value
                }
                pumpGasType {
                    value
                }
                pumpingPrinciple {
                    value
                }
                backingPump {
                    value
                  }
                  backingPumpCount {
                    value
                  }
                  rootsPump {
                    value
                  }
                  rootsPumpCount {
                    value
                  }
                  turboPump {
                    value
                  }
                  turboPumpCount {
                    value
                  }
                  pumpingStation {
                    value
                  }
                  pumpingStationCount {
                    value
                  }
                  customPumpSystem {
                    value
                  }
            }
        }
    }
`;
