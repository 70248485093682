import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {CALCULATION_COLLECTION_LIST} from "../../graphql/queries/calculationCollectionList";
import _ from "lodash";
import {Button, LoadingSpinner} from "@dreebit/pv-components";
import moment from "moment";
import {Empty} from "antd";
import formatLongTitles from "../../utils/formatLongTitles";

export default function SavedCalculationsMobileModal() {


    const {t} = useTranslation();
    const history = useHistory();

    const {loading, data} = useQuery(CALCULATION_COLLECTION_LIST, {

    });

    const collections = _.get(data, 'calculationCollectionList.calculationCollections')

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <div
            className={'full-width pt-16'}
            style={{overflowY: "scroll"}}
        >
            {collections.length > 0 ? collections.map((coll: any) => {
                const calculationMode = _.get(coll, 'calculationMode', '');
                const calculationType = _.get(coll, 'calculationType', '');

                return <div
                    className={'pv-16'}
                    style={{borderBottom: '1px solid #ccc'}}
                >
                    <div>
                        <span>{t('Name')}:</span>
                        <span className="font-bold pl-16">{formatLongTitles(coll.title, 28, false) || t('No title')}</span>
                    </div>
                    <div>
                        <span>{t('Date')}:</span>
                        <span className="pl-8">{moment(coll.insert).format("L")}</span>
                    </div>
                    <div>
                        <span>{t('calculationMode')}:</span>
                        <span className="pl-8">{t(calculationMode)}</span>
                    </div>
                    <div>
                        <span>{t('calculationType')}:</span>
                        <span className="pl-8">{t(calculationType)}</span>
                    </div>
                    <div className="flex-row flex-space-between">
                        <Button
                            type={'ghost'}
                            onClick={() => history.push(`/${calculationMode}/${calculationType}/${_.get(coll, 'calculations[0].id')}`)}
                            icon={"arrow_forward"}
                        >
                            {t('Select')}
                        </Button>
                        <Button type={'ghost'} icon={"delete"} />
                    </div>
                </div>
            }) :
                <Empty
                    className={'mv-32'}
                    description={t('No calculations')}
                />
            }
        </div>
    )
}
