import {gql} from "@apollo/client";

export const CALCULATION_REPORT_CONFIG = gql`
    query CalculationReportConfig($id: ID!) {
        calculationCollection(calculationCollectionId: $id) {
            id
            calculations {
              id
            }
            calculationReportConfig {
              id
              solutionSort {
                key
                direction
              }
              solutionType
              pumpDownChartOptions {
                active
                xMin
                xMax
                xUnit
                yMin
                yMax
                yUnit
              }
              initialPumpDownChartOptions {
                active
                xMin
                xMax
                xUnit
                yMin
                yMax
                yUnit
              }
              suctionSpeedChartOptions {
                active
                xMin
                xMax
                xUnit
                yMin
                yMax
                yUnit
              }
              initialSuctionSpeedChartOptions {
                active
                xMin
                xMax
                xUnit
                yMin
                yMax
                yUnit
              }
              gasFlowChartOptions {
                active
                xMin
                xMax
                xUnit
                yMin
                yMax
                yUnit
              }
              initialGasFlowChartOptions {
                active
                xMin
                xMax
                xUnit
                yMin
                yMax
                yUnit
              }
              suctionSpeedChartConfig
              pumpDownChartConfig
              activeSolutionIds
              title
              comment
            }
        }
    }
`;
