//@ts-nocheck
import React, {useEffect, useState} from "react";
import useChamberStep from "../../hooks/useChamberStep";
import {Button, Col, Form, FormInstance, Grid, InputNumber, Row, Select, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {stepConfig} from "../../config/fixedPumpSystemChamberEvacuationStepConfig";
import PumpSelectionGroup from "../PumpSelectionGroup";
import {StepAttribute} from "../../types";
import _ from "lodash";
import {useQuery} from "@apollo/client";
import {PUMP_LIST} from "../../graphql/queries/pumpList";
import {formatPumpInputAttributes} from "../../utils/formatPumpInputAttributes";
import {formatPumpSelectionValues} from "../../utils/formatPumpSelectionValues";
import formatFormData from "../../utils/formatFormData";
import { LoadingOutlined } from '@ant-design/icons';
import {getMatchingFlangeDiameter} from "../../utils/getMatchingFlangeDiameter";
import SubmitTypeFormItem from "../SubmitTypeFormItem";

interface Props {
    form: FormInstance,
    stepId: string,
    onSubmit: (values: any, skipFormatting?: boolean) => void,
    initialValues: {[key: string]: string}
}

export default function ChamberPumpContent({form, stepId, onSubmit, initialValues}: Props) {

    const {t} = useTranslation();
    const step = useChamberStep(stepConfig, stepId);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const { Option } = Select;

    const nonPumpInputAttributes = step.attributes.filter(attr => ["pumpFrequency", "pumpingStation", "pumpingStationCount"].includes(attr.id));
    const pumpInputAttributes = step.attributes.filter(attr => !["pumpFrequency", "pumpingStation", "pumpingStationCount"].includes(attr.id));

    // @ts-ignore
    const [pumpFrequency, setPumpFrequency]: string = useState<"f50hz" | "f60hz">(_.get(initialValues, 'pumpFrequency', "f50hz"))
    const [pumpAttributes, setPumpAttributes] = useState(formatPumpInputAttributes(pumpInputAttributes, initialValues))
    const [currentPumps, setCurrentPumps] = useState({
        backingPump: {
            value: _.get(initialValues, 'backingPump'),
            count: _.get(initialValues, 'backingPumpCount')
        },
        rootsPump: {
            value: _.get(initialValues, 'rootsPump'),
            count: _.get(initialValues, 'rootsPumpCount')
        },
        turboPump: {
            value: _.get(initialValues, 'turboPump'),
            count: _.get(initialValues, 'turboPumpCount')
        }
    })

    const {data, loading, error} = useQuery(PUMP_LIST, {
        variables: {
            pumpType: ["turboPumpingStation", "rootsPumpingStation"],
            frequency: pumpFrequency
        }
    })
    const pumpingStations = _.get(data, 'pumpList.pumps', [])

    const pumpList = useQuery(PUMP_LIST, {
        variables: {
            pumpType: ["turboPump", "rootsPump", "primaryPumpDry", "primaryPumpOil", "multiStageRoots"],
            frequency: pumpFrequency
        }
    })

    useEffect(() => {
        if(currentPumps?.turboPump?.value) {
            const pumpId = currentPumps.turboPump.value
            const matchingTurboFlangeDiameter = getMatchingFlangeDiameter(pumpId, _.get(pumpList, 'data.pumpList.pumps', []))

            form.setFieldsValue({
                turboPipeDiameter: matchingTurboFlangeDiameter,
                turboPipeDiameterUnit: "mm"
            })
        }

        if (currentPumps?.rootsPump?.value) {
            const pumpId = currentPumps.rootsPump.value
            const matchingRootsFlangeDiameter = getMatchingFlangeDiameter(pumpId, _.get(pumpList, 'data.pumpList.pumps', []))

            form.setFieldsValue({
                primaryPipeDiameter: matchingRootsFlangeDiameter,
                primaryPipeDiameterUnit: "mm"
            })
        } else if (currentPumps?.backingPump?.value) {
            const pumpId = currentPumps.backingPump.value
            const matchingPrimaryFlangeDiameter = getMatchingFlangeDiameter(pumpId, _.get(pumpList, 'data.pumpList.pumps', []))

            form.setFieldsValue({
                primaryPipeDiameter: matchingPrimaryFlangeDiameter,
                primaryPipeDiameterUnit: "mm"
            })
        }
    }, [form, pumpList, currentPumps])

    // check for matching pumping stations with current pump selection
    useEffect(() => {
        const backingPumpCount = _.get(currentPumps, 'backingPump.count');

        const filteredPumpingStations = pumpingStations.filter((station: any) => {
            const quotient = Math.floor(backingPumpCount/_.get(station, 'backingPumpCount'));

            if (backingPumpCount % _.get(station, 'backingPumpCount') === 0) {
                // Pumpen und Anzahl müssen exakt mit denen einer Pumpstation matchen, um das Select vorauszufüllen
                return (
                    (_.get(station, 'backingPump.id') === _.get(currentPumps, 'backingPump.value'))
                    && (_.get(station, 'backingPumpCount') * quotient === backingPumpCount)
                    && ((_.get(station, 'mainPump.id') === _.get(currentPumps, 'turboPump.value') && !_.get(currentPumps, 'rootsPump.value'))
                        || (_.get(station, 'mainPump.id') === _.get(currentPumps, 'rootsPump.value')) && !_.get(currentPumps, 'turboPump.value'))
                    && (quotient === _.get(currentPumps, 'turboPump.count') || quotient === _.get(currentPumps, 'rootsPump.count'))
                )
            }
        })

        if (filteredPumpingStations.length > 1) {
            console.warn("More than one suitable pumping station was found.")
            console.log(filteredPumpingStations, 'Filtered PumpingStations');
        }

        if (filteredPumpingStations.length === 1) {
            const quotient = Math.floor(backingPumpCount/_.get(filteredPumpingStations[0], 'backingPumpCount'));

            form.setFieldsValue({
                pumpingStation: _.get(filteredPumpingStations[0], 'id', null),
                pumpingStationCount: quotient
            })
        } else {
            form.setFieldsValue({
                pumpingStation: null,
                pumpingStationCount: null
            })
        }
    }, [currentPumps, pumpingStations])

    const setEmptyState = () => {
        setCurrentPumps({
            backingPump: {
                value: null,
                count: null
            },
            rootsPump: {
                value: null,
                count: null
            },
            turboPump: {
                value: null,
                count: null
            }
        })
        form.setFieldsValue({
            backingPumpCount: null,
            backingPump: null,
            rootsPumpCount: null,
            rootsPump: null,
            turboPumpCount: null,
            turboPump: null,
            pumpingStation: null,
            pumpingStationCount: null
        })
    }

    const onChangeFrequency = (frequency: string) => {
        // different pumps with different frequencies can have the same name but different IDs
        // we need to clear the inputs because pumps with other IDs won't match correctly
        if (frequency !== pumpFrequency) {
            setEmptyState()
        }

        setPumpFrequency(frequency)
    }

    const _returnAttributeInput = (attribute: StepAttribute) => {

        if (attribute.options && attribute.id === "pumpFrequency") {
            return <Select
                style={{width: "100%"}}
                value={pumpFrequency}
                onChange={(value) => onChangeFrequency(value)}
            >
                {attribute.options.map((option: any, index) => {
                    return <Option key={index} value={option}>{t(option)}</Option>
                })}
            </Select>
        }

        if (attribute.options) {
            return <Select style={{width: "100%"}}>
                {attribute.options.map((option: any, index) => {
                    return <Option key={index} value={option}>{t(option)}</Option>
                })}
            </Select>
        }

        if (attribute.type === "number") {
            return <InputNumber
                min={1}
                max={999}
                onChange={(value) => {
                    const pumpingStation = pumpingStations.find((pump: any) => pump.id === form.getFieldValue('pumpingStation'))
                    const newBackingPumpCount = _.get(pumpingStation, 'backingPumpCount') * form.getFieldValue('pumpingStationCount')
                    const newOtherPumpCount = 1 * form.getFieldValue('pumpingStationCount')

                    // pumping stations can only contain a turbo OR a roots pump as main pump
                    if (form.getFieldValue('turboPumpCount')) {
                        form.setFieldsValue({
                            backingPumpCount: newBackingPumpCount > 0 ? newBackingPumpCount : 1,
                            turboPumpCount: newOtherPumpCount > 0 ? newOtherPumpCount : 1
                        })
                    }
                    if (form.getFieldValue('rootsPumpCount')) {
                        form.setFieldsValue({
                            backingPumpCount: newBackingPumpCount > 0 ? newBackingPumpCount : 1,
                            rootsPumpCount: newOtherPumpCount > 0 ? newOtherPumpCount : 1
                        })
                    }
                }}
            >
            </InputNumber>
        }

        if (attribute.pumpQueryTypes && !loading) {
            return <Select
                placeholder={t('Please choose')}
                style={{width: "100%"}}
                allowClear
                optionFilterProp="children"
                showSearch
                onChange={(value) => {
                    if (attribute.id === "pumpingStation" && !value) {
                        setEmptyState()
                    } else if (form.getFieldValue('pumpingStationCount') < 1) {
                        form.setFieldsValue({
                            pumpingStationCount: 1
                        })
                    }

                    const pumpingStation = pumpingStations.find((pump: any) => pump.id === value)
                    const turboPumpStepAttribute = step.attributes.find(attr => attr.id === "turboPump");
                    const rootsPumpStepAttribute = step.attributes.find(attr => attr.id === "rootsPump");

                    form.setFieldsValue({
                        backingPump: _.get(pumpingStation, 'backingPump.id'),
                        backingPumpCount: _.get(pumpingStation, 'backingPumpCount') * form.getFieldValue('pumpingStationCount')
                    })

                    let attributesClone = [...pumpAttributes];
                    let turboPumpAttribute = {...attributesClone[pumpAttributes.findIndex(x => x.pumpSystemType === "turboPump")]}
                    let rootsPumpAttribute = {...attributesClone[pumpAttributes.findIndex(x => x.pumpSystemType === "rootsPump")]}
                    let primaryPipeAttribute = {...attributesClone[pumpAttributes.findIndex(x => x.pumpSystemType === "primaryPipe")]}
                    let turboPipeAttribute = {...attributesClone[pumpAttributes.findIndex(x => x.pumpSystemType === "turboPipe")]}

                    // pumping stations can only contain a turbo OR a roots pump as main pump
                    if (turboPumpStepAttribute?.pumpQueryTypes?.includes(_.get(pumpingStation, 'mainPump.pumpType'))) {
                        form.setFieldsValue({
                            rootsPump: null,
                            rootsPumpCount: null,
                            turboPump: _.get(pumpingStation, 'mainPump.id'),
                            turboPumpCount: 1 * form.getFieldValue('pumpingStationCount'),
                            turboPipeDiameter: _.get(pumpingStation, 'mainPump.flangeDiameter'),
                            turboPipeDiameterUnit: "mm"
                        })

                        turboPumpAttribute.status = "active";
                        rootsPumpAttribute.status = "addable";
                        primaryPipeAttribute.status = "addable";
                        turboPipeAttribute.status = "addable"

                        setCurrentPumps({
                            backingPump: {
                                value: _.get(pumpingStation, 'backingPump.id'),
                                count: _.get(pumpingStation, 'backingPumpCount')
                            },
                            turboPump: {
                                value: _.get(pumpingStation, 'mainPump.id'),
                                count: 1 * form.getFieldValue('pumpingStationCount')
                            },
                            rootsPump: null
                        })

                    } else if (rootsPumpStepAttribute?.pumpQueryTypes?.includes(_.get(pumpingStation, 'mainPump.pumpType'))) {
                        form.setFieldsValue({
                            rootsPump: _.get(pumpingStation, 'mainPump.id'),
                            rootsPumpCount: 1 * form.getFieldValue('pumpingStationCount'),
                            turboPump: null,
                            turboPumpCount: null,
                            primaryPipeDiameter: _.get(pumpingStation, 'mainPump.flangeDiameter'),
                            primaryPipeDiameterUnit: "mm"
                        })

                        turboPumpAttribute.status = "addable";
                        rootsPumpAttribute.status = "active";
                        primaryPipeAttribute.status = "addable";
                        turboPipeAttribute.status = "addable";

                        setCurrentPumps({
                            backingPump: {
                                value: _.get(pumpingStation, 'backingPump.id'),
                                count: _.get(pumpingStation, 'backingPumpCount')
                            },
                            rootsPump: {
                                value: _.get(pumpingStation, 'mainPump.id'),
                                count: 1 * form.getFieldValue('pumpingStationCount')
                            },
                            turboPump: null
                        })
                    }

                    attributesClone[pumpAttributes.findIndex(x => x.pumpSystemType === "turboPump")] = turboPumpAttribute;
                    attributesClone[pumpAttributes.findIndex(x => x.pumpSystemType === "rootsPump")] = rootsPumpAttribute;
                    attributesClone[pumpAttributes.findIndex(x => x.pumpSystemType === "primaryPipe")] = primaryPipeAttribute;
                    attributesClone[pumpAttributes.findIndex(x => x.pumpSystemType === "turboPipe")] = turboPipeAttribute;

                    setPumpAttributes(attributesClone);
                }}
            >
                {pumpingStations.map((pump: any) => {
                    return <Option
                        key={pump.id}
                        value={pump.id}
                    >
                        {pump.name}
                    </Option>
                })}
            </Select>
        }
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <div>
            <h3 className={'font-bold'}>{t(step?.title || "")}</h3>
            <p className={"mv-24"}>{t(step?.subtitle || "")}</p>

            {
                pumpFrequency && !loading ?
                    <Form
                        id={`form-${stepId}`}
                        onFinish={(values) =>{
                            let formattedValues = formatFormData(values, step);
                            formattedValues = formatPumpSelectionValues(formattedValues, step)
                            formattedValues = {
                                ...formattedValues,
                                submitType: _.get(values, 'submitType', null)
                            }

                            onSubmit(formattedValues, true)
                        }}
                        form={form}
                        layout={'vertical'}
                        className={'mt-24'}
                        initialValues={initialValues}
                    >
                        <SubmitTypeFormItem />
                        <Row gutter={10}>
                            {
                                nonPumpInputAttributes.map((attribute) => {
                                    return (
                                        <Col xs={attribute.colMobile} lg={attribute.col} key={attribute.id}>
                                            <Form.Item
                                                name={attribute.id}
                                                label={attribute.type !== "boolean" ? t(attribute.label) : undefined}
                                                rules={[{
                                                    // PumpingStation inputs are not required if it's a custom pumping system
                                                    required: ["pumpingStation", "pumpingStationCount"].includes(attribute.id) ? false : !attribute.optional
                                                }]}
                                                valuePropName={attribute.type === "boolean" ? "checked" : undefined}
                                            >
                                                {_returnAttributeInput(attribute)}
                                            </Form.Item>
                                        </Col>
                                    )
                                })
                            }
                        </Row>


                        <PumpSelectionGroup
                            pumpSystemType={"chamber"}
                            status={"active"}
                            changeStatus={(status: string) => {}}
                        />

                        {
                            pumpAttributes.map((x, index) => {
                                return <PumpSelectionGroup
                                    className={screens.lg ? '' : 'mb-20'}
                                    key={index}
                                    form={form}
                                    attributes={x.attributes}
                                    pumpSystemType={x.pumpSystemType}
                                    pumpQueryTypes={x.pumpQueryTypes}
                                    frequency={pumpFrequency}
                                    label={x.label}
                                    status={x.status}
                                    onDeletePump={
                                    ["turboPump", "rootsPump", "backingPump"].includes(x.pumpSystemType) ?
                                        (pumpType) => {
                                            setCurrentPumps({
                                                ...currentPumps,
                                                [pumpType]: {
                                                    count: null,
                                                    value: null
                                                }
                                            })
                                        }
                                        : null
                                    }
                                    changePump={(pumpType, pumpId) => {
                                        setCurrentPumps({
                                            ...currentPumps,
                                            [pumpType]: {
                                                count: _.get(currentPumps[pumpType], 'count', 1),
                                                value: pumpId
                                            }
                                        })
                                    }}
                                    changePumpCount={(pumpType, count) => {
                                        setCurrentPumps({
                                            ...currentPumps,
                                            [pumpType]: {
                                                ...currentPumps[pumpType],
                                                count: count
                                            }
                                        })
                                    }}
                                    changeStatus={(status: string) => {
                                        let items = [...pumpAttributes];
                                        let item = {...items[index]}

                                        if (x.pumpSystemType === "turboPump") {
                                            let turboPipeIndex = items.map(e => e.pumpSystemType).indexOf('turboPipe');
                                            let turboPipeAttribute = {...items[turboPipeIndex]}
                                            if (status === "active") {
                                                turboPipeAttribute.status = "addable"
                                            } else if (status === "addable") {
                                                turboPipeAttribute.status = "unaddable"
                                            }
                                            items[turboPipeIndex] = turboPipeAttribute;
                                        }

                                        item.status = status;
                                        items[index] = item;

                                        setPumpAttributes(items);
                                    }}
                                />
                            })
                        }
                    </Form>
                    :
                    <div className={"pt-48 pb-40 flex-col full-width flex-align-items-center"}>
                        <Spin indicator={antIcon} />
                    </div>

            }
        </div>
    )
}
