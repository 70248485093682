import React, {useEffect, useState} from "react";
import _ from "lodash";
import useChamberStep from "../../hooks/useChamberStep";
import {Col, Form, FormInstance, Grid, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {calculateGeometry} from "../../utils/calculateGeometry";
import {getValidationStatus} from "../../utils/getValidationStatus";
import {formatSizeUnitsForSubmit} from "../../utils/formatSizeUnitsForSubmit";
import NumberInput from "../NumberInput";
import {stepConfig} from "../../config/pumpFinderChamberEvacuationStepConfig";
import SubmitTypeFormItem from "../SubmitTypeFormItem";
import {FeedbackBox} from "@dreebit/pv-components";
import InfoPanel from "../InfoPanel";

interface Props {
    form: FormInstance,
    stepId: string,
    onSubmit: (values: any, skipFormatting?: boolean) => void,
    initialValues: {[key: string]: string},
    fieldValidations: any[],
    calculationMode: string,
    calculationData?: any
}

export default function ChamberVolumeContent({form, stepId, onSubmit, initialValues, fieldValidations, calculationMode, calculationData}: Props) {

    const {t} = useTranslation();
    const step = useChamberStep(stepConfig, stepId);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const calculationCount = _.size(_.get(calculationData, 'calculation.calculationCollection.calculations'))

    if(!initialValues.chamberVolume && !form.getFieldValue("chamberVolume")) {
        form.setFieldsValue({
            chamberVolume: 0
        });
    }
    if(!initialValues.chamberSurface && !form.getFieldValue("chamberSurface")) {
        form.setFieldsValue({
            chamberSurface: 0
        });
    }

    const [geometry, setGeometry] = useState(initialValues.chamberVolumeGeometry);
    const flexAttributes = step.attributes.filter((attr) => _.get(attr, 'dependingOn', []).some(x => x === geometry)).map(y => y.id)

    const { Option } = Select;

    useEffect(() => {
        const tmp = calculateGeometry(formatSizeUnitsForSubmit(form.getFieldsValue(), flexAttributes, form))
        const calculatedVolume = tmp.volume >= 0.01 ? (Math.round(tmp.volume * 100) / 100) : (Math.round(tmp.volume * 1000) / 1000)
        const calculatedSurface = tmp.area >= 0.01 ? (Math.round(tmp.area * 100) / 100) : (Math.round(tmp.area * 1000) / 1000)

        if (geometry !== "custom") {
            form.setFieldsValue({
                chamberVolume: calculatedVolume,
                chamberSurface: calculatedSurface
            });
        }
    }, [geometry])

    return (
        <div>
            {
                calculationMode === "fixedPumpSystem" && calculationCount === 1 ?
                    <div className={"mb-32"}>
                        <InfoPanel
                            title={t("Compare pump systems")}
                            text={t('You can compare different pump systems in the last step Result by adding new or already saved calculations.')}
                        />
                    </div>
                : null
            }
            <h3 className={'font-bold'}>{t(step.title || "")}</h3>
            <p className={"mv-24"}>{t(step.subtitle || "")}</p>
            <Form
                id={`form-${stepId}`}
                layout={'vertical'}
                form={form}
                onFinish={(values) => {
                    onSubmit(formatSizeUnitsForSubmit(values, flexAttributes, form), false);
                }}
                initialValues={initialValues}
                onValuesChange={(changedValues, allValues) => {
                    if (geometry !== "custom") {
                        const tmp = calculateGeometry(formatSizeUnitsForSubmit(allValues, flexAttributes, form))
                        const calculatedVolume = tmp.volume >= 0.01 ? (Math.round(tmp.volume * 100) / 100) : (Math.round(tmp.volume * 1000) / 1000)
                        const calculatedSurface = tmp.area >= 0.01 ? (Math.round(tmp.area * 100) / 100) : (Math.round(tmp.area * 1000) / 1000)
                        form.setFieldsValue({
                            chamberVolume: calculatedVolume,
                            chamberSurface: calculatedSurface
                        })
                    }
                }}
            >
                <SubmitTypeFormItem />
                <Row gutter={16}>
                    {step.attributes.map((attribute) => {
                        return (
                            attribute.id === 'chamberVolumeGeometry' ?
                                <Col span={24} key={attribute.id}>
                                    <Form.Item
                                        name={attribute.id}
                                        label={t(attribute.label)}
                                        rules={[{ required: !attribute.optional }]}
                                        validateStatus={getValidationStatus(fieldValidations, attribute.id)}
                                    >
                                        {attribute.options?.length ?
                                            <Select onChange={(val: string) => setGeometry(val)}>
                                                {attribute.options.map((option, index) => {
                                                    return <Option value={option} key={index}>{t(option)}</Option>
                                                })}
                                        </Select>  : null}
                                    </Form.Item>
                                </Col>
                            : flexAttributes.includes(attribute.id) ?
                                attribute.options ?
                                    <Col span={screens.lg ? 24 / (flexAttributes.length) : 24} key={attribute.id}>
                                        <Form.Item
                                            name={attribute.id}
                                            label={t("Unit")}
                                            rules={[{ required: true }]}
                                            initialValue={initialValues.chamberVolumeHeightUnit}
                                        >
                                            <Select>
                                                {
                                                    attribute.options?.map((option, index) => {
                                                        return <Option value={option} key={index}>{option}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                :
                                    <Col
                                        span={screens.lg ? 24 / (flexAttributes.length) : 24}
                                        key={attribute.id}
                                    >
                                        <Form.Item
                                            name={attribute.id}
                                            label={t(attribute.label)}
                                            rules={[{ required: !attribute.optional }]}
                                            validateStatus={getValidationStatus(fieldValidations, attribute.id)}
                                        >
                                            <NumberInput />
                                        </Form.Item>
                                    </Col>
                            : ["chamberVolume", "chamberSurface"].includes(attribute.id) ?
                                <Col span={24} key={attribute.id}>
                                    <Row gutter={16} align={'top'}>
                                        <Col span={10} style={{marginTop: screens.md ? 10 : 0}}>
                                            <span>{t(attribute.label)}:</span>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name={attribute.id}
                                                validateStatus={getValidationStatus(fieldValidations, attribute.id)}
                                            >
                                                <NumberInput
                                                    disabled={geometry !== "custom"}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            {
                                                attribute.units?.length ?
                                                    <Form.Item
                                                        name={`${attribute.id}Unit`}
                                                        validateStatus={getValidationStatus(fieldValidations, attribute.id)}
                                                    >
                                                        <Select style={{ width: '100%' }}>
                                                            {attribute.units.map((unit, index) => {
                                                                return <Option
                                                                    value={unit.value}
                                                                    key={index}
                                                                >
                                                                    {unit.display}
                                                                </Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                : null
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            : null
                        )
                    })}
                </Row>
            </Form>
        </div>
    )
}
