import React from 'react';
import "./index.css"

interface Props {
    content: any[]
}



const DebugConsole = ({content}: Props) => {

    const jsonContent = JSON.stringify(content, undefined, 4)

    const syntaxHighlight =(json: any) => {
        if (!json) return ""; //no JSON from response

        json = json
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");
        return json.replace(
            /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
            function (match: any) {
                var cls = "number";
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = "key";
                    } else {
                        cls = "string";
                    }
                } else if (/true|false/.test(match)) {
                    cls = "boolean";
                } else if (/null/.test(match)) {
                    cls = "null";
                }
                return '<span class="' + cls + '">' + match + "</span>";
            }
        );
    }

    return (
        <div className={"debug-console-wrapper"}>
            <pre
                dangerouslySetInnerHTML={{
                    __html: syntaxHighlight(jsonContent)
                }}
            />
        </div>
    );
}

export default DebugConsole;
