import React, {useState} from "react";
import "./index.css";
import {Form, Select, Row, Col, Grid, Input, Upload} from "antd";
import _ from "lodash";
import {useMutation, useQuery} from "@apollo/client";
import {COUNTRY_LIST} from "../../graphql/queries/countryList";
import {CURRENT_USER} from "../../graphql/queries/currentUser";
import {SEND_CONTACT_FORM} from "../../graphql/mutations/sendContactForm";
import {Loader, Button} from "@dreebit/pv-components";
import Error from "../Error/Error";
import {useTranslation} from "react-i18next";
import {CloseOutlined, DownloadOutlined, MailOutlined} from "@ant-design/icons";
import pdfThumbnail from "../../assets/images/pdf_thumbnail.png"
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";
import {isRegionNeccessary} from "../../utils/isRegionNeccessary";
import {REGION_LIST} from "../../graphql/queries/regionList";

interface Props {
    resultId?: string
    resultPdf?: string
    onSuccess?: () => void
    onCancel?: () => void
}

export default function ContactForm({resultId, resultPdf, onSuccess, onCancel}: Props) {

    const {t} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const {
        loading: userLoading,
        error: userError,
        data: userData
    } = useQuery(CURRENT_USER, {});
    const currentUser = _.get(userData, 'currentUser')

    const [country, setCountry] = useState(_.get(currentUser, 'orgUnit.address.country', ''));

    const {
        loading: countriesLoading,
        error: countriesError,
        data: countriesData
    } = useQuery(COUNTRY_LIST, {});
    const countries = _.get(countriesData, 'countryList.countries', []);

    const {
        loading: regionsLoading,
        error: regionsError,
        data: regionsData
    } = useQuery(REGION_LIST, {
        variables: {
            country: country
        },
        skip: !isRegionNeccessary(country)
    });
    const regions = _.get(regionsData, 'regionList.regions', []);

    const [sendContactForm] = useMutation(SEND_CONTACT_FORM);

    const initialValues = {
        ...currentUser,
        country: _.get(currentUser, 'orgUnit.address.country', ''),
        region: _.get(currentUser, 'orgUnit.address.region', ''),
        companyName: _.get(currentUser, 'orgUnit.name', ''),
        postalCode: _.get(currentUser, 'orgUnit.address.postalCode', ''),
        town: _.get(currentUser, 'orgUnit.address.town', ''),
        street: _.get(currentUser, 'orgUnit.address.street', ''),
        number: _.get(currentUser, 'orgUnit.address.number', '')
    }

    const onValuesChange = (changedValues: any) => {
        const changedFieldKey = Object.keys(changedValues)[0];

        if(changedFieldKey === "country"){
            const changedValue = changedValues[changedFieldKey];
            setCountry(changedValue)
        }
    }

    const onFinish = (values: any) => {
        sendContactForm({
            variables: resultId ?
                {
                    id: resultId,
                    input: values
                } :
                {
                    input: values
                }
        }).then((res) => {
            const success = _.get(res, 'data.sendContactForm.success')

            if (success) {
                toast.success(<ToastContent
                    status={"success"}
                    text={_.get(res, 'data.sendContactForm.message')}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
                return onSuccess ? onSuccess() : null;
            } else {
                toast.error(<ToastContent
                    status={"error"}
                    text={t("send_contact_request_error")}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            }
        }).catch((err) => {
            toast.error(<ToastContent
                status={"error"}
                text={t("send_contact_request_error")}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    if (userLoading || countriesLoading) {
        return <Loader />
    }

    if (userError || countriesError || regionsError) {
        return <div className={'pt-40'}>
            {userError ? <Error error={userError} /> : null}
            {countriesError ? <Error error={countriesError} /> : null}
            {regionsError ? <Error error={regionsError} /> : null}
        </div>
    }

    const { Option } = Select;

    return (
        <Form
            layout="vertical"
            initialValues={initialValues}
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="country"
                        label={t('Country')}
                        rules={[{required: true}]}
                    >
                        <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) => {
                                if (option && option.children) {
                                    // @ts-ignore
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                return false;
                            }}
                        >
                            {
                                countries.map((country: any) => {
                                    return <Option
                                        key={_.get(country,'isocode')}
                                        value={_.get(country,'isocode')}
                                    >
                                        {_.get(country,'name')}
                                    </Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>

                {
                    isRegionNeccessary(country) && !regionsLoading && regions.length > 0 ?
                        <Col span={24}>
                            <Form.Item
                                name="region"
                                label={t('Region')}
                                rules={[{required: true}]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) => {
                                        if (option && option.children) {
                                            // @ts-ignore
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        return false;
                                    }}
                                >
                                    {
                                        regions.map((region: any) => {
                                            return <Option
                                                key={_.get(region, 'isocode')}
                                                value={_.get(region, 'isocode')}
                                            >
                                                {_.get(region, 'name')}
                                            </Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    : null
                }

                <Col span={screens.lg ? 12 : 24}>
                    <Form.Item
                        name="firstName"
                        label={t('First name')}
                        rules={[{required: true}]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={screens.lg ? 12 : 24}>
                    <Form.Item
                        name="lastName"
                        label={t('Last name')}
                        rules={[{required: true}]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        name="companyName"
                        label={t('Company')}
                        rules={[{required: true}]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={screens.lg ? 8 : 24}>
                    <Form.Item
                        name="postalCode"
                        label={t('ZIP code')}
                        rules={[{required: true}]}
                    >
                        <Input type={"number"}/>
                    </Form.Item>
                </Col>

                <Col span={screens.lg ? 16 : 24}>
                    <Form.Item
                        name="town"
                        label={t('City')}
                        rules={[{required: true}]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={18}>
                    <Form.Item
                        name="street"
                        label={t('Street')}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item
                        name="number"
                        label={t('street_no')}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        name="phone"
                        label={t('Phone')}
                    >
                        <Input type={"number"}/>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        name="email"
                        label={t('E-Mail')}
                        rules={[{required: true}]}
                    >
                        <Input/>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        name="message"
                        label={t('Message')}
                        rules={[{required: true}]}
                    >
                        <Input.TextArea  autoSize={{minRows: 5, maxRows: 10}}/>
                    </Form.Item>
                </Col>

                {
                    resultPdf ?
                        <Col span={24}>
                            <Form.Item
                                label={t('File')}
                            >
                                <Upload
                                    listType="picture"
                                    defaultFileList={[{
                                        uid: '1',
                                        name: 'calculation-result.pdf',
                                        status: 'done',
                                        url: resultPdf,
                                        thumbUrl: pdfThumbnail,
                                    }]}
                                    iconRender={() => <DownloadOutlined/>}
                                    className="upload-list-inline"
                                />
                            </Form.Item>
                        </Col>
                    : null
                }

                <Col span={screens.lg ? 8 : 24}>
                    <Form.Item>
                        <Button
                            type={"tertiary"}
                            htmlType="button"
                            className={'full-width'}
                            onClick={onCancel}
                            icon={"close"}
                        >
                            {t('Cancel')}
                        </Button>
                    </Form.Item>
                </Col>

                <Col span={screens.lg ? 8 : 24} offset={screens.lg ? 8 : 0}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={'full-width'}
                            icon={"mail"}
                        >
                            {t('Submit')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
