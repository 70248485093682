import React from "react";
import './index.css';
import {useTranslation} from "react-i18next";


interface Props {
    title: string,
    subtitle?: string,
    id?: any,
    type: 'default' | 'active' | 'disabled' | string,
    onClick?: () => void;
}

export default function HomeSelectionCard(props:Props) {

    const  {id, title, subtitle, type, onClick} = props;
    const {t} = useTranslation();

    return (
        <div
            className={'full-height'}
            onClick={onClick}
            data-testid={`selection-card`}
            data-cy={id}
        >
            {type === 'default' &&
                <div className={'ph-24 pv-24 selection-card selection-card-default'}>
                    <h3 className={'mb-0 text-default'}>{title}</h3>
                    {(subtitle) && <p className={'mt-8 mb-0 text-default'}>{subtitle}</p>}
                </div>}

            {type === 'active' &&
                <div className={'ph-24 pv-24 selection-card selection-card-active'}>
                    <h3 className={'mb-0 text-active'}>{title}</h3>
                    {(subtitle) && <p className={'mt-8 mb-0 text-active'}>{subtitle}</p>}
                </div>}

            {type === 'disabled' &&
                <div className={'ph-24 pv-24 selection-card selection-card-disabled'}>
                    <div className={'disabled-flag font-bold'}>
                        {t('soon_available_1')}
                        <br/>
                        {t('soon_available_2')}
                    </div>
                    <h3 className={'mb-0 text-disabled'}>{title}</h3>
                    {(subtitle) && <p className={'mt-8 mb-0 text-disabled'}>{subtitle}</p>}
                </div>}
        </div>
            )
}
