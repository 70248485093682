import React from "react";
import {Switch, Route, Redirect, useHistory, useLocation} from "react-router-dom";
import _ from "lodash";
import {stepConfig} from "../../config/fixedPumpSystemChamberEvacuationStepConfig";
import FixedPumpSystemChamberStepRoute from "./step";
import FixedPumpSystemResultRoute from "./result";

export default function FixedPumpSystemRoute() {

    const history = useHistory();
    const location = useLocation();

    const calculationMode = "fixedPumpSystem"
    const calculationType = "chamberEvacuation"

    const firstStepId = _.chain(stepConfig).first().get("id").value();

    const setMobileContent = (content: string) => {
        history.push(_.get(location, 'pathname') + '/mobile/' + content);
    };

    return (
        <Switch>
            {/*<Redirect*/}
            {/*    from={`/${calculationMode}/${calculationType}/:calculationId`}*/}
            {/*    to={`/${calculationMode}/${calculationType}/:calculationId` + firstStepId}*/}
            {/*/>*/}
            <Route
                path={`/${calculationMode}/${calculationType}/result/:collectionId`}
                component={() => <FixedPumpSystemResultRoute
                    calculationType={calculationType}
                    calculationMode={calculationMode}
                    onMobileContent={(content: string) => setMobileContent(content)}
                />}
            />
            <Route
                path={`/${calculationMode}/${calculationType}/:calculationId/:stepId`}
                component={() => <FixedPumpSystemChamberStepRoute
                    calculationType={calculationType}
                    calculationMode={calculationMode}
                    onMobileContent={(content: string) => setMobileContent(content)}
                />}
            />
            <Redirect
                from={`/${calculationMode}/${calculationType}/:calculationId`}
                to={`/${calculationMode}/${calculationType}/:calculationId/` + firstStepId}
            />
        </Switch>
    )
}
