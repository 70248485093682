import * as React from "react"
import {useTranslation} from "react-i18next";

export default function DesorptionRateSvg(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {

    const {t} = useTranslation();

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 488 224"
             width="100%"
             {...props}
        >
            <path fill="#f5f5f5" d="M0 0h488v224H0z" />
            <linearGradient
                id="prefix__a"
                gradientUnits="userSpaceOnUse"
                x1={183}
                y1={195.89}
                x2={0}
                y2={195.89}
                gradientTransform="matrix(1 0 0 -1 0 223.89)"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.344} stopColor="#e0e0e0" />
                <stop offset={0.641} stopColor="#e0e0e0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <text
                x="56"
                y="58"
                style={{fontWeight: 'bold', fontSize: '11px'}}
            >

                {t('Baked System')}
            </text>
            <path fill="url(#prefix__a)" d="M0 16h183v24H0z" />
            <linearGradient
                id="prefix__b"
                gradientUnits="userSpaceOnUse"
                x1={303}
                y1={195.89}
                x2={204}
                y2={195.89}
                gradientTransform="matrix(1 0 0 -1 0 223.89)"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.344} stopColor="#e0e0e0" />
                <stop offset={0.641} stopColor="#e0e0e0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <text
                x="210"
                y="56"
                style={{fontWeight: 'bold', fontSize: '11px'}}
            >
                {t('Typical UHV application')}
            </text>
            <path fill="url(#prefix__b)" d="M204 16h99v24h-99z" />
            <linearGradient
                id="prefix__c"
                gradientUnits="userSpaceOnUse"
                x1={364}
                y1={131.89}
                x2={252}
                y2={131.89}
                gradientTransform="matrix(1 0 0 -1 0 223.89)"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.344} stopColor="#e0e0e0" />
                <stop offset={0.641} stopColor="#e0e0e0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <text
                x="250"
                y="120"
                style={{fontWeight: 'bold', fontSize: '11px'}}
            >
                {t('Typical industry application')}
            </text>
            <path fill="url(#prefix__c)" d="M252 80h112v24H252z" />
            <linearGradient
                id="prefix__d"
                gradientUnits="userSpaceOnUse"
                x1={427}
                y1={67.89}
                x2={323}
                y2={67.89}
                gradientTransform="matrix(1 0 0 -1 0 223.89)"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.344} stopColor="#e0e0e0" />
                <stop offset={0.641} stopColor="#e0e0e0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <text
                x="307"
                y="185"
                style={{fontWeight: 'bold', fontSize: '11px'}}
            >
                {t('High desorption elastomeres')}
            </text>
            <path fill="url(#prefix__d)" d="M323 144h104v24H323z" />
        </svg>
    )
}


