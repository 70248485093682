import React, {useState} from 'react';
import {Button} from "@dreebit/pv-components";
import {Grid, Modal, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {isMobileOrTablet} from "../../utils/isMobileOrTablet";

type ButtonType = "back" | "createReport" | "downloadReport" | "newCalculation" | "save"

interface Props {
    buttons: ButtonType[],
    onBack?: () => void,
    onCreateReport?: () => void,
    onDownloadReport?: () => void,
    onNewCalculation?: () => void,
    onSave?: () => void,
    showSaveHint?: boolean,
    showCurveHint?: boolean,
    className?: string
}

export default function ResultActionButtons(props: Props) {

    const {buttons, onBack, onCreateReport, onDownloadReport, onNewCalculation, onSave, showSaveHint, showCurveHint, className} = props;

    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const {t} = useTranslation();

    let isMobile = isMobileOrTablet();

    return (
        <div className={`result-buttons ${screens.lg ? 'flex-row' : 'flex-col'} ${className}`}>
            <div className={screens.lg ? 'flex-grow-1' : ''}>
                {
                    buttons.includes("back") ?
                        <Button
                            type={'ghost'}
                            style={{width: screens.lg ? 'auto' : '100%'}}
                            onClick={onBack}
                            icon={"arrow_back"}
                            iconPosition={"left"}
                        >
                            {t('Back')}
                        </Button>
                    : null
                }
            </div>

            {
                buttons.includes("newCalculation") ?
                    <Button
                        type={"tertiary"}
                        className={`${screens.lg ? 'ml-8' : 'mt-24'}`}
                        style={{width: screens.lg ? 'auto' : '100%'}}
                        onClick={onNewCalculation}
                        icon={"restart_alt"}
                    >
                        {t('New calculation')}
                    </Button>
                : null
            }

            {
                buttons.includes("createReport") && !showCurveHint ?
                    <Button
                        type={'primary'}
                        className={`${screens.lg ? 'ml-8' : 'mt-24'}`}
                        style={{width: screens.lg ? 'auto' : '100%'}}
                        onClick={onCreateReport}
                        icon={"arrow_forward"}
                    >
                        {t('Pump curve report')}
                    </Button>
                : buttons.includes("createReport") && showCurveHint ?
                    <Tooltip title={t('pumpCurve-hint_lead')} trigger={isMobile ? "click" : "hover"}>
                        <div>
                            <Button
                                type={'primary'}
                                className={`${screens.lg ? 'ml-8' : 'mt-24'}`}
                                style={{width: screens.lg ? 'auto' : '100%'}}
                                disabled={true}
                                noPointerEvents={true}
                                icon={"arrow_forward"}
                            >
                                {t('Pump curve report')}
                            </Button>
                        </div>
                    </Tooltip>
                : null
            }

            {
                buttons.includes("downloadReport") ?
                    <Button
                        type={'primary'}
                        className={`${screens.lg ? 'ml-8' : 'mt-24'}`}
                        style={{width: screens.lg ? 'auto' : '100%'}}
                        onClick={onDownloadReport}
                        icon={"download"}
                    >
                        {t('Download Report')}
                    </Button>
                : null
            }

            {
                buttons.includes("save") && !showSaveHint ?
                    <Button
                        type={"secondary"}
                        className={`${screens.lg ? 'ml-8' : 'mt-24'}`}
                        style={{width: screens.lg ? 'auto' : '100%'}}
                        onClick={onSave}
                        icon={"save"}
                    >
                        {t('Save')}
                    </Button>
                : buttons.includes("save") && showSaveHint ?
                    <Tooltip title={t('calculation_save_hint')} trigger={isMobile ? "click" : "hover"}>
                        <div>
                            <Button
                                type={"secondary"}
                                className={`${screens.lg ? 'ml-8' : 'mt-24'}`}
                                style={{width: screens.lg ? 'auto' : '100%'}}
                                disabled={true}
                                noPointerEvents={true}
                                icon={"save"}
                            >
                                {t('Save')}
                            </Button>
                        </div>
                    </Tooltip>
                : null
            }
        </div>
    );
}
