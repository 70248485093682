import React, {useState} from "react";
import {Checkbox, Form, FormInstance, Grid, Input, Select} from 'antd'
import useChamberStep from "../../hooks/useChamberStep";
import {useTranslation} from "react-i18next";
import {getValidationStatus} from "../../utils/getValidationStatus";
import NumberInput from "../NumberInput";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import {stepConfig} from "../../config/pumpFinderChamberEvacuationStepConfig";
import SubmitTypeFormItem from "../SubmitTypeFormItem";

interface Props {
    form: FormInstance,
    stepId: string,
    onSubmit: (values: any, skipFormatting?: boolean) => void,
    initialValues: {[key: string]: string},
    onMobileContent: (content: string) => void
    fieldValidations: any[],
    calculationId: string,
    onGlobalMessage: (status: "success" | "warning" | "error", text: string, headline?: string) => void
}

export default function ChamberTargetPressureContent({form, stepId, onSubmit, initialValues, onMobileContent, fieldValidations, calculationId, onGlobalMessage}: Props) {

    const step = useChamberStep(stepConfig, stepId);
    const {t} = useTranslation();
    const mbarTheshold = 1e-7
    const pressureTheshold: {[key: string]: number} = {
        mbar: mbarTheshold,
        Torr: mbarTheshold * 0.750062,
        Pa: mbarTheshold * 100,
        hPa: mbarTheshold
    }

    const [ignoreTargetTime, setIgnoreTargetTime] = useState<boolean>(typeof initialValues?.ignoreTargetTime === "boolean" ? initialValues?.ignoreTargetTime : false)

    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const { Option } = Select;

    const checkPressureThreshold = (values: any) => {
        const pressureUnit = form.getFieldsValue(['targetPressureUnit'])?.targetPressureUnit;
        const pressureValue = form.getFieldsValue(['targetPressure'])?.targetPressure;

        if (
            pressureValue
            && parseLocalizedFloat(pressureValue) <= pressureTheshold[pressureUnit]
            && parseLocalizedFloat(pressureValue) !== 0
        ) {
            // save calculationIds which have already seen the warning
            // TODO: save to local state (dispatch)
            let warningIds = JSON.parse(localStorage.getItem('pressureThresholdWarning') || "[]");
            if (warningIds.includes(calculationId)) {
                return;
            } else {
                if (warningIds.length > 2) {
                    warningIds = warningIds.splice(warningIds.length - 2, 2);
                }
                warningIds.push(calculationId);
                localStorage.setItem('pressureThresholdWarning', JSON.stringify(warningIds));

                if (screens.lg) {
                    onGlobalMessage("warning", t('uhv_warning_subtitle'), t('uhv_warning_title'))
                } else {
                    onMobileContent("targetPressureWarning");
                }
            }
        }
    }

    return (
        <div>
            <h3 className={'font-bold'}>{t(step.title || "")}</h3>
            <p className={"mv-24"}>{t(step.subtitle || "")}</p>
            <Form
                id={`form-${stepId}`}
                form={form}
                layout={'vertical'}
                initialValues={initialValues}
                onValuesChange={checkPressureThreshold}
                onFinish={onSubmit}
            >
                <SubmitTypeFormItem />
                {step.attributes.map((attribute) => {

                    if (attribute.type === "number") {
                        return (
                            <Form.Item
                                key={attribute.id}
                                name={attribute.id}
                                label={t(attribute.label)}
                                rules={[{
                                    required: (ignoreTargetTime && attribute.id === "targetTime") ? false : !attribute.optional
                                }]}
                                validateStatus={getValidationStatus(fieldValidations, attribute.id)}
                            >
                                <NumberInput
                                    disabled={ignoreTargetTime && attribute.id === "targetTime"}
                                    addonAfter={ attribute.units ?
                                        (<Form.Item name={`${attribute.id}Unit`} noStyle>
                                            <Select tabIndex={-1}>
                                                {attribute.units.map((unit, index) => {
                                                    return <Option
                                                        value={unit.value}
                                                        key={index}
                                                    >
                                                        {unit.display}
                                                    </Option>
                                                })}
                                            </Select>
                                        </Form.Item>)
                                        : null}
                                />

                            </Form.Item>
                        )
                    }

                    if (attribute.type === "boolean") {
                        return (
                            <Form.Item
                                key={attribute.id}
                                name={attribute.id}
                                valuePropName={"checked"}
                            >
                                <Checkbox
                                    value={ignoreTargetTime}
                                    onChange={() => {
                                        if (!ignoreTargetTime) {
                                            form.setFieldsValue({
                                                targetTime: null
                                            })
                                        }
                                        setIgnoreTargetTime(!ignoreTargetTime)
                                    }}
                                >
                                    {t(attribute.label)}
                                </Checkbox>
                            </Form.Item>
                        )
                    }
                })}
            </Form>
        </div>
    )
}
