import React from "react";
import {Alert} from "antd";
import _ from "lodash";

interface Props {
    error: any
}

export default function Error({error}: Props) {

    if (_.has(error, 'graphQLErrors')) {
        return <Alert
            message={`GraphQL API Error: ${error.message}`}
            description={error.graphQLErrors.map(({ debugMessage }: any, i: any) => (
                <span key={i}>{debugMessage}</span>
            ))}
            type="error"
        />
    } else if (_.has(error, 'networkError')) {
        return <Alert
            message={`Network Error: ${error.message}`}
            description={error.networkError}
            type="error"
        />
    }
    return <Alert
        message={`Fetch Error: ${error.message}`}
        description={error}
        type="error"
    />
}

