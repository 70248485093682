import {gql} from "@apollo/client";

export const DELETE_CALCULATION = gql`
    mutation DeleteCalculation($ids: [ID]!) {
        deleteCalculation(calculationIds: $ids) {
            success
            message
        }
    }
`;
