import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_SURFACE} from "../../graphql/mutations/deleteSurface";
import {SURFACE_LIST} from "../../graphql/queries/surfaceList";
import _ from "lodash";
import {LoadingSpinner} from "@dreebit/pv-components";
import {Button, Empty, message, Pagination} from "antd";
import {ArrowRightOutlined, DeleteFilled} from "@ant-design/icons";
import {getDisplayUnit} from "../../utils/getDisplayUnit";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";

interface Props {
    onSelectSurface: (surface: any) => void
}

export default function SavedSurfacesList({onSelectSurface}: Props) {

    const {t} = useTranslation();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 7;

    const [deleteSurface] = useMutation(DELETE_SURFACE);
    const {loading, data} = useQuery(SURFACE_LIST, {
        variables: {
            start: (currentPage - 1)*pageSize,
            limit: pageSize
        },
        fetchPolicy: "network-only"
    });

    const totalCount = _.get(data, 'surfaceList.total', 0)
    const surfaces = _.get(data, 'surfaceList.surfaces', [])

    const _deleteSurface = (id: string) => {
        deleteSurface({
            variables: {
                id: id,
            },
            refetchQueries: [
                {
                    query: SURFACE_LIST,
                    variables: {
                        start: (currentPage - 1)*pageSize,
                        limit: pageSize
                    }
                }
            ],
            awaitRefetchQueries: true
        }).then((res) => {
            let success = _.get(res, 'data.deleteSurface.success')
            if (success) {
                toast.success(<ToastContent
                    status={"success"}
                    text={_.get(res, 'data.deleteSurface.message')}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            } else {
                toast.error(<ToastContent
                    status={"error"}
                    text={_.get(res, 'data.deleteSurface.message')}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            }
        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)
            toast.error(<ToastContent
                status={"error"}
                text={t('Error') + `: ${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    }

    if (loading) {
        return <LoadingSpinner />
    }

    if (totalCount === 0) {
        return <div className={'full-width'}>
            <Empty
                className={'mv-32'}
                description={t('No saved materials')}
            />
        </div>
    }

    return (
        <div className={'full-width flex-col flex-center align-center'}>

            <table className={'full-width'}>
                <tr>
                    <th>{t('Name')}</th>
                    <th>{t('Desorption rate')}</th>
                    <th></th>
                    <th></th>
                </tr>
                {surfaces ? surfaces.map((surface: any) => {
                    return <tr key={surface.id}>
                        <td className={'font-bold'}>{surface.title || t('No title')}</td>
                        <td>{surface.desorptionRate} {getDisplayUnit("desorptionRate", surface.desorptionRateUnit)}</td>
                        <td className={'font-bold'}>
                            <Button
                                type={'link'}
                                onClick={() => onSelectSurface(surface)}
                            >
                                {t('Select')}
                                <ArrowRightOutlined />
                            </Button>
                        </td>
                        <td className={'font-bold'}>
                            <Button type={'link'} onClick={() => _deleteSurface(surface.id)}>
                                <DeleteFilled />
                            </Button>
                        </td>
                    </tr>
                }) : null}
            </table>

            <Pagination
                style={{margin: "32px auto"}}
                defaultCurrent={1}
                current={currentPage}
                total={totalCount}
                defaultPageSize={pageSize}
                responsive={true}

                onChange={(page) => setCurrentPage(page)}
            />
        </div>
    )
}
