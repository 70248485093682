import {ChartSetting} from "../types";

export const pressureChartSetting: ChartSetting = {
    id: "pressure",
    label: "Pressure",
    units: [
        {
            display: "mbar",
            value: "mbar"
        },
        {
            display: "Torr",
            value: "Torr"
        },
        {
            display: "hPa",
            value: "hpa"
        },
        {
            display: "Pa",
            value: "Pa"
        }
    ]
}

export const suctionSpeedChartSetting: ChartSetting = {
    id: "suctionSpeed",
    label: "Suction speed",
    units: [
        {
            display: "m³/h",
            value: "m3_h"
        },
        {
            display: "l/s",
            value: "l_s"
        }
    ]
}

export const timeChartSetting: ChartSetting = {
    id: "time",
    label: "Time",
    units: [
        {
            display: "s",
            value: "s"
        },
        {
            display: "min",
            value: "min"
        },
        {
            display: "h",
            value: "h"
        },
        {
            display: "d",
            value: "d"
        }
    ]
}

export const gasFlowChartSetting: ChartSetting = {
    id: "gasFlow",
    label: "Gas flow",
    units: [
        {
            display: "sccm",
            value: "sccm"
        },
        {
            display: "mbar*l/s",
            value: "mbar_l_s"
        }
    ]
}
