import React from "react";
import useChamberStep from "../../hooks/useChamberStep";
import {Checkbox, Col, Form, FormInstance, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {InfoCircleOutlined} from "@ant-design/icons";
import {stepConfig} from "../../config/pumpFinderChamberEvacuationStepConfig";
import SubmitTypeFormItem from "../SubmitTypeFormItem";

interface Props {
    form: FormInstance,
    stepId: string,
    onSubmit: (values: any) => void,
    initialValues: {[key: string]: string}
}

export default function ChamberPumpContent({form, stepId, onSubmit, initialValues}: Props) {

    const {t} = useTranslation();
    const step = useChamberStep(stepConfig, stepId);

    const { Option } = Select;

    return (
        <div>
            <h3 className={'font-bold'}>{t(step.title || "")}</h3>
            <p className={"mv-24"}>{t(step.subtitle || "")}</p>
            <div>
                <InfoCircleOutlined style={{color: '#DD1541'}}/>
                <span className={'font-bold pl-8'}>{t('N2_note')}</span>
            </div>
            <Form
                id={`form-${stepId}`}
                onFinish={(values) => {
                    if (values.pumpingPrinciple === "") {
                        values.pumpingPrinciple = null
                    }
                    onSubmit(values)
                }}
                form={form}
                layout={'vertical'}
                className={'mt-24'}
                initialValues={initialValues}
            >
                <SubmitTypeFormItem />
                <Row gutter={16}>
                    {step.attributes.map((attribute, index) => {
                        return (
                            <Col
                                span={attribute.col}
                                key={attribute.id}
                            >
                                <Form.Item
                                    name={attribute.id}
                                    label={attribute.type === "boolean" ? null : t(attribute.label)}
                                    rules={[{ required: !attribute.optional }]}
                                    valuePropName={attribute.type === "boolean" ? "checked" : undefined}
                                >
                                    {attribute.type === "boolean" ?
                                        <Checkbox>
                                            <span>{t(attribute.label)} </span>
                                        </Checkbox> :
                                            attribute.options?.length ?
                                            <Select
                                                defaultValue={attribute.options[0]}
                                                allowClear={!!attribute.optional}
                                                disabled={!!attribute.disabled}
                                            >
                                                {attribute.options.map((option, index) => {
                                                    return <Option value={option} key={index}>{t(option)}</Option>
                                                })}
                                            </Select>  : null}
                                </Form.Item>
                            </Col>
                        )
                    })}
                </Row>
            </Form>
        </div>
    )
}
