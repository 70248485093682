import React from "react";
import {useTranslation} from "react-i18next";
import {FeedbackBox} from "@dreebit/pv-components";

interface Props {
    closeModal: () => void
}

export default function TargetPressureWarningMobileModal({closeModal}: Props) {


    const {t} = useTranslation();

    return (
        <div
            className={'full-width pt-16'}
            style={{overflowY: "scroll"}}
        >
            <div>
                <FeedbackBox
                    title={t('uhv_warning_title')}
                    subtitle={t('uhv_warning_subtitle')}
                    type={'warning'}
                />
            </div>
        </div>
    )
}
