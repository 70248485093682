export const parseJwt = (token: string | null) => {
    if (token) {
        return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    }
    return null;
}

export const getJwtExpireTime = (token: string | null) => {
    const parsedToken = parseJwt(token);
    const tokenExpireTime = parsedToken?.exp || null;

    return tokenExpireTime;
}
