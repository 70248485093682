import React, {useState} from "react";
import MaterialIcon from "../MaterialIcon";
import {useTranslation} from "react-i18next";
import "./index.css"

export default function LegalNotice(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {

    const {t} = useTranslation();
    const [showNote, setShowNote] = useState(false);

    return (
        <div className={props.className}>
            <div
                className={`legal-notice ${showNote ? 'pb-8' : ''}`}
                onClick={() => setShowNote(!showNote)}
            >
                <span>
                    {t('Legal notice')}
                </span>
                <MaterialIcon
                    name={showNote ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    style={{fontSize: "14px", paddingTop: "3px"}}
                />
            </div>
            {
                showNote ?
                    <p style={{textAlign: "justify"}}>{t('legal_notice_text')}</p>
                : null
            }
        </div>
    )
}
