import React from "react";
import {Route, Switch, useParams} from "react-router-dom";
import ChamberResultPumpCurveRoute from "./pumpCurves";
import MobileContentRoute from "../mobileContent";
import ChamberResultSolutionRoute from "./solutions";
import {useQuery} from "@apollo/client";
import {CALCULATION_REPORT_CONFIG} from "../../../graphql/queries/calculationReportConfig";
import {FeedbackBox, LoadingSpinner} from "@dreebit/pv-components";
import _ from "lodash";

interface Props {
    calculationMode: string
    calculationType: string
    onMobileContent: (content: string) => void
}

export default function PumpFinderChamberResultRoute({calculationMode, calculationType, onMobileContent}: Props) {

    const {collectionId} = useParams<any>();

    const {loading, error, data} = useQuery(CALCULATION_REPORT_CONFIG, {
        variables: {
            id: collectionId
        }
    });

    const calculationReportConfig = _.get(data, 'calculationCollection.calculationReportConfig')

    // for pumpFinder we only have exactly 1 calculation (in comparison to multiple results for fixedPumpSystem)
    const calculations = _.get(data, 'calculationCollection.calculations')
    const hasResult = !error && Array.isArray(calculations) && calculations.length === 1

    if (loading) {
        return <LoadingSpinner />
    }

    if (!hasResult) {
        return <div className={"mt-24"}>
            <FeedbackBox
                type={"error"}
                title={"Invalid calculation count"}
                subtitle={"For the pumpFinder only calculationCollections with exactly 1 calculation are supported."}
            />
        </div>
    }

    return (
        <Switch>
            <Route
                path={"/pumpFinder/chamberEvacuation/result/:collectionId/pumpCurves"}
                component={() => <ChamberResultPumpCurveRoute
                    calculationMode={calculationMode}
                    calculationType={calculationType}
                    onMobileContent={onMobileContent}
                    calculationReportConfig={calculationReportConfig}
                />}
            />
            <Route
                path={"/pumpFinder/chamberEvacuation/result/:collectionId/mobile/:content"}
                component={MobileContentRoute}
            />
            <Route
                path={"/pumpFinder/chamberEvacuation/result/:collectionId"}
                component={() => <ChamberResultSolutionRoute
                    calculationMode={calculationMode}
                    calculationType={calculationType}
                    onMobileContent={onMobileContent}
                    calculationReportConfig={calculationReportConfig}
                />}
            />
        </Switch>
    )
}
