// @ts-nocheck
import React, {useEffect, useState} from "react";
import {Switch, Route, Redirect, useLocation, useHistory} from "react-router-dom";
import {Header, Footer, KeycloakService} from "@dreebit/pv-components";
import Home from "../components/Home";
import {getUser, isLoggedIn} from "../redux/selectors/user";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {useQuery} from "@apollo/client";
import {CURRENT_USER} from "../graphql/queries/currentUser";
import ContactForm from "../components/ContactForm";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import {DataActionType} from "../redux/reducers";
import PumpFinderRoute from "./pumpFinder/index";
import FixedPumpSystemRoute from "./fixedPumpSystem";
import {toast} from 'react-toastify';
import ToastContent from "../components/ToastContent";
import {getJwtExpireTime} from "../utils/parseJWT";
import MobileContentRoute from "./pumpFinder/mobileContent";
const pjson = require('../../package.json')

const RouteWrapper = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const loggedIn = useSelector(isLoggedIn);
    const location = useLocation();
    const history = useHistory();
    const {t, i18n} = useTranslation();

    const changeLanguage = (lng: string) => {
        if (window.location.href.indexOf('/de/') > -1 && lng === 'en') {
            return window.location.href = window.location.href.replace('/de/', '/en/');
        } else if (lng === 'de') {
            return window.location.href = window.location.href.replace('/en/', '/de/');
        }
    }

    const {loading, error, data} = useQuery(CURRENT_USER, {});
    const currentUser = _.get(data, 'currentUser', null)
    const salesEngineer = _.get(data, 'currentUser.orgUnit.salesEngineer', {})
    const keycloak = KeycloakService.getInstance();
    const backendVar = _.get(window, '__BE__');
    const token = window.sessionStorage.getItem('token');

    const beVersion = _.get(backendVar, 'version', null);
    const feVersion = _.get(pjson, 'version', null);
    const cnIcpData = _.get(backendVar, 'cn', null);


    const handleLogin = () => {
        return keycloak.login({
            locale: i18n?.language
        });
    }

    const handleLogout = () => {
        keycloak.logout();
        dispatch({
            type: DataActionType.LOG_OUT,
        });
        window.sessionStorage.removeItem('token');
    }

    useEffect(() => {
        const tokenExpireTime = getJwtExpireTime(token);

        if (currentUser) {
            dispatch({
                type: DataActionType.SET_USER,
                payload: {
                    name: currentUser.name,
                    registrationStatus: currentUser.registrationStatusCode
                }
            });
        // Der Fall, dass man zwar beim SSO eingeloggt wird, aber vom BE (bzw. Hybris) keine Userdaten bekommt
        } else if (!loading && !currentUser && token) {
            dispatch({
                type: DataActionType.LOG_OUT,
            });
            window.sessionStorage.removeItem('token');

            toast.error(<ToastContent
                status={"error"}
                text={t('no_currentUser_error_message')}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        } else if (!token || tokenExpireTime*1000 < Date.now()){
            dispatch({
                type: DataActionType.LOG_OUT,
            });
            window.sessionStorage.removeItem('token');
        }
    }, [currentUser, token, loading])

    const [contactVisible, setContactVisible] = useState(false);

    const openContactModal = () => {
        setContactVisible(true);
    }

    const pathName = _.get(location, 'pathname')
    const isMobileOverlay = pathName.includes('/mobile/')

    const setMobileContent = (content: string) => {
        history.push('/mobile/' + content);
    };

    const routingContent = <Switch>
        <Route path={"/pumpFinder"} component={PumpFinderRoute} />
        <Route path={"/fixedPumpSystem"} component={FixedPumpSystemRoute} />
        <Route
            path={`/mobile/:content`}
            component={MobileContentRoute}
        />
        <Route exact path="/" component={() => <Home onMobileContent={(content: string) => setMobileContent(content)}/>} />
        <Redirect from="" to={"/"} />
    </Switch>

    if (isMobileOverlay) {
        return <div className={'p-16'}>
            {routingContent}
        </div>
    }

    return (
        <div className={'flex-col full-min-height'}>
            <div className={'site-container flex-grow-1 pb-96'}>
                <div>
                    <Header
                        onLogoClick={() => {
                            history.push('/')
                            toast.dismiss();
                        }}
                        onChangeLanguage={changeLanguage}
                        onLogin={handleLogin}
                        onLogout={handleLogout}
                        authenticated={loggedIn}
                        userName={_.get(user, 'name', '')}
                        salesEngineer={loggedIn && salesEngineer?.name && salesEngineer?.phone ?
                            {name: salesEngineer.name, phone: salesEngineer.phone}
                            : undefined
                        }
                        onContact={openContactModal}
                    />
                </div>
                {routingContent}
            </div>
            <Footer
                className={'site-container'}
                onChangeLanguage={changeLanguage}
                env={_.get(backendVar, 'env', 'production')}
                dataProtectionLink={`/${i18n?.language}/privacy-policy`}
                version={{beVersion, feVersion}}
                cnIcpData={cnIcpData}
            />
            <Modal
                title={t('Contact us')}
                visible={contactVisible}
                onCancel={() => setContactVisible(false)}
                footer={null}
                width={720}
            >
                <ContactForm
                    onSuccess={() => setContactVisible(false)}
                    onCancel={() => setContactVisible(false)}
                />
            </Modal>
        </div>
    )
}

export default RouteWrapper;
