import React from "react";
import {MaterialIcon} from "@dreebit/pv-components";

interface Props {
    title?: string,
    text: string
}

export default function InfoPanel({text, title}:Props) {

    return (
        <div
            className={'full-width p-16 flex flex-align-items-center'}
            style={{backgroundColor: '#f5f5f5', color: '#7a7a7a', borderRadius: "6px"}}
        >
            <MaterialIcon type={"info_outline"}/>
            <div className={'pl-16'}>
                { title ? <div style={{fontSize: 16, fontWeight: "bold"}}>{title}</div> : null }
                {text}
            </div>
        </div>
    )
}
