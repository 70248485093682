// @ts-nocheck
import * as React from "react"
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-svg-tooltip";
import "./index.css"

export default function LeakRateSvg(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {

    const {t} = useTranslation();

    const uhvHint = React.createRef<SVGSVGElement>();
    const hvHint = React.createRef<SVGSVGElement>();
    const fvHint = React.createRef<SVGSVGElement>();
    const rvHint = React.createRef<SVGSVGElement>();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 488 184"
            xmlSpace="preserve"
            {...props}
        >
            <style>{".prefix__st2{fill:#292929}"}</style>
            <path fill="#f5f5f5" d="M0 0h488v184H0z" />
            <linearGradient
                id="prefix__SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1={176}
                y1={167.89}
                x2={0}
                y2={167.89}
                gradientTransform="matrix(1 0 0 -1 0 199.89)"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.162} stopColor="#e0e0e0" />
                <stop offset={0.828} stopColor="#e0e0e0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <path fill="url(#prefix__SVGID_1_)" d="M0 16h176v32H0z" />
            <text
                x="31"
                y="36"
                style={{fontWeight: 'bold', fontSize: '11px'}}
            >

                {t('Ultra high vacuum')}
            </text>
            <svg ref={uhvHint}>
                <path
                    className="prefix__st2"
                    d="M141 25.3a6.7 6.7 0 100 13.4 6.7 6.7 0 000-13.4zm.7 10h-1.3v-4h1.3v4zm0-5.3h-1.3v-1.3h1.3V30z"
                />
            </svg>
            <Tooltip triggerRef={uhvHint}>
                <foreignObject x="10" y="10" width="300" height="100">
                    <div className={"leak-rate-svg-tooltip"}>
                        {t('ultrahigh_vacuum_hint')}
                    </div>
                </foreignObject>
            </Tooltip>
            <linearGradient
                id="prefix__SVGID_2_"
                gradientUnits="userSpaceOnUse"
                x1={187}
                y1={167.89}
                x2={0}
                y2={167.89}
                gradientTransform="matrix(1 0 0 -1 126 239.89)"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.162} stopColor="#e0e0e0" />
                <stop offset={0.828} stopColor="#e0e0e0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <path fill="url(#prefix__SVGID_2_)" d="M126 56h187v32H126z" />
            <text
                x="173"
                y="75.5"
                style={{fontWeight: 'bold', fontSize: '11px'}}
            >

                {t('High vacuum')}
            </text>
            <svg ref={hvHint}>
                <path
                    className="prefix__st2"
                    d="M259 65.3a6.7 6.7 0 100 13.4 6.7 6.7 0 000-13.4zm.7 10h-1.3v-4h1.3v4zm0-5.3h-1.3v-1.3h1.3V70z"
                />
            </svg>
            <Tooltip triggerRef={hvHint}>
                <foreignObject x="-150" y="15" width="300" height="100">
                    <div className={"leak-rate-svg-tooltip"}>
                        {t('high_vacuum_hint')}
                    </div>
                </foreignObject>
            </Tooltip>
            <linearGradient
                id="prefix__SVGID_3_"
                gradientUnits="userSpaceOnUse"
                x1={153}
                y1={167.89}
                x2={0}
                y2={167.89}
                gradientTransform="matrix(1 0 0 -1 276 279.89)"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.162} stopColor="#e0e0e0" />
                <stop offset={0.828} stopColor="#e0e0e0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <path fill="url(#prefix__SVGID_3_)" d="M276 96h153v32H276z" />
            <text
                x="308"
                y="115"
                style={{fontWeight: 'bold', fontSize: '11px'}}
            >

                {t('Fine vacuum')}
            </text>
            <svg ref={fvHint}>
                <path
                    className="prefix__st2"
                    d="M389.5 105.3a6.7 6.7 0 100 13.4 6.7 6.7 0 000-13.4zm.7 10h-1.3v-4h1.3v4zm0-5.3h-1.3v-1.3h1.3v1.3z"
                />
            </svg>
            <Tooltip triggerRef={fvHint}>
                <foreignObject x="-310" y="-80" width="300" height="100">
                    <div className={"leak-rate-svg-tooltip"}>
                        {t('fine_vacuum_hint')}
                    </div>
                </foreignObject>
            </Tooltip>
            <linearGradient
                id="prefix__SVGID_4_"
                gradientUnits="userSpaceOnUse"
                x1={123}
                y1={167.89}
                x2={0}
                y2={167.89}
                gradientTransform="matrix(1 0 0 -1 365 319.89)"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.162} stopColor="#e0e0e0" />
                <stop offset={0.828} stopColor="#e0e0e0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <path fill="url(#prefix__SVGID_4_)" d="M365 136h123v32H365z" />
            <text
                x="378"
                y="156"
                style={{fontWeight: 'bold', fontSize: '11px'}}
            >

                {t('Rough vacuum')}
            </text>
            <svg ref={rvHint}>
                <path
                    className="prefix__st2"
                    d="M465.5 145.3a6.7 6.7 0 100 13.4 6.7 6.7 0 000-13.4zm.7 10h-1.3v-4h1.3v4zm0-5.3h-1.3v-1.3h1.3v1.3z"
                />
            </svg>
            <Tooltip triggerRef={rvHint}>
                <foreignObject x="-310" y="-110" width="300" height="130">
                    <div className={"leak-rate-svg-tooltip"}>
                        {t('rough_vacuum_hint')}
                    </div>
                </foreignObject>
            </Tooltip>
        </svg>
    )
}
