import React from "react";
import {Switch, Route, Redirect, useHistory, useLocation} from "react-router-dom";
import _ from "lodash";
import PumpFinderChamberResultRoute from "./result";
import PumpFinderChamberStepRoute from "./step";
import MobileContentRoute from "./mobileContent"
import {stepConfig} from "../../config/pumpFinderChamberEvacuationStepConfig";

export default function PumpFinderRoute() {

    const history = useHistory();
    const location = useLocation();

    const calculationMode = "pumpFinder"
    const calculationType = "chamberEvacuation"

    const firstStepId = _.chain(stepConfig).first().get("id").value();

    const setMobileContent = (content: string) => {
        history.push(_.get(location, 'pathname') + '/mobile/' + content);
    };

    return (
        <Switch>
            <Route
                path={`/${calculationMode}/${calculationType}/result/:collectionId`}
                component={() => <PumpFinderChamberResultRoute
                    calculationType={calculationType}
                    calculationMode={calculationMode}
                    onMobileContent={(content: string) => setMobileContent(content)}
                />}
            />
            <Route
                path={`/${calculationMode}/${calculationType}/:calculationId/:stepId/mobile/:content`}
                component={MobileContentRoute}
            />
            <Route
                path={`/${calculationMode}/${calculationType}/:calculationId/:stepId`}
                component={() => <PumpFinderChamberStepRoute
                    calculationType={calculationType}
                    calculationMode={calculationMode}
                    onMobileContent={(content: string) => setMobileContent(content)}
                />}
            />
            <Redirect
                from={`/${calculationMode}/${calculationType}/:calculationId`}
                to={`/${calculationMode}/${calculationType}/:calculationId/` + firstStepId}
            />
        </Switch>
    )
}
