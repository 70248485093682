import {gql} from "@apollo/client";

export const CALCULATION_RESULT_SOLUTIONS = gql`
    query CalculationResultSolutions($id: ID!, $solutionParams: SolutionParamsInput) {
        calculationCollection (calculationCollectionId: $id)  {
            id
            link
            sheetUrl
            calculations {
                id
                otherSuitableProducts {
                  key
                  link
                  title
                  filters {
                    id
                    filterId
                    name
                    value
                  }
                }
                values {
                    targetPressure {
                        unit
                        value
                    }
                    targetTime {
                        unit
                        value
                    }
                    ignoreTargetTime {
                        value
                    }
                    chamberSurface {
                        unit
                        value
                    }
                    chamberVolume {
                        unit
                        value
                    }
                    chamberVolumeGeometry {
                        value
                    }
                    chamberVolumeHeight {
                        unit
                        value
                    }
                    chamberVolumeLength {
                        unit
                        value
                    }
                    chamberVolumeWidth {
                        unit
                        value
                    }
                    chamberVolumeRadius {
                        unit
                        value
                    }
                    desorption {
                        desorptionRate {
                            unit
                            value
                        }
                        desorptionSurfaceName {
                            value
                        }
                        desorptionSurfaceSize {
                            unit
                            value
                        }
                    }
                    leakRate {
                        unit
                        value
                    }
                    primaryPipeDefault {
                        value
                    }
                    primaryPipeLength {
                        value
                        unit
                    }
                    primaryPipeDiameter {
                        value
                        unit
                    }
                    primaryPipeElbowCount {
                        value
                    }
                    turboPipeDefault {
                        value
                    }
                    turboPipeLength {
                        value
                        unit
                    }
                    turboPipeDiameter {
                        unit
                        value
                    }
                    enforceSameCount {
                        value
                    }
                    pumpFrequency {
                        value
                    }
                    pumpGasType {
                        value
                    }
                    pumpingPrinciple {
                        value
                    }
                }
            }
            solutionList (params: $solutionParams) {
                total
                pumpingStationFilter
                solutions {
                    id
                    count
                    type
                    totalEstimatedTime
                    price
                    weight
                    noise
                    debugInfo
                    components {
                        title
                        count
                        type
                        url
                        imageUrl
                        filters {
                            name
                            value
                        }
                        rootsPump {
                            title
                            count
                        }
                        backingPump {
                            title
                            count
                        }
                        turboPump {
                            title
                            count
                        }
                    }
                }
            }
            calculationReportConfig {
                  id
                  solutionSort {
                    key
                    direction
                  }
            }
        }
    }
`;
