import {Step, StepAttribute} from "../types";
import {defaultDesorptionRate, defaultDesorptionUnit, defaultSizeUnit} from "../config/defaultValues";

const getInitialFormValues = (step: Step, calculationValues: any) => {

    if (step.id === "chamberStepDesorptionRate") {
        if (calculationValues.desorption) {
            return calculationValues.desorption
        } else {
            return [
                {
                    desorptionRate: {
                        unit: defaultDesorptionUnit,
                        value: defaultDesorptionRate
                    },
                    desorptionSurfaceName: {
                        value: ""
                    },
                    desorptionSurfaceSize: {
                        unit: calculationValues.chamberSurface?.unit || defaultSizeUnit,
                        value: calculationValues.chamberSurface?.value || ""
                    }
                }
            ]
        }
    }

    let obj: any = {};

    step.attributes.forEach((attr: StepAttribute) => {
        if (attr.units) {
            Object.assign(obj, {
                [attr.id]: calculationValues[attr.id]?.value || "",
                [attr.id + 'Unit']: calculationValues[attr.id]?.unit || attr.units[0].value
            })
        } else if (attr.options) {
            if (attr.id === "pumpingPrinciple" && (!calculationValues[attr.id]?.value || calculationValues[attr.id]?.value === null)) {
                Object.assign(obj, {
                    [attr.id]: ""
                })
            } else {
                Object.assign(obj, {
                    [attr.id]: calculationValues[attr.id]?.value || attr.options[0]
                })
            }
        } else if (attr.type === "boolean") {
            Object.assign(obj, {
                [attr.id]: typeof calculationValues[attr.id]?.value === "boolean" ? calculationValues[attr.id].value : attr.defaultValue
            })
        } else {
            Object.assign(obj, {
                [attr.id]: calculationValues[attr.id]?.value || ""
            })
        }
    })

    if (step.id === "chamberStepChamberVolume") {
        obj = {
            ...obj,
            chamberSizeUnits: calculationValues["chamberVolumeHeight"]?.unit || calculationValues["chamberVolumeRadius"]?.unit || "cm"
        }
    }

    return obj;
}
export default getInitialFormValues;
