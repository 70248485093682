import React from "react";
import {useTranslation} from "react-i18next";
import MaterialIcon from "../MaterialIcon";
import './index.css';

interface Props {
    toggle: () => void
    extended: boolean
}

export default function ShowMoreAndLessButton(props: Props & React.ButtonHTMLAttributes<any>) {
    const {t} = useTranslation();
    const {toggle, extended} = props;

    return (
        <button
            onClick={toggle}
            className={'more-less-button ' + props.className}
        >
            {extended ? t('Show less options') : t('Show more options')}
            <MaterialIcon name={extended ? 'keyboard_double_arrow_up' : 'keyboard_double_arrow_down'} className={'ml-8'} />
        </button>
    )
}
