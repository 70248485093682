import React from "react";
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import RouteWrapper from "./routes"
import ScrollToTop from "../hooks/ScrollToTop";


const Routes = () => {

    return (
        <div className={'full-height'}>
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route path="/" component={() => <RouteWrapper />} />
                </Switch>
            </Router>
        </div>
    )
}

export default Routes;
