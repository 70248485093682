import _ from "lodash"

// format chartjs data and options into a json so the backend can use the data for the pdf
export const generateChartConfig = (chartRefConfig: {[key: string]: any}): string => {

    let data = _.get(chartRefConfig, 'data');
    // set data point radius to zero, because the PDF-render doesn't need single data points
    data = {
        ...data,
        datasets: data.datasets.map((dataset: any) => {
            return {
                ...dataset,
                pointRadius: 0
            }
        })
    };

    const options = _.get(chartRefConfig, 'options');

    delete options.plugins["tooltip"];
    delete options.plugins["zoom"];
    delete options.plugins["annotation"];

    let result = {
        type: "line",
        data,
        options,
    }

    return JSON.stringify(result, function(key, value) {
        if (typeof value === "function") {
            return "/Function(" + value.toString() + ")/";
        }
        return value;
    });
}
