// @ts-nocheck
import React, {useEffect, useState} from "react";
import useChamberStep from "../../hooks/useChamberStep";
import {Checkbox, Col, Form, FormInstance, Row, Select} from "antd";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {getValidationStatus} from "../../utils/getValidationStatus";
import NumberInput from "../NumberInput";
import InfoPanel from "../InfoPanel";
import {stepConfig} from "../../config/pumpFinderChamberEvacuationStepConfig";
import SubmitTypeFormItem from "../SubmitTypeFormItem";

interface Props {
    form: FormInstance,
    stepId: string,
    onSubmit: (values: any, skipFormatting?: boolean) => void,
    initialValues: {[key: string]: any},
    fieldValidations: any[]
}

export default function ChamberPipeLengthContent({form, stepId, onSubmit, initialValues, fieldValidations}: Props) {

    const step = useChamberStep(stepConfig, stepId);
    const {t} = useTranslation();

    const [primaryDefault, setPrimaryDefault] = useState(_.get(initialValues, 'primaryPipeDefault', true));
    const [turboDefault, setTurboDefault] = useState(_.get(initialValues, 'turboPipeDefault', true));

    const { Option } = Select;

    const primaryAttributes = step.attributes.filter(x => x.category?.key === 'primary');
    const turboAttributes = step.attributes.filter(x => x.category?.key === 'turbo');

    return (
        <div>
            <h3 className={'font-bold'}>{t(step.title || "")}</h3>
            <p className={"mv-24"}>{t(step.subtitle || "")}</p>

            <Form
                id={`form-${stepId}`}
                form={form}
                className={'mt-24'}
                layout={'vertical'}
                onFinish={(values) => {
                    let temp = values

                    Object.keys(temp).forEach(key => {
                        if (temp[key] === undefined) {
                            delete temp[key];
                        }
                    });

                    onSubmit(temp, false);
                }}
                initialValues={initialValues}
            >
                <SubmitTypeFormItem />
                <Row gutter={16}>
                    <h5 className={'pl-8'}>{t(primaryAttributes[0].category.title)}</h5>
                    {
                        primaryAttributes.map((attribute) => {
                            if (attribute.type === "boolean") {
                                return <Col span={24} key={attribute.id}>
                                    <Form.Item
                                        name={attribute.id}
                                        valuePropName="checked"
                                        initialValue={primaryDefault}
                                    >
                                        <Checkbox
                                            checked={primaryDefault}
                                            onChange={() => setPrimaryDefault(!primaryDefault)}
                                        >
                                            {t(attribute.label)}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            } else {
                                if (!primaryDefault) {
                                    return <Col span={12} key={attribute.id}>
                                        <Form.Item
                                            name={attribute.id}
                                            label={t(attribute.label)}
                                            rules={[{ required: !attribute.optional }]}
                                            validateStatus={getValidationStatus(fieldValidations, attribute.id)}
                                        >
                                            <NumberInput
                                                integer={attribute.id === "primaryPipeBendCount"}
                                                addonAfter={ attribute.units?.length ?
                                                    <Form.Item name={`${attribute.id}Unit`} noStyle>
                                                        <Select tabIndex={-1}>
                                                            {attribute.units.map((unit, index) => {
                                                                return <Option
                                                                    value={unit.value}
                                                                    key={index}
                                                                >
                                                                    {unit.display}
                                                                </Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                    : null}
                                            />
                                        </Form.Item>
                                    </Col>
                                }
                            }
                        })
                    }
                    {
                        primaryDefault ?
                            <div className={'mb-40'}>
                                <InfoPanel text={t('pipeLength_standardValues_info')} />
                            </div>
                        : null
                    }
                    <h5 className={'pl-8'}>{t(turboAttributes[0].category.title)}</h5>
                    {
                        turboAttributes.map((attribute) => {
                            if (attribute.type === "boolean") {
                                return <Col span={24} key={attribute.id}>
                                    <Form.Item
                                        name={attribute.id}
                                        valuePropName="checked"
                                        initialValue={turboDefault}
                                    >
                                        <Checkbox
                                            checked={turboDefault}
                                            onChange={() => setTurboDefault(!turboDefault)}
                                        >
                                            {t(attribute.label)}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            } else {
                                if (!turboDefault) {
                                    return <Col span={12} key={attribute.id}>
                                        <Form.Item
                                            name={attribute.id}
                                            label={t(attribute.label)}
                                            rules={[{ required: !attribute.optional }]}
                                            validateStatus={getValidationStatus(fieldValidations, attribute.id)}
                                        >
                                            <NumberInput
                                                addonAfter={ attribute.units?.length ?
                                                    <Form.Item name={`${attribute.id}Unit`} noStyle>
                                                        <Select tabIndex={-1}>
                                                            {attribute.units.map((unit, index) => {
                                                                return <Option
                                                                    value={unit.value}
                                                                    key={index}
                                                                >
                                                                    {unit.display}
                                                                </Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                    : null}
                                            />
                                        </Form.Item>
                                    </Col>
                                }
                            }
                        })
                    }
                </Row>
            </Form>


            {/*<Checkbox
                checked={checked}
                onChange={() => setCheck(!checked)}
            >
                {t('Calculate with standard value')}
            </Checkbox>
            {!checked ?
                <Form
                    id={`form-${stepId}`}
                    className={'mt-24'}
                    layout={'vertical'}
                    onFinish={onSubmit}
                    initialValues={initialValues}
                >
                    <Row gutter={16}>
                        {step.attributes.map((attribute) => {
                            if (attribute.type !== "boolean") {
                                return <Col span={12} key={attribute.id}>
                                    <Form.Item
                                        name={attribute.id}
                                        label={t(attribute.label)}
                                        rules={[{ required: !attribute.optional }]}
                                        validateStatus={getValidationStatus(fieldValidations, attribute.id)}
                                    >
                                        <NumberInput
                                            addonAfter={ attribute.units?.length ?
                                                <Form.Item name={`${attribute.id}Unit`}>
                                                    <Select>
                                                        {attribute.units.map((unit, index) => {
                                                            return <Option
                                                                value={unit.value}
                                                                key={index}
                                                            >
                                                                {unit.display}
                                                            </Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                : null}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                        })}
                    </Row>
                </Form>
                :
                <Form
                    id={`form-${stepId}`}
                    className={'mt-24'}
                    onFinish={() => {
                        const data = {
                            pipeDefault: {
                                value: true
                            }
                        };
                        onSubmit(data, true);}
                    }
                    initialValues={initialValues}
                >
                    <InfoPanel
                        text={t('pipeLength_standardValues_info')}
                    />
                </Form>
            }*/}
        </div>
    )
}
