import {gql} from "@apollo/client";

export const REGION_LIST = gql`
    query RegionList($country: String!) {
        regionList(country: $country) {
            total
            regions {
                isocode
                name
            }
        }
    }
`;
