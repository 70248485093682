export interface FieldValidation {
    key: any,
    message: any,
    type: any
}

export const getFieldValidations = (errors: any[]): FieldValidation[] => {
    if (errors[0].fields) {
        let fieldValidations: FieldValidation[] = [];

        Object.keys(errors[0].fields).map((key, index) => {
            fieldValidations.push({
                key: Object.keys(errors[0].fields)[index],
                type: Object.keys(errors[0].fields[key])[0],
                message: errors[0].fields[key][Object.keys(errors[0].fields[key])[0]]
            })
        })
       return fieldValidations
    }

    return []
}
