import React from 'react';
import "./index.css";
import {Checkbox, Col, Collapse, Grid, Row} from "antd";
import {getSolutionTitle} from "../../utils/getSolutionTitle";
import _ from "lodash";
import moment from "moment";
import {Button, MaterialIcon} from "@dreebit/pv-components";
import {useTranslation} from "react-i18next";

interface Props {
    solution?: any,
    calculation?: any,
    activeSolutionIndizes: string[],
    colorConfig: string[],
    index: number,
    onCheck: (id: string) => void,
    onEdit?: (calculationId: string) => void,
    onRemove?: (calculationId: string) => void,
    incomplete?: boolean
}

const SolutionListItem = ({solution, calculation, activeSolutionIndizes, colorConfig, index, onCheck, onEdit, onRemove, incomplete}: Props) => {

    const {t} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const calculationId = incomplete ? _.get(calculation, 'id') : _.get(solution, 'calculation.id')

    return <div className={`full-width`}>
        <Checkbox
            checked={incomplete ? false : activeSolutionIndizes.includes(solution.id)}
            disabled={!!incomplete}
            className={`full-width ${screens.lg ? "" : "flex flex-align-items-center"}`}
            onClick={(event) => {
                if (incomplete) {
                    return;
                }
                event.stopPropagation()
                onCheck(solution.id)}
            }
        >
            <div className={'flex flex-align-items-center full-width'}>
                {
                    incomplete ?
                        <span>{t('Incomplete calculation from')} {moment(_.get(calculation, 'insert')).format("L")}</span>
                        :
                        <span>{getSolutionTitle(solution, t)}</span>
                }

                <i
                    className="material-icons ml-8 solution-chart-icon"
                    style={{color: incomplete ? "#ccc" : colorConfig[index]}}
                >
                    timeline
                </i>
                <div className={"flex solution-list-item-button-wrapper"}>
                    {
                        onEdit ?
                            <Button
                                type={"ghost"}
                                icon={"edit"}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    onEdit(calculationId)
                                }}
                            />
                            : null
                    }
                    {
                        onRemove ?
                            <Button
                                type={"ghost"}
                                icon={"delete"}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    onRemove(calculationId)
                                }}
                            />
                            : null
                    }
                </div>
            </div>
        </Checkbox>
    </div>
}

export default SolutionListItem;
