// @ts-nocheck
import React, {forwardRef, useEffect} from "react";
import {Col, Form, Grid, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import NumberInput from "../NumberInput";
import {ChartSetting} from "../../types";

interface Props {
    chartSetting: ChartSetting
    defaultValues?: any
    onValuesChange?: (values) => void
}

const ChartSettingsForm = forwardRef(({chartSetting, defaultValues, onValuesChange}: Props, ref) => {

    const {t} = useTranslation()
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const [form] = Form.useForm();

    const { Option } = Select;

    const initialValues = {
        unit: defaultValues?.unit || chartSetting.units[0].value,
        min: defaultValues?.min,
        max: defaultValues?.max
    }

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [form, initialValues])

    return (
        <div>
            <Form
                form={form}
                ref={ref}
                layout={'vertical'}
                initialValues={initialValues}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name={'unit'}
                            label={t('Unit')}
                        >
                            <Select>
                                {chartSetting.units?.map((unit, index) => {
                                    return <Option value={unit.value} key={index}>
                                        {unit.display}
                                    </Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={screens.lg ? 12 : 24}>
                        <Form.Item
                            name={'min'}
                            label={'Minimum'}
                        >
                            <NumberInput />
                        </Form.Item>
                    </Col>
                    <Col span={screens.lg ? 12 : 24}>
                        <Form.Item
                            name={'max'}
                            label={'Maximum'}
                        >
                            <NumberInput />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
})

export default ChartSettingsForm;

