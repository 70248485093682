// @ts-nocheck
import _ from "lodash";
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';


const destinationMap = {
    'de': 'de_DE',
    'en': 'en_EN',
    'de-DE': 'de_DE',
    'en-EN': 'en_EN',
    'en_EN': 'en_EN',
    'de_DE': 'de_DE'
};


export default () => {

    i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: 'en-EN',
            ns: ['translations'],
            defaultNS: 'translations',
            load: 'currentOnly',

            keySeparator: false, // we use content as keys
            debug: true,
            initImmediate: true,
            saveMissing: true,
            nonExplicitWhitelist: true,

            interpolation: {
                escapeValue: false, // not needed for react!!
                formatSeparator: ','
            },
            detection: {
                order: ['path', 'htmlTag'],
            },
            backend: {
                loadPath: (lngs, __namespaces) => {
                    const lang = destinationMap[lngs] || 'en_EN';
                    const isLocalhost = _.get(window, 'location.hostname') === "localhost" || _.get(window, 'location.hostname') === "127.0.0.1"

                    const devTranslatorUrl = "https://testing-vacuum-calculator.pfeiffer-vacuum.com";
                    // for chinese users we need to proxy the translator URL relative to the host url

                    if (isLocalhost) {
                        return `${devTranslatorUrl}/translations/vc/${lang}/web`
                    }
                    return `/translations/vc/${lang}/web`
                },
                addPath: '/graphql',
                ajax: function (url, options, callback, data) {

                    const read = !data;
                    if (read) {
                        return fetch(url, {
                            method: 'GET'
                        }).then(res => res.json())
                            .then((res) => {
                                callback(JSON.stringify(res), res);
                            }).catch((err) => {
                                console.error(err);
                                callback(null, {});
                            })
                        //return ajax(url, options, callback, data)
                    }
                },
            },
        })
        .then(() => {
            const momentLocal = _.first(i18n.language ? i18n.language.split("-") : []);
            moment.locale(momentLocal);
        });

    return i18n;
};

