import _ from "lodash";

const getPumpAmountTitle = (obj: any, countPath: string, titlePath: string) => {
    const count = _.get(obj, countPath, null)
    const title = _.get(obj, titlePath, null)

    if (count && title) {
        return `${count}x ${title}`
    } else {
        return ''
    }

}

export const getSolutionTitle = (solution: any, t: any, recommendation?: boolean) => {
    if (solution?.type === "pumpingStation" && solution?.components.length === 1 && solution?.components[0].backingPump) {
        const pumpingStation = solution?.components[0]

        // pumpingStations always consist of a backingPump + turboPump OR rootsPump
        const backingPumpTitle = getPumpAmountTitle(pumpingStation.backingPump, 'count', 'title');
        const variablePumpTitle = pumpingStation.turboPump?.title ?
                getPumpAmountTitle(pumpingStation.turboPump, 'count', 'title')
                : getPumpAmountTitle(pumpingStation.rootsPump, 'count', 'title')

        let title = `${getPumpAmountTitle(pumpingStation, 'count', 'title')}`

        // older calculations (pre 06.03.23) won't have subComponents (backingPump, turboPump, ...) attached to the solution
        if (variablePumpTitle && backingPumpTitle) {
            title = title + ` (${variablePumpTitle}, ${backingPumpTitle})`
        }

        if (recommendation) {
            return `${t('Our recommendation')}: ${title}`
        } else {
            return title
        }
    }


    if (solution?.components) {
        let solutionTitle = solution.components.map( (c:any) => getPumpAmountTitle(c, 'count', 'title')).join(', ');

        if (recommendation) {
            return `${t('Our recommendation')}: ${solutionTitle}`
        } else {
            return solutionTitle
        }
    }
}
