export const parseLocalizedFloat = (value: string | number): number => {
    let temp = value;

    if (typeof temp === "string") {
        temp = temp.replace(",", ".");
        temp = parseFloat(temp);
    }

    return temp;
}


