import React from "react";
import './index.css';
import {useTranslation} from "react-i18next";
import _ from 'lodash';

export interface Props {
    attributes: {
        label: string,
        reading: string | React.ReactNode,
        category?: any
    }[],
}

const CollapseAttributeList = (props: Props) => {

    const {t} = useTranslation();
    const {attributes} = props;

    const attributesByCategory = _.groupBy(attributes, 'category.key')

    return (
        <div  className={'info-content'}>
            {
                Object.entries(attributesByCategory).map(entry => {
                    const [category, attributes] = entry;

                    const attributesList = attributes.map((attribute, index)=>
                        <div key={index}>
                            <p className={'info-title'}>
                                {t(attribute.label)}
                            </p>
                            <p className={'info-content'}>
                                {typeof attribute.reading === "string" ? t(attribute.reading) : attribute.reading}
                            </p>
                        </div>
                    );

                    if (category !== 'undefined') {
                        return <>
                            <span
                                className={"font-bold"}
                                style={{borderBottom: '1px solid #E0E0E0'}}
                            >
                                {t(attributes[0].category?.title || '')}
                            </span>

                            {attributesList}
                        </>
                    }
                    return attributesList;
                })

            }
        </div>
    )
}

export default CollapseAttributeList
