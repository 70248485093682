import {gql} from "@apollo/client";

export const PUMP = gql`
    query Pump($id: ID!) {
        pump(pumpId: $id) {
            id
            name
        }
    }
`;
