export enum DataActionType {
    LOG_IN = "LOG_IN",
    SET_USER = "SET_USER",
    LOG_OUT = "LOG_OUT",
    SET_SUMMARY_TAB = "SET_SUMMARY_TAB",
    CLEAR_SUMMARY_TABS = "CLEAR_SUMMARY_TABS"
}

export interface DataAction {
    type: DataActionType,
    payload: any
}

export interface DataState {
    loggedIn: boolean,
    user: {
        name?: string,
        registrationStatus?: "OPEN" | "LEAD" | "REJECTED" | "APPROVED"
    },
    openedSummaryTabs: string[]
}

const initialState = {
    loggedIn: false,
    user: {},
    openedSummaryTabs: []
};

export default function (state: DataState = initialState, action: DataAction) {
    switch (action.type) {

        case DataActionType.LOG_IN: {
            return {
                ...state,
                loggedIn: true
            };
        }

        case DataActionType.LOG_OUT: {
            return {
                ...state,
                loggedIn: false,
                user: {}
            };
        }

        case DataActionType.SET_USER: {
            return {
                ...state,
                user: action.payload
            };
        }

        case DataActionType.SET_SUMMARY_TAB: {
            if (state.openedSummaryTabs.includes(action.payload)) {
                const newTabs = state.openedSummaryTabs.filter(x => x !== action.payload)
                return {
                    ...state,
                    openedSummaryTabs: newTabs
                };
            }
            return {
                ...state,
                openedSummaryTabs: [...state.openedSummaryTabs, action.payload]
            };
        }

        case DataActionType.CLEAR_SUMMARY_TABS: {

            return {
                ...state,
                openedSummaryTabs: []
            };
        }

        default:
            return state;
    }
}
