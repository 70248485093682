import * as React from "react"

interface Props {
    highlight?: "pump" | "chamber" | "pipe";
    props?: any
}

export default function ChamberPumpSvg ({highlight, props}: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 231.23 672"
            {...props}
            width="100%"
        >
            <path
                className="st0"
                fill={highlight === 'chamber' ? "#dd1541" : "#292929"}
                d="M224 102V0H56v102h73v20h-9v2h40v-2h-9v-20h73zm-75 20h-18v-22H58V2h164v98h-73v22z"
                id="Chamber"
            />

            <path
                className="st0"
                fill={highlight === 'pipe' ? "#dd1541" : "#292929"}
                d="M210 142v-2h-40v2h9v98h-9v2h40v-2h-9v-98h9zm-11 98h-18v-98h18v98z"
                id="Pipe"
            />

            <g id="Pump" fill={highlight === 'pump' ? "#dd1541" : "#292929"}>
                <path
                    className="st0"
                    d="M96 206c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm0-30c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14-6.3-14-14-14z"
                />
                <path
                    className="st0"
                    d="M96 202c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12zm0-22c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z"
                />
                <path
                    className="st0"
                    d="M136 190c0-18.3-12.3-33.7-29-38.5V142h9v-2H76v2h9v9.5c-16.7 4.8-29 20.2-29 38.5s12.3 33.7 29 38.5V240h-9v2h40v-2h-9v-11.5c16.7-4.8 29-20.2 29-38.5zm-49-48h18v9c-2.9-.7-5.9-1-9-1-3.1 0-6.1.4-9 1v-9zm9 10c17 0 31.4 11.2 36.2 26.5l-28 48.6c-2.6.6-5.4.9-8.2.9-2.7 0-5.3-.3-7.9-.8l-28.3-48.9C64.8 163 79.1 152 96 152zm-38 38c0-3.1.4-6.2 1.1-9.1l26.3 45.6C69.6 221.9 58 207.3 58 190zm47 39v11H87v-11c2.9.7 5.9 1 9 1 3.1 0 6.1-.4 9-1zm1.9-2.6 26.1-45.1c.7 2.8 1 5.7 1 8.7 0 17.2-11.4 31.7-27.1 36.4zM180 309c0-18.3-12.4-33.8-29.2-38.5.1 0 .2 0 .2.1V260h9v-2h-40v2h9v10.5c.1 0 .2 0 .2-.1-16.8 4.7-29.2 20.2-29.2 38.5s12.4 33.8 29.2 38.5c-.1 0-.2 0-.2-.1V358h-9v2h40v-2h-9v-10.5c-.1 0-.2 0-.2.1 16.8-4.8 29.2-20.3 29.2-38.6zm-49-39v-10h18v10h.2c-2.9-.7-6-1.1-9.2-1.1-3.2 0-6.2.4-9.2 1.1.1.1.1 0 .2 0zm-29 39c0-3.1.4-6.2 1.1-9.1l26.3 45.6c-15.8-4.6-27.4-19.2-27.4-36.5zm29 49v-10h-.2c2.9.7 6 1.1 9.2 1.1 3.2 0 6.2-.4 9.2-1.1h-.2v10h-18zm17.2-11.9c-2.6.6-5.4.9-8.2.9-2.7 0-5.3-.3-7.9-.8l-28.3-48.9c5-15.2 19.3-26.2 36.1-26.2 17 0 31.4 11.2 36.2 26.6l-27.9 48.4zm2.7-.7 26-45.1c.7 2.8 1 5.7 1 8.7.1 17.2-11.3 31.7-27 36.4z"
                />
                <path
                    className="st0"
                    d="M141 322.9V295c4 .3 7.5 2.2 9.8 5.1l1.4-1.4c-2.9-3.5-7.3-5.7-12.3-5.7-4.9 0-9.4 2.3-12.3 5.8l1.4 1.4c2.4-2.9 5.9-4.9 9.9-5.2v27.9c-4-.3-7.5-2.2-9.8-5.1l-1.4 1.4c2.9 3.5 7.3 5.7 12.3 5.7 4.8 0 9.2-2.2 12.1-5.6l-1.4-1.4c-2.4 2.9-5.8 4.8-9.7 5zM180 427c0-18.3-12.4-33.8-29.2-38.5.1 0 .2 0 .2.1V378h9v-2h-40v2h9v10.5c.1 0 .2 0 .2-.1-16.8 4.7-29.2 20.2-29.2 38.5s12.4 33.8 29.2 38.5c-.1 0-.2 0-.2-.1V476h-9v2h40v-2h-9v-10.5c-.1 0-.2 0-.2.1 16.8-4.8 29.2-20.3 29.2-38.6zm-49-39v-10h18v10h.2c-2.9-.7-6-1.1-9.2-1.1-3.2 0-6.2.4-9.2 1.1.1.1.1 0 .2 0zm9 1c17 0 31.4 11.2 36.2 26.6l-28 48.5c-2.6.6-5.4.9-8.2.9-2.7 0-5.3-.3-7.9-.8l-28.3-48.9c5-15.3 19.4-26.3 36.2-26.3zm-38 38c0-3.1.4-6.2 1.1-9.1l26.3 45.6c-15.8-4.6-27.4-19.2-27.4-36.5zm47 39v10h-18v-10h-.2c2.9.7 6 1.1 9.2 1.1 3.2 0 6.2-.4 9.2-1.1-.1-.1-.1 0-.2 0zm1.9-2.6 26-45.1c.7 2.8 1 5.7 1 8.7.1 17.2-11.3 31.7-27 36.4z"
                />
            </g>
        </svg>
    )
}
