// @ts-nocheck
export interface DesorptionSliderConfig {
    min: number,
    max: number,
    minExponential: number,
    maxExponential: number,
    defaultMark: number,
    exponentMarks: {[key: number]: string}
}


export const generateSliderConfig = (unit: "mbar_l_s_cm2" | "pa_m3_s_m2" | "torr_l_s_cm2" | "mbar_l_s" | "pa_m3_s"): DesorptionSliderConfig => {

    // Basic config (Desorption rate: mbar_l_s_cm2)
    let config = {
        min: -12,
        max: -4,
        minExponential: 1e-12,
        maxExponential: 1e-4,
        defaultMark: 3e-7,
        exponentMarks: {
            [-12]: '1E-12',
            [-11]: '1E-11',
            [-10]: '1E-10',
            [-9]: '1E-9',
            [-8]: '1E-8',
            [-7]: '1E-7',
            [-6]: '1E-6',
            [-5]: '1E-5',
            [-4]: '1E-4',
        }
    }

    // Desorption rate
    if (unit === "pa_m3_s_m2") {
        config = {
            min: -9,
            max: -1,
            minExponential: 1e-9,
            maxExponential: 1e-1,
            defaultMark: 3e-4,
            exponentMarks: {
                [-9]: '1E-9',
                [-8]: '1E-8',
                [-7]: '1E-7',
                [-6]: '1E-6',
                [-5]: '1E-5',
                [-4]: '1E-4',
                [-3]: '1E-3',
                [-2]: '1E-2',
                [-1]: '1E-1',
            }
        }
    }

    // Desorption rate
    if (unit === "torr_l_s_cm2") {
        config = {
            ...config,
            // conversion for "torr_l_s_cm2" is 0.750062
            defaultMark: 2.25e-7,
        }
    }

    // Leak rate
    if (unit === "mbar_l_s") {
        config = {
            ...config,
            defaultMark: 1e-7,
        }
    }

    // Leak rate
    if (unit === "pa_m3_s") {
        config = {
            min: -11,
            max: -3,
            minExponential: 1e-11,
            maxExponential: 1e-3,
            defaultMark: 1e-6,
            exponentMarks: {
                [-11]: '1E-11',
                [-10]: '1E-10',
                [-9]: '1E-9',
                [-8]: '1E-8',
                [-7]: '1E-7',
                [-6]: '1E-6',
                [-5]: '1E-5',
                [-4]: '1E-4',
                [-3]: '1E-3',
            }
        }
    }

    return config
}
