import React from "react";
import {Redirect, useParams} from "react-router-dom";
import PumpFinderChamberMasterView from "./master";
import useChamberStep from "../../../hooks/useChamberStep";
import {stepConfig} from "../../../config/pumpFinderChamberEvacuationStepConfig";

interface Props {
    calculationMode: string
    calculationType: string
    onMobileContent: (content: string) => void
}

export default function PumpFinderChamberStepRoute({calculationMode, calculationType, onMobileContent}: Props) {

    const {stepId, calculationId} = useParams<any>();
    const step = useChamberStep(stepConfig, stepId);
    const first = useChamberStep(stepConfig);

    if (!step){
        return <Redirect to={`/${calculationMode}/${calculationType}/:calculationId/${first.id}`}/>
    }

    return <PumpFinderChamberMasterView
        stepId={stepId}
        calculationId={calculationId}
        calculationMode={calculationMode}
        calculationType={calculationType}
        onMobileContent={onMobileContent}
    />
}
