import * as React from "react"

interface Props {
    pumpSystemType?: "primaryPipe" | "turboPipe" | "turboPump" | "rootsPump" | "backingPump" | "chamber",
    active: boolean
    props?: any
    isChamberStep?: boolean
}

export default function PumpPartsSvg ({pumpSystemType, active, isChamberStep, props}: Props) {

    if (pumpSystemType === "chamber") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 124" {...props}>
                <path
                    d="M168 102V0H0v102h73v20h-9v2h40v-2h-9v-20h73Zm-93 20v-22H2V2h164v98H93v22H75Z"
                    style={{
                        fill: isChamberStep ? "#dd1541" : active ? "#292929" : "#e0e0e0",
                    }}
                />
            </svg>
        )
    }

    if (pumpSystemType === "turboPipe" || pumpSystemType === "primaryPipe") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 102" {...props}>
                <path
                    d="M40 2V0H0v2h9v98H0v2h40v-2h-9V2h9Zm-11 98H11V2h18v98Z"
                    style={{
                        fill: active ? "#292929" : "#e0e0e0",
                    }}
                />
            </svg>
        )
    }

    if (pumpSystemType === "turboPump") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 80 102"
                {...props}
            >
                <path
                    d="M40 66c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16Zm0-30c-7.72 0-14 6.28-14 14s6.28 14 14 14 14-6.28 14-14-6.28-14-14-14Z"
                    style={{
                        fill: active ? "#292929" : "#e0e0e0",
                    }}
                />
                <path
                    d="M40 62c-6.62 0-12-5.38-12-12s5.38-12 12-12 12 5.38 12 12-5.38 12-12 12Zm0-22c-5.51 0-10 4.49-10 10s4.49 10 10 10 10-4.49 10-10-4.49-10-10-10Z"
                    style={{
                        fill: active ? "#292929" : "#e0e0e0",
                    }}
                />
                <path
                    d="M80 50c0-18.27-12.26-33.68-29-38.46V2h9V0H20v2h9v9.54C12.26 16.32 0 31.73 0 50s12.26 33.68 29 38.46V100h-9v2h40v-2h-9V88.46C67.74 83.68 80 68.28 80 50ZM50.92 86.4l26.06-45.13C77.64 44.08 78 47 78 50c0 17.19-11.42 31.7-27.08 36.4ZM31 2h18v9.03a39.794 39.794 0 0 0-18 0V2Zm9 10c17 0 31.38 11.16 36.24 26.55L48.21 87.1c-2.64.58-5.39.9-8.21.9s-5.34-.29-7.88-.82L3.87 38.23C8.82 23.01 23.12 12 40 12ZM2 50c0-3.13.39-6.18 1.1-9.09l26.32 45.58C13.58 81.91 2 67.31 2 50Zm47 50H31V88.97a39.794 39.794 0 0 0 18 0V100Z"
                    style={{
                        fill: active ? "#292929" : "#e0e0e0",
                    }}
                />
            </svg>
        )
    }

    if (pumpSystemType === "rootsPump") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 80 102"
                {...props}
            >
                <path
                    d="M80 51c0-18.32-12.39-33.79-29.22-38.51l.22.06V2h9V0H20v2h9v10.54l.22-.06C12.39 17.21 0 32.68 0 51s12.39 33.8 29.23 38.52c-.08-.02-.16-.04-.23-.06V100h-9v2h40v-2h-9V89.46l-.24.06C67.6 84.81 80 69.33 80 51ZM31 12.03V2h18v10.03c.05.01.11.03.16.04C46.22 11.38 43.15 11 40 11s-6.21.38-9.16 1.07c.05-.01.1-.03.16-.04Zm9 .97c16.97 0 31.37 11.18 36.24 26.56L48.22 88.1c-2.65.59-5.39.9-8.21.9s-5.34-.29-7.88-.83L3.87 39.24C8.83 24.03 23.15 13 40 13ZM2 51c0-3.13.39-6.17 1.11-9.08l26.31 45.57C13.6 82.89 2 68.28 2 51Zm47 38.97V100H31V89.97c-.06-.01-.11-.03-.17-.04C33.78 90.62 36.85 91 40 91s6.23-.38 9.17-1.07c-.06.01-.12.03-.17.04Zm1.93-2.58 26.05-45.12C77.64 45.07 78 47.99 78 51c0 17.16-11.43 31.68-27.07 36.39Z"
                    style={{
                        fill: active ? "#292929" : "#e0e0e0",
                    }}
                />
                <path
                    d="M41 64.94V37.05c3.96.28 7.47 2.2 9.84 5.1l1.42-1.42c-2.94-3.5-7.34-5.74-12.26-5.74s-9.37 2.25-12.3 5.79l1.42 1.42c2.37-2.93 5.9-4.87 9.88-5.15v27.88c-3.96-.28-7.47-2.2-9.84-5.1l-1.42 1.42c2.94 3.5 7.34 5.73 12.26 5.73s9.19-2.17 12.13-5.59l-1.41-1.41a13.998 13.998 0 0 1-9.71 4.95ZM40 37ZM24 51z"
                    style={{
                        fill: active ? "#292929" : "#e0e0e0",
                    }}
                />
            </svg>
        )
    }

    if (pumpSystemType === "backingPump") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 102" {...props}>
                <path
                    d="M80 51c0-18.32-12.39-33.8-29.23-38.51.07.02.15.04.23.06V2h9V0H20v2h9v10.54l.22-.06C12.39 17.2 0 32.68 0 51s12.39 33.8 29.23 38.51l-.23-.06v10.54h-9v2h40v-2h-9V89.45l-.23.06C67.61 84.79 80 69.32 80 50.99ZM31 12.03V2h18v10.03c.06.01.11.03.16.04C46.21 11.38 43.15 11 40 11s-6.22.38-9.16 1.07c.05-.01.11-.03.16-.04Zm9 .97c16.97 0 31.37 11.18 36.24 26.56L48.22 88.1c-2.65.59-5.39.9-8.21.9s-5.34-.29-7.88-.83L3.87 39.24C8.83 24.03 23.15 13 40 13ZM2 51c0-3.13.39-6.17 1.11-9.08l26.31 45.57C13.6 82.89 2 68.28 2 51Zm47 38.97V100H31V89.97c-.06-.01-.11-.03-.17-.04C33.78 90.62 36.84 91 40 91s6.22-.38 9.17-1.07c-.06.01-.11.03-.17.04Zm1.93-2.58 26.05-45.12C77.64 45.07 78 47.99 78 51c0 17.16-11.43 31.68-27.07 36.39Z"
                    style={{
                        fill: active ? "#292929" : "#e0e0e0",
                    }}
                />
            </svg>
        )
    }

    return null;
}
