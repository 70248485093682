import React from "react";
import {useParams, useHistory} from "react-router-dom";
import {Button} from "@dreebit/pv-components";
import _ from "lodash";
import {getPreviousStep} from "../../hooks/getPreviousStep";
import {useTranslation} from "react-i18next";
import getCurrentStepIndex from "../../hooks/getCurrentStepIndex";
import {Step} from "../../types";

interface Props {
    calculationMode: string;
    calculationType: string;
    calculationId: string;
    customNextText?: string;
    customPreviousText?: string;
    nextButtonDisabled?: boolean,
    stepConfig: Step[],
    onNextStep?: () => void
}

export default function PreviousNextStepButtons({calculationMode, calculationType, calculationId, customNextText, customPreviousText, nextButtonDisabled, stepConfig, onNextStep}: Props) {

    const {stepId} = useParams<any>();
    const history = useHistory();
    const {t} = useTranslation();
    const currentStepIndex = getCurrentStepIndex(stepConfig, stepId)

    const previousStep = () => {
        if (currentStepIndex === 0) {
            history.push('/');
        } else {
            history.push(`/${calculationMode}/${calculationType}/${calculationId}/${_.get(getPreviousStep(stepConfig, stepId), 'id', '')}`);
        }
    }

    const isLastStep = currentStepIndex === stepConfig.length - 2;

    return (
        <div className={'full-width flex-row flex-space-between'}>
            <Button
                type={'ghost'}
                htmlType={"button"}
                onClick={() => previousStep()}
                icon={"arrow_back"}
                iconPosition={"left"}
            >
                {customPreviousText ? customPreviousText : t('Back')}
            </Button>
            <Button
                data-testid={`next-step-button`}
                type={'primary'}
                disabled={nextButtonDisabled}
                onClick={onNextStep}
                icon={"arrow_forward"}
            >
                {isLastStep ? t('Calculate') : customNextText ? customNextText : t('Next') }
            </Button>
        </div>
    )
}
