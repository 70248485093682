import React from 'react';
import {Button} from "@dreebit/pv-components";
import {Form, Input} from "antd";
import {useTranslation} from "react-i18next";
import MaterialIcon from "../MaterialIcon";

interface Props {
    onSave: (values: any) => void,
    onCancel?: () => void
}

const SaveCalculationForm = ({onSave, onCancel}: Props) => {

    const {t} = useTranslation();

    return <>
        <p>
            {t("calculation_name_subtitle")}
        </p>
        <Form
            onFinish={onSave}
            layout={'vertical'}
        >
            <Form.Item
                name={'title'}
                label={t('Name')}
                rules={[{required: true}]}
            >
                <Input/>
            </Form.Item>
            <div className={'flex-row flex-space-between'}>
                <Button
                    className={'pv-secondary-button flex'}
                    type={"tertiary"}
                    htmlType={'button'}
                    onClick={onCancel}
                    icon={"close"}
                >
                    {t('Cancel')}
                </Button>
                <Form.Item style={{marginTop: "10px"}}>
                    <Button
                        className={'flex'}
                        type="primary"
                        htmlType={'submit'}
                        icon={"save"}
                    >
                        {t('Save')}
                    </Button>
                </Form.Item>
            </div>
        </Form>
    </>
}

export default SaveCalculationForm;
