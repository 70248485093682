import {Step} from "../types";

export const formatPumpSelectionValues = (values: any, step: Step) => {
    let newValues = {};

    for (const [key, value] of Object.entries(values)) {
        // @ts-ignore
        if ((typeof value?.value === "string" && value?.value === '') || (typeof value?.value !== "string" && isNaN(value?.value))) {
            // @ts-ignore
            newValues[key] = {value: null}
        } else {
            // @ts-ignore
            newValues[key] = value
        }

        // get a set with all stepIds that are filled out
        const filledOutStepIds = new Set()
        for (const [key, value] of Object.entries(newValues)) {
            filledOutStepIds.add(key);
        }
        // get the missing stepIds that are not filled out
        const notFilledOutStepIds = step.attributes.filter(obj => !filledOutStepIds.has(obj.id)).map(x => x.id);

        // set those missing stepIds to {value: null}, so they get reset when the forms is sent
        for(let i=0; i<notFilledOutStepIds.length; i++)  {
            // @ts-ignore
            newValues[notFilledOutStepIds[i]] = {value: null}
        }
    }

    return newValues;
}
