import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import useChamberStep from "../../../hooks/useChamberStep";
import getCurrentStepIndex from "../../../hooks/getCurrentStepIndex"
import getInitialFormValues from "../../../utils/getInitialFormValues";
import {useMutation, useQuery} from "@apollo/client";
import {CALCULATION} from "../../../graphql/queries/calculation";
import {InfoBox, Loader, LoadingSpinner} from "@dreebit/pv-components";
import {Row, Col, Grid, Modal, Form} from "antd";
import StepProgressBar from "../../../components/StepProgressBar";
import PreviousNextStepButtons from "../../../components/PreviousNextStepButtons";
import ChamberPumpSelectionContent from "../../../components/ChamberPumpSelectionContent";
import FinishedStepsOverview from "../../../components/FinishedStepsOverview";
import {stepConfig} from "../../../config/fixedPumpSystemChamberEvacuationStepConfig";
import getFinishedSteps from "../../../utils/getFinishedSteps";
import ChamberVolumeContent from "../../../components/ChamberVolumeContent";
import formatFormData from "../../../utils/formatFormData";
import {getFieldValidations} from "../../../utils/getFieldValidations";
import {UPDATE_CALCULATION} from "../../../graphql/mutations/updateCalculation";
import ChamberDesorptionRateContent from "../../../components/ChamberDesorptionRateContent";
import ChamberLeakRateContent from "../../../components/ChamberLeakRateContent";
import {CREATE_CALCULATION_RESULT} from "../../../graphql/mutations/createCalculationResult";
import CustomPumpSvg from "../../../components/Svg/CustomPumpSvg";
import {toast} from "react-toastify";
import ToastContent from "../../../components/ToastContent";
import {useSelector} from "react-redux";
import {isLoggedIn} from "../../../redux/selectors/user";
import SavedCalculationsList from "../../../components/SavedCalculationsList";
import NoCalculationError from "../../../components/NoCalculationError";
import {IDesorptionSchemaData} from "../../../utils/convertDesorptionRateForm";

interface Props {
    stepId: string
    calculationMode: string
    calculationType: string
    calculationId: string
    onMobileContent: (content: string) => void
}

const Components: {[key: string]: any} = {
    "ChamberPumpSelectionContent": ChamberPumpSelectionContent,
    "ChamberVolumeContent": ChamberVolumeContent,
    "ChamberDesorptionRateContent": ChamberDesorptionRateContent,
    "ChamberLeakRateContent": ChamberLeakRateContent,
}

export default function FixedPumpSystemChamberMasterView({calculationMode, calculationType, stepId, calculationId, onMobileContent}: Props) {

    const {t} = useTranslation();
    const history = useHistory();
    const loggedIn = useSelector(isLoggedIn);
    const [calculationsVisible, setCalculationsVisible] = useState(false);
    const [fieldValidations, setFieldValidations] = useState([{}]);
    const [isCalculating, setIsCalculating] = useState(false);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const step = useChamberStep(stepConfig, stepId);
    const currentStepIndex = getCurrentStepIndex(stepConfig, stepId);
    const isFirstStep = currentStepIndex === 0
    const isPumpStep = (stepId === "pumpSystem")

    const {loading, error, data} = useQuery(CALCULATION, {
        variables: {
            id: calculationId
        }
    });

    const [updateCalculation] = useMutation(UPDATE_CALCULATION);
    const [createCalculationResult] = useMutation(CREATE_CALCULATION_RESULT)

    const calculationValues = _.get(data, 'calculation.values', [])
    const calculationCollectionId = _.get(data, 'calculation.calculationCollection.id')
    const initialValues = getInitialFormValues(step, calculationValues)
    const finishedSteps = getFinishedSteps(calculationValues, stepConfig)

    const isCalculationValid = _.get(data, 'calculation.valid')
    const isLastStep = currentStepIndex === stepConfig.length - 2;

    const toggleCalculationVisibility = () => {
        setCalculationsVisible(!calculationsVisible);
    };

    const onChangeStep = (stepIndex: any) => {
        if (stepIndex === stepConfig.length - 1) {
            _createResult()
        } else {
            history.push(`/${calculationMode}/${calculationType}/${calculationId}/${stepConfig[stepIndex].id}`);
        }
    };

    const _createResult = () => {
        setIsCalculating(true);
        createCalculationResult({
            variables: {
                id: calculationId,
            }
        }).then((res) => {
            setIsCalculating(false);
            if (calculationCollectionId) {
                history.push(`/${calculationMode}/${calculationType}/result/${calculationCollectionId}`)
            } else {
                toast.error(<ToastContent
                    status={"error"}
                    headline={"Collection error"}
                    text={"No calculation collection found."}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            }
        }).catch((err) => {
            setIsCalculating(false);
            const errorObject=JSON.parse(JSON.stringify(err));
            let errorMessage = '';
            if (errorObject.graphQLErrors?.length > 0) {
                errorMessage = _.get(errorObject, 'graphQLErrors[0].message', errorObject.message);
            } else if (_.has(errorObject, 'networkError')) {
                errorMessage =
                    _.get(errorObject, 'networkError.statusCode') + ' ' +
                    _.get(errorObject, 'networkError.name') + ' - ' +
                    _.get(errorObject, 'message', errorObject.message);
            } else {
                errorMessage = _.get(errorObject, 'message', errorObject.message);
            }
            toast.error(<ToastContent
                status={"error"}
                headline={t('Calculation error')}
                text={`${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    }

    let content = null;

    if (typeof Components[step.component] !== "undefined") {
        content = React.createElement(Components[step.component], {
            form,
            stepId,
            initialValues,
            fieldValidations,
            calculationId,
            calculationMode,
            calculationType,
            calculationData: data,
            onMobileContent: onMobileContent,
            onSubmit: (values: any, skipFormatting?: boolean) => {
                // we need to differentiate between submitTypes because the behaviour after submitting the form is different (change to next step or create result)
                // the submitType value is a hidden form element which can be set by form.setFieldsValue
                const submitType = _.get(values, 'submitType', null);
                delete values["submitType"];

                // validate all surfaces for size and desorption rate
                if (stepId === "chamberStepDesorptionRate"
                    && values.desorption
                    && (values.desorption.some((x: IDesorptionSchemaData) => !x?.desorptionRate?.value) || values.desorption.some((x: IDesorptionSchemaData) => !x?.desorptionSurfaceSize?.value))
                ) {
                    return toast.error(<ToastContent
                        status={"error"}
                        text={t("Please fill in size and desorption rate for all selected surfaces")}
                    />, {
                        autoClose: 7000,
                        pauseOnHover: true
                    })
                }
                setFieldValidations([{}]);
                updateCalculation({
                    variables: {
                        id: calculationId,
                        values: skipFormatting ? values : formatFormData(values, step)
                    },
                    refetchQueries: [
                        {
                            query: CALCULATION,
                            variables: {
                                id: calculationId
                            }
                        }
                    ],
                    awaitRefetchQueries: true
                }).then((res) => {
                    if (submitType === "nextStep") {
                        onChangeStep(currentStepIndex + 1)
                    } else if (submitType === "createResult") {
                        _createResult()
                    }
                }).catch((err) => {
                    const errorObject=JSON.parse(JSON.stringify(err));
                    const errors = _.get(errorObject, 'networkError.result.errors', [])

                    if (errors.length === 1) {
                        const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

                        toast.error(<ToastContent
                            status={"error"}
                            headline={t('Error updating the calculation')}
                            text={`${errorMessage}`}
                        />, {
                            autoClose: 7000,
                            pauseOnHover: true
                        })

                        // check for Form-Validation-Errors (checked on BE)
                        if (errors[0].fields) {
                            const fieldValidations = getFieldValidations(errors);
                            setFieldValidations(fieldValidations);
                            fieldValidations.forEach(x => {
                                toast.error(<ToastContent
                                    status={"error"}
                                    text={x.message}
                                />, {
                                    autoClose: 7000,
                                    pauseOnHover: true
                                })
                            })
                        }
                    }
                })
            }
        });
    }

    if (loading || isCalculating) {
        return <LoadingSpinner />
    }

    if (error) {
        return <div className={'pt-64'}>
            <NoCalculationError
                onNewCalculation={() => history.push('/')}
                onReload={() => history.go(0)}
            />
        </div>
    }

    return (
        <>
            <div className={'pt-40'}>
                <div>
                    <h1 className={'font-bold'}>{t('Vacuum Calculator')}</h1>
                    <h2>{t('Calculate pumping curve for a vacuum system')}</h2>
                </div>
                <div className={screens.lg ? 'pv-40' : 'pv-8'}>
                    <StepProgressBar
                        stepConfig={stepConfig}
                        index={currentStepIndex}
                        onChangeStep={onChangeStep}
                        finishedSteps={finishedSteps}
                    />
                </div>
                <Row justify={'space-between'} gutter={screens.lg ? 40 : 0}>
                    {
                        !isPumpStep ?
                            <Col xs={0} md={5}>
                                <CustomPumpSvg calculationValues={calculationValues} isChamberStep={stepId === "chamberStepChamberVolume"}/>
                            </Col>
                        :null
                    }
                    <Col
                        xs={24}
                        md={isPumpStep ? 24 : 12}
                        style={{padding: isPumpStep && screens.lg ? `0 ${screens.lg ? '140px' : '50px'}` : ""}}
                    >
                        {content}
                        <div className={'pt-40'}>
                            <PreviousNextStepButtons
                                stepConfig={stepConfig}
                                calculationMode={calculationMode}
                                calculationType={calculationType}
                                calculationId={calculationId}
                                customNextText={finishedSteps.includes(stepId) ? t('Save') : undefined}
                                onNextStep={() => {
                                    form.setFieldsValue({
                                        submitType: "nextStep"
                                    });
                                    form.submit();
                                }}
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={7}>
                        <div className={screens.lg ? 'ml-32' : 'mt-24'}>
                            {
                                isFirstStep && loggedIn && screens.lg &&
                                <div className={"pb-16"}>
                                    <InfoBox
                                        heading={t('Open saved calculations')}
                                        infoBoxText={t('saved_calculations_subtitle')}
                                        infoBoxButtonLabel={t('Open calculations')}
                                        onClick={toggleCalculationVisibility}
                                        topRightIcon={"description"}
                                        buttonIcon={"open_in_new"}
                                    />
                                </div>
                            }
                            {
                                !isPumpStep &&
                                <FinishedStepsOverview
                                    stepConfig={stepConfig}
                                    finishedSteps={finishedSteps}
                                    calculationValues={calculationValues}
                                    calculationId={calculationId}
                                    calculationMode={calculationMode}
                                    calculationType={calculationType}
                                    resultButton={
                                        isCalculationValid && !isLastStep ?
                                            {
                                                show: true,
                                                onClick: () => {
                                                    form.setFieldsValue({
                                                        submitType: "createResult"
                                                    });
                                                    form.submit();
                                                }
                                            }
                                            : undefined
                                    }
                                />
                            }
                            {
                                isFirstStep && loggedIn && !screens.lg ?
                                    <InfoBox
                                        heading={t('Open saved calculations')}
                                        infoBoxText={t('saved_calculations_subtitle')}
                                        infoBoxButtonLabel={t('Open calculations')}
                                        onClick={() => onMobileContent("savedCalculations")}
                                        topRightIcon={"description"}
                                        buttonIcon={"open_in_new"}
                                    />
                                    : null
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                title={t('Open saved calculations')}
                visible={calculationsVisible}
                onCancel={toggleCalculationVisibility}
                footer={null}
                width={700}
            >
                <SavedCalculationsList
                    calculationMode={calculationMode}
                    calculationType={calculationType}
                    showCalculationType={false}
                />
            </Modal>
        </>
    )
}
