const formatLongTitles = (title: string, maxLength: number, useWordBoundary: boolean): string | undefined => {
    if (!title) { return undefined }
    if (title?.length <= maxLength) { return title; }
    const subString = title.slice(0, maxLength-1); // the original check
    return (useWordBoundary
        ? subString.slice(0, subString.lastIndexOf(" "))
        : subString) + "...";

}
export default formatLongTitles;
