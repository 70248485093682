import React from 'react';
import {Button, Result} from "antd";
import "./index.css";
import {useTranslation} from "react-i18next";

interface Props {
    onNewCalculation: () => void,
    onReload: () => void
}

const NoCalculationError = ({onNewCalculation, onReload}: Props) => {

    const {t} = useTranslation()

    return (
        <Result
            status="error"
            title={t("No calculation found")}
            subTitle={t("no_calculation_error_subtitle")}
            extra={[
                <Button key={"reload"} onClick={onReload}>
                    {t("Reload page")}
                </Button>,
                <Button key={"home"} onClick={onNewCalculation} type="primary">
                    {t("New calculation")}
                </Button>
            ]}
        />
    );
}

export default NoCalculationError;
