import {gql} from "@apollo/client";

export const SEND_CONTACT_FORM = gql`
    mutation SendContactForm($id: ID, $input: ContactFormInput!) {
        sendContactForm(calculationCollectionId: $id, input: $input) {
            success
            message
        }
    }
`;
