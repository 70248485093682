import React from 'react';
import {Button} from "@dreebit/pv-components";
import {Form, Col, Row, Select, InputNumber, Grid} from "antd";
import PumpPartsSvg from "../Svg/PumpPartsSvg";
import {useQuery} from "@apollo/client";
import {PUMP_LIST} from "../../graphql/queries/pumpList";
import _ from "lodash";
import NumberInput from "../NumberInput";
import MaterialIcon from "../MaterialIcon";
import {useTranslation} from "react-i18next";
import {StepAttribute} from "../../types";

export interface Props {
    className?: string,
    form?: any
    attributes?: StepAttribute[]
    pumpSystemType?: "primaryPipe" | "turboPipe" | "turboPump" | "rootsPump" | "backingPump" | "chamber",
    pumpQueryTypes?: ("turboPump" | "rootsPump" | "primaryPumpDry" | "primaryPumpOil" | "rootsPumpingStation" | "turboPumpingStation" | "multiStageRoots")[]
    label?: string,
    status: "active" | "addable" | "unaddable"
    changeStatus: (status: string) => void,
    changePump?: (pumpType: string, pumpId: any) => void,
    changePumpCount?: (pumpType: string, count: number) => void,
    onDeletePump?: (pumpType: string) => void,
    frequency?: "f50hz" | "f60hz"
}

const PumpSelectionGroup = (props: Props) => {

    const {className, form, attributes, pumpSystemType, pumpQueryTypes, label, status, changeStatus, changePump, changePumpCount, onDeletePump, frequency} = props;

    const {Option} = Select;
    const {t} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const {data, loading, error} = useQuery(PUMP_LIST, {
        variables: {
            pumpType: pumpQueryTypes,
            frequency: frequency
        },
        skip: !pumpQueryTypes || pumpQueryTypes.length === 0 || !frequency
    })

    const pumpList = _.get(data, 'pumpList.pumps')

    const _returnAttributeInput = (attribute: StepAttribute) => {

        if (attribute.type === "number" && attribute.id.includes("Pump") && pumpSystemType) {
            return <InputNumber
                max={999}
                min={attribute.minCount ? attribute.minCount : 0}
                onChange={(value) => {
                    if (value === 0 && onDeletePump && !attribute.minCount) {
                        onDeletePump(pumpSystemType);
                        if (["turboPump", "rootsPump"].includes(pumpSystemType)) {
                            form.setFieldsValue({
                                [pumpSystemType]: null,
                                [pumpSystemType + 'Count']: null
                            })
                        }
                        changeStatus("addable")
                    }
                    if(changePumpCount && value) {
                        changePumpCount(pumpSystemType, value)
                    }
                }}
            />
        }

        if (attribute.type === "number" && !attribute.id.includes("Pump")) {
            return <NumberInput
                addonAfter={ attribute.units?.length ?
                    <Form.Item name={`${attribute.id}Unit`} noStyle>
                        <Select tabIndex={-1}>
                            {attribute?.units?.map((unit, index) => {
                                return <Option
                                    value={unit.value}
                                    key={index}
                                >
                                    {unit.display}
                                </Option>
                            })}
                        </Select>
                    </Form.Item>
                : null}
            />
        }

        //TODO: durch eigene PumpSelect Komponente ersetzen
        if (attribute.pumpQueryTypes && !loading && frequency) {
            return <Select
                placeholder={t('Please choose')}
                style={{width: "100%"}}
                allowClear
                optionFilterProp="children"
                showSearch
                onChange={(value) => {
                    if (form.getFieldValue(attribute.id + 'Count') < 1) {
                        form.setFieldsValue({
                            [attribute.id + 'Count']: 1
                        })
                    }

                    if(changePump) {
                        changePump(attribute.id, value)
                    }
                }}
            >
                {pumpList.map((pump: any) => {
                    return <Option key={pump.id} value={pump.id}>{pump.name}</Option>
                })}
            </Select>
        }
    }

    const addButton = <Button
        type={"tertiary"}
        onClick={() => changeStatus("active")}
        icon={"add"}
    >
        {t('Add')}
    </Button>


    return (
        <Row gutter={10} align={"middle"} className={className}>
            {
                !(status === "unaddable" && !screens.md) ?
                    <Col xs={24} md={6}>
                        {
                            label && <div style={{
                                fontWeight: "bold",
                                color: status === "active" ? "#292929" : "#e0e0e0",
                                width: "100%",
                                textAlign: screens.lg ? "right" : "left",
                                marginBottom: screens.lg ? "0" : "8px"
                            }}>
                                {t(label)}
                            </div>
                        }
                    </Col>
                : null
            }

            <Col xs={0} md={5}>
                <PumpPartsSvg pumpSystemType={pumpSystemType} active={status === "active"} props={{height: "100px", width: "100%"}} />
            </Col>

            <Col xs={22} md={12}>
                {
                    attributes && !(pumpSystemType === "chamber") ?
                        <Row gutter={10}>
                            {
                                status === "active" ?
                                    attributes.map((attribute) => {
                                        return <Col span={attribute.col} key={attribute.id}>
                                            <Form.Item
                                                name={attribute.id}
                                                label={t(attribute.label)}
                                                rules={[{ required: !attribute.optional }]}
                                            >
                                                {_returnAttributeInput(attribute)}
                                            </Form.Item>
                                        </Col>

                                    })

                                    :
                                        (status === "addable")
                                    ? addButton : null
                            }
                        </Row>
                    : null
                }

            </Col>

            <Col xs={2} md={1}>
                {
                    status === "active" && pumpSystemType && !["chamber", "backingPump"].includes(pumpSystemType) ||
                    pumpSystemType === "turboPipe" && status === "active" && pumpSystemType && !["chamber", "backingPump"].includes(pumpSystemType) ?
                        <MaterialIcon
                            name={"delete"}
                            onClick={() => {
                                if (["turboPump", "rootsPump"].includes(pumpSystemType)) {
                                    form.setFieldsValue({
                                        [pumpSystemType]: null,
                                        [pumpSystemType + 'Count']: null
                                    })
                                }

                                if (onDeletePump) {
                                    onDeletePump(pumpSystemType)
                                }

                                changeStatus("addable")
                            }}
                            style={{color: "#dd1541", cursor: "pointer"}}
                        />
                    : null
                }
            </Col>
        </Row>
    );
}


export default PumpSelectionGroup;
