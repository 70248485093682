import React from 'react';
import {Button} from "@dreebit/pv-components";
import {Form, Input} from "antd";
import {useTranslation} from "react-i18next";

interface Props {
    onCreateReport: (values: any) => void,
    onCancel?: () => void,
    initialValues?: any
}

const CreateReportForm = ({onCreateReport, onCancel, initialValues}: Props) => {

    const { TextArea } = Input;
    const {t} = useTranslation();

    return <Form
        onFinish={onCreateReport}
        layout={'vertical'}
        initialValues={initialValues}
    >
        <Form.Item
            name={'title'}
            label={t('Title')}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            name={'comment'}
            label={t('Comment')}
        >
            <TextArea autoSize={{ minRows: 5, maxRows: 7 }}/>
        </Form.Item>
        <div className={'flex-row flex-space-between flex-center'}>
            <Button
                type={"tertiary"}
                htmlType={"button"}
                onClick={onCancel}
                icon={"close"}
            >
                {t('Cancel')}
            </Button>
            <Button
                type="primary"
                htmlType={"submit"}
                icon={"download"}
            >
                {t('Create report')}
            </Button>
        </div>
    </Form>
}

export default CreateReportForm;
