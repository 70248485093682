import React from 'react';
import {ProductCard, ProductCardFilterWrapper} from "@dreebit/pv-components";
import {Grid} from "antd";
import "./index.css"
import {useTranslation} from "react-i18next";
import componentsImg from "../../assets/images/Components.png";
import gaugesImg from "../../assets/images/Gauges.png";
import valvesImg from "../../assets/images/Valves.png";

interface Product {
    key: string,
    link: string,
    title: string,
    filters: {[key: string]: string}[]
}

interface Props {
    products: Product[],
    className?: string
}

function openProductUrl (url: string) {
    // @ts-ignore
    window.open(url, '_blank').focus()
}

function formatFilter (filters: {[key: string]: string}[]) {
    if (filters.length) {
        return filters.map((x: any) => {
            return {
                label: x.name,
                value: x.value,
                highlighted: false
            }
        });
    }
    return [];
}

function getProductImg (key: string) {
    if (key === "COMPONENTS") {
        return componentsImg
    } else if (key === "GAUGES") {
        return gaugesImg
    } else if (key === "VACUUM_VALVES") {
        return valvesImg
    }

    return componentsImg;
}

const ProductLinks = ({products, className}: Props) => {

    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const {t} = useTranslation();

    return (
        <div className={className}>
            <h5>{t('More matching products')}</h5>
            <div className={"product-links-container"}>
                {products.map((product, index) => {
                    return <ProductCard
                        key={index}
                        img={{
                            alt: product.title,
                            src: getProductImg(product.key)
                        }}
                        productName={product.title}
                        productCategory= {product.title}
                        onClick={product.link ? () => openProductUrl(product.link) : undefined}
                        width={screens.lg ? '261px' : '100%'}
                    >
                        <ProductCardFilterWrapper
                            filters={formatFilter(product.filters)}
                        />
                    </ProductCard>
                })}
            </div>
        </div>
    );
}

export default ProductLinks;
