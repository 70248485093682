// @ts-nocheck
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {KeycloakService, LoadingSpinner} from "@dreebit/pv-components";
import {DataActionType} from "../redux/reducers";

interface Props {
    children?: any
}

const KeycloakProvider = ({children}: Props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const keycloak = KeycloakService.getInstance();

    const _localLogout = () => {
        dispatch({
            type: DataActionType.LOG_OUT,
        });
        window.sessionStorage.removeItem('token');
    }

    const _localLogin = () => {
        // set token to session storage for apollo
        window.sessionStorage.setItem('token',keycloak.token)

        dispatch({
            type: DataActionType.LOG_IN
        });
    }

    const _refreshToken = () => {
        window.sessionStorage.setItem('token',keycloak.token)
    }

    const _getNewKeycloakToken = () => {
        keycloak.updateToken(60).then((refreshed: any)=>{
            if (refreshed){
                _refreshToken();
                console.log('Token refreshed. New token: ', keycloak.token);
            }else {
                console.info('Token not refreshed: ' + new Date());
            }
        }).catch(() => {
            console.error('Failed to refresh token: ' + new Date());
        });
    }

    useEffect((): any => {
        if (keycloak && loading) {
            console.log('KeycloakService init: ...')
            KeycloakService.init()
                .then((authenticated?: any) => {
                    console.log('authenticated: ', authenticated)

                    if (authenticated) {
                        _localLogin()
                    } else {
                        _localLogout()
                    }
                    return setLoading(false);
                })
                .catch((__err: any) => {
                    _localLogout()
                    return setLoading(false);
                });
        } else {
            return setLoading(false);
        }
    }, [])

    keycloak.onAuthLogout = _localLogout;
    keycloak.onTokenExpired = _getNewKeycloakToken;

    if (loading) {
        return <LoadingSpinner />;
    }

    return children;
}

export default KeycloakProvider;
