import React from "react";

interface MaterialIconProps {
    name: string
}

export default function MaterialIcon(props: MaterialIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) {

    const {name} = props;

    // Docs: https://jossef.github.io/material-design-icons-iconfont/
    return <i
        {...props}
        className={'material-icons ' + props.className}
    >
        {name}
    </i>
}

