export const getValidationStatus = (fieldValidations: any[], attributeId: string) => {
    if (fieldValidations.length > 0) {
        const matchingValidation = fieldValidations.find(val => val.key === attributeId)

        if (matchingValidation) {
            return 'error';
        }
    }
    return undefined;
}
