import {convertTime} from "./unitConverter";

export const getPumpDownDisplayTime = (timeInSeconds: number): string => {
    if (timeInSeconds > 7200) {
        const timeInMinutes = Math.round(convertTime(timeInSeconds, "min"))
        const fullHours = Math.floor(timeInMinutes / 60)
        const restMinutes = timeInMinutes % 60

        return `${fullHours} h ${restMinutes} min`
    } else if (timeInSeconds > 120) {
        const fullMinutes = Math.floor(timeInSeconds / 60)
        const restSeconds = timeInSeconds % 60

        return `${fullMinutes} min ${restSeconds} s`
    }

    return `${timeInSeconds} s`
}
