import getCurrentStepIndex from "./getCurrentStepIndex";
import {Step} from "../types";

export function getPreviousStep(stepConfig: Step[], stepId: string){

    const currentStepIndex = getCurrentStepIndex(stepConfig, stepId)

    if (currentStepIndex === 0) {
        return stepConfig[currentStepIndex]
    }
    return stepConfig[currentStepIndex-1]

}

export function getLastStepBeforeResult(stepConfig: Step[]) {

    return stepConfig[stepConfig.length - 2];
}
