//@ts-nocheck
import * as React from "react";
import {Suspense} from "react";
import {ConfigProvider} from "antd";
import deDE from "antd/lib/locale/de_DE";
import enUS from "antd/lib/locale/en_US";
import {Provider} from "react-redux";
import store from "./redux/store";
import {useTranslation, withTranslation} from "react-i18next";
import i18n from "./i18n";
import Routes from "./routes";
import KeycloakProvider from "./provider/keycloak";
import './styles/index.less';
import {LoadingSpinner} from "@dreebit/pv-components";
import {ApolloProvider} from "@apollo/client";
import VacuumCalculatorApolloClient from "./graphql/Client";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

i18n();

const Root = () => {
    const {i18n} = useTranslation();

    if (!i18n) {
        return <LoadingSpinner />
    }

    return <div className={'bg-white full-height'}>
        <Provider store={store}>
            <ConfigProvider locale={i18n.language === 'de' ? deDE : enUS} >
                <KeycloakProvider>
                    <ApolloProvider client={VacuumCalculatorApolloClient}>
                        <ToastContainer
                            theme={"colored"}
                            position={"top-center"}
                            icon={false}
                            closeButton={false}
                        />
                        <Routes />
                    </ApolloProvider>
                </KeycloakProvider>
            </ConfigProvider>
        </Provider>
    </div>
};

const WrappedRoot = withTranslation()(Root);

export default () => {
    return <Suspense fallback={<div/>}>
        <WrappedRoot/>
    </Suspense>
};
