// @ts-nocheck
import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import MaterialIcon from "../MaterialIcon";

interface Link {
    text: string,
    onClick: Function
}

interface Props {
    status: "success" | "warning" | "error",
    headline?: string,
    text: string,
    link?: Link
}

const getIconFromStatus = (status: string) => {
    if (status === "success") {
        return "check"
    } else if (status === "warning") {
        return "error_outline"
    } else if (status === "error") {
        return "error"
    }
}

// Usage
//
// https://fkhadra.github.io/react-toastify/introduction
//
// toast.warning(<ToastContent
//     headline={"Titel der Warnung"}
//     status={"warning"}
//     link={{text: "Link Text", onClick: () => {}}}
//     text={t('no_currentUser_error_message')}
// />, {
//     autoClose: false
// })

const ToastContent = ({ status, headline, text, link }: Props) => (
    <div
        className={"custom-toast-content-wrapper"}
        data-testid={`toast`}
        data-cy={status}
    >
        <div className={"custom-toast-content-wrapper__left"}>
            {
                headline ? <div className={"toast-headline"}>{headline}</div> : null
            }
            <div className={`toast-text ${!headline && !link ? "font-bold" : ""}`}>{text}</div>
            {
                link ? <div className={'toast-link'} onClick={link?.onClick}>
                    {link.text} <MaterialIcon name={"arrow_forward"} className={"toast-link-icon"} />
                </div> : null
            }
        </div>
        {
            headline || link ?
                <div className={"custom-toast-content-wrapper__right"} style={{background: "rgba(255, 255, 255, 0.2)"}}>
                    <MaterialIcon
                        name={getIconFromStatus(status)}
                        className={"toast-icon"}
                    />
                </div>
            :
                <div className={"close-icon-wrapper"}>
                    <MaterialIcon name={"close"} className={"close-icon"} />
                </div>
        }
    </div>
)

export default ToastContent;
