import React from "react";
import {Redirect, Route, Switch, useParams} from "react-router-dom";
import FixedPumpSystemResultPumpCurveRoute from "./pumpCurves";
import {useQuery} from "@apollo/client";
import {CALCULATION_REPORT_CONFIG} from "../../../graphql/queries/calculationReportConfig";
import {LoadingSpinner} from "@dreebit/pv-components";
import _ from "lodash";

interface Props {
    calculationMode: string
    calculationType: string
    onMobileContent: (content: string) => void
}

export default function FixedPumpSystemResultRoute({calculationMode, calculationType, onMobileContent}: Props) {

    const {collectionId} = useParams<any>();

    const {loading, error, data} = useQuery(CALCULATION_REPORT_CONFIG, {
        variables: {
            id: collectionId
        }
    });

    const calculationReportConfig = _.get(data, 'calculationCollection.calculationReportConfig')

    if (loading) {
        return <div></div>
    }

    return (
        <Switch>
            <Route
                path={"/fixedPumpSystem/chamberEvacuation/result/:collectionId/pumpCurves"}
                component={() => <FixedPumpSystemResultPumpCurveRoute
                    calculationMode={calculationMode}
                    calculationType={calculationType}
                    onMobileContent={onMobileContent}
                    calculationReportConfig={calculationReportConfig}
                />}
            />
            <Redirect
                from={"/fixedPumpSystem/chamberEvacuation/result/:collectionId"}
                to={"/fixedPumpSystem/chamberEvacuation/result/:collectionId/pumpCurves"}
            />
            {/*<Route*/}
            {/*    path={"/fixedPumpSystem/chamberEvacuation/result/:resultId/mobile/:content"}*/}
            {/*    component={MobileContentRoute}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*    path={"/fixedPumpSystem/chamberEvacuation/result/:resultId"}*/}
            {/*    component={() => <ChamberResultSolutionRoute*/}
            {/*        calculationMode={calculationMode}*/}
            {/*        calculationType={calculationType}*/}
            {/*        onMobileContent={onMobileContent}*/}
            {/*        calculationReportConfig={calculationReportConfig}*/}
            {/*    />}*/}
            {/*/>*/}
        </Switch>
    )
}
