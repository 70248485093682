import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {useQuery} from "@apollo/client";
import {FeedbackBox, InfoBox, Loader, LoadingSpinner} from "@dreebit/pv-components";
import {Row, Col, Grid, Modal} from "antd";
import StepProgressBar from "../../../components/StepProgressBar";
import ChamberResultContent from "../../../components/ChamberResultContent";
import FinishedStepsOverview from "../../../components/FinishedStepsOverview";
import {stepConfig} from "../../../config/pumpFinderChamberEvacuationStepConfig";
import getFinishedSteps from "../../../utils/getFinishedSteps";
import ContactForm from "../../../components/ContactForm";
import MaterialIcon from "../../../components/MaterialIcon";
import {CALCULATION_RESULT_SOLUTIONS} from "../../../graphql/queries/calculationResult-solutions";

interface Props {
    calculationType: string
    calculationMode: string
    onMobileContent: (content: string) => void
    calculationReportConfig: any
}

export default function ChamberResultSolutionRoute({calculationType, calculationMode, onMobileContent, calculationReportConfig}: Props) {

    const {t} = useTranslation();
    const history = useHistory();
    const {collectionId} = useParams<any>();
    const { useBreakpoint } = Grid;
    const [contactFormVisible, setContactFormVisible] = useState(false);
    const screens = useBreakpoint();

    const lastStepIndex = stepConfig.length - 1;

    const onChangeStep = (stepIndex: any) => {
        if (calculationId) {
            history.push(`/${calculationMode}/${calculationType}/${calculationId}/${stepConfig[stepIndex].id}`);
        }
    };

    const onPumpCurveRoute = () => {
        history.push(`/${calculationMode}/${calculationType}/result/${collectionId}/pumpCurves`);
    }

    const [pumpType, setPumpType] = useState(_.get(calculationReportConfig, 'solutionType', 'single'));
    const [sortType, setSortType] = useState({
        key: _.get(calculationReportConfig, 'solutionSort.key', 'totalEstimatedTime'),
        direction: _.get(calculationReportConfig, 'solutionSort.direction', 'desc'),
    })

    const changeSort = (value: string) => {
        // values contain sort key and sort direction in the format "key_direction"
        const key = value.split("_")[0] || "totalEstimatedTime";
        const direction = value.split("_")[1] || "desc";

        setSortType({
            key: key,
            direction: direction
        })
    }

    const {loading, error, data} = useQuery(CALCULATION_RESULT_SOLUTIONS, {
        variables: {
            id: collectionId,
            solutionParams: {
                filter: {
                    solutionType: pumpType
                },
                sort: {
                    key: sortType.key,
                    direction: sortType.direction
                }
            }
        },
        fetchPolicy: "network-only"
    });

    const toggleContactFormVisibility = () => {
        setContactFormVisible(!contactFormVisible);
    };

    const calculationId = _.get(data, 'calculationCollection.calculations[0].id', undefined)
    const calculationCollection = _.get(data, 'calculationCollection', undefined);
    const calculationValues = _.get(data, 'calculationCollection.calculations[0].values', [])
    const resultPdfUrl = _.get(data, 'calculationCollection.sheetUrl')
    const finishedSteps = getFinishedSteps(calculationValues, stepConfig)

    if (loading) {
        return <LoadingSpinner />
    }

    // TODO: Adjust Error message from algorithm (PVEC-801)
    if (error) {
        return <FeedbackBox
            type={'error'}
            title={t('Error')}
            subtitle={error.message}
        />
    }

    return (
        <>
            <div className={'pt-40'}>
                <div>
                    <h1 className={'font-bold'}>{t('Vacuum Calculator')}</h1>
                    <h2>{t('Find the optimal pump for your application')}</h2>
                </div>
                <div className={'pv-40'}>
                    <StepProgressBar
                        stepConfig={stepConfig}
                        index={lastStepIndex}
                        onChangeStep={onChangeStep}
                        finishedSteps={finishedSteps}
                    />
                </div>
                <Row justify={'space-between'} gutter={40}>
                    <Col xs={24} md={17}>
                        <ChamberResultContent
                            calculationId={calculationId}
                            calculationCollection={calculationCollection}
                            collectionId={collectionId}
                            calculationReportConfig={calculationReportConfig}
                            onPumpCurveContent={onPumpCurveRoute}
                            sort={{
                                key: sortType.key,
                                direction: sortType.direction
                            }}
                            onChangeSort={(value: string) => changeSort(value)}
                            pumpType={pumpType}
                            onChangePumpType={(value: string) => setPumpType(value)}
                        />
                    </Col>
                    <Col xs={24} md={7}>
                        <div className={screens.lg ? 'ml-32' : 'mt-24'}>
                            <div className={'mb-24'}>
                                <InfoBox
                                    heading={t('need_help_title')}
                                    infoBoxText={t('need_help_subtitle')}
                                    infoBoxButtonLabel={t('Contact us')}
                                    onClick={screens.lg ? toggleContactFormVisibility : () => onMobileContent("contactForm")}
                                    topRightIcon={"contact_support"}
                                    buttonIcon={"mail_outline"}
                                />
                            </div>
                            <FinishedStepsOverview
                                stepConfig={stepConfig}
                                finishedSteps={finishedSteps}
                                calculationValues={calculationValues}
                                calculationId={calculationId}
                                calculationMode={calculationMode}
                                calculationType={calculationType}
                                editable={!!calculationId}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                title={t('Contact us')}
                visible={contactFormVisible}
                onCancel={toggleContactFormVisibility}
                footer={null}
                width={720}
            >
                <ContactForm
                    resultId={collectionId}
                    resultPdf={resultPdfUrl}
                    onSuccess={toggleContactFormVisibility}
                    onCancel={toggleContactFormVisibility}
                />
            </Modal>
        </>
    )
}
