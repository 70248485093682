// @ts-nocheck
import React, {forwardRef, useImperativeHandle, useRef} from "react";
import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    ChartData
} from "chart.js";
import 'chart.js/auto';
import zoomPlugin from "chartjs-plugin-zoom";
import {useTranslation} from "react-i18next";
import {generateChartConfig} from "../../utils/generateChartConfig";
import {getDisplayUnitWithoutAttribute} from "../../utils/getDisplayUnit";

ChartJS.register(zoomPlugin);

export interface EvacuationChartSettings {
    xMin?: number,
    yMin?: number,
    xMax?: number,
    yMax?: number,
    xUnit?: "Torr" | "Pa" | "hpa" | "mbar",
    yUnit?: "sccm" | "mbar_s"
}

interface Props {
    chartData: ChartData<"line">,
    settings?: EvacuationChartSettings
}

const GasFlowChart = forwardRef(({chartData, settings}: Props, ref) => {

    const {t} = useTranslation()
    const evacuationChartRef = useRef<ChartJS>(null);

    useImperativeHandle(ref, () => ({
        resetZoom() {
            evacuationChartRef.current.resetZoom();
        },
        returnChartConfig() {
            return generateChartConfig(evacuationChartRef?.current?.config);
        }
    }));

    const chartOptions = {
        hover: {
            mode: 'nearest',
            intersect: false
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: `${t('Pressure')} (${getDisplayUnitWithoutAttribute(settings?.xUnit)})`
                },
                min: settings?.xMin,
                max: settings?.xMax,
                display: true,
                type: 'logarithmic',
                ticks: {
                    callback: function(value: string | number, index: any, ticks: any) {
                        const decadeNumbers = ["1e+5", "1e+4", "1e+3", "1e+2", "1e+1", "1e+0", "1e-1", "1e-2", "1e-3", "1e-4", "1e-5", "1e-6", "1e-7", "1e-8", "1e-9", "1e-10", "1e-11", "1e-12", "1e-13", "1e-14", "1e-15"]
                        const expValue = parseFloat(value).toExponential(0) || value.toExponential(0) || undefined

                        if (decadeNumbers.includes(expValue)) {
                            return expValue
                        }
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: `${t('Gas flow')} (${getDisplayUnitWithoutAttribute(settings?.yUnit)})`
                },
                min: settings?.yMin,
                max: settings?.yMax,
                display: true,
                type: "logarithmic",
                ticks: {
                    callback: function(value: string | number, index: any, ticks: any) {
                        const decadeNumbers = ["1e+5", "1e+4", "1e+3", "1e+2", "1e+1", "1e+0", "1e-1", "1e-2", "1e-3", "1e-4", "1e-5", "1e-6", "1e-7", "1e-8", "1e-9", "1e-10", "1e-11", "1e-12", "1e-13", "1e-14", "1e-15"]
                        const expValue = parseFloat(value).toExponential(0) || value.toExponential(0) || undefined

                        if (decadeNumbers.includes(expValue)) {
                            return expValue
                        }
                    }
                }
            }
        },
        elements: {
            point:{
                radius: 0
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    title: function (context) {
                        return context[0]?.dataset?.label || '';
                    },
                    beforeLabel(tooltipItem: Item): string | string[] {
                        return tooltipItem?.parsed?.x?.toExponential(2) + ' ' + getDisplayUnitWithoutAttribute(settings?.xUnit) || '';
                    },
                    label: function(context) {
                        return context?.parsed?.y?.toExponential(2) + ' ' + getDisplayUnitWithoutAttribute(settings?.yUnit) || '';
                    }
                }
            },
            zoom: {
                limits: {
                    x: {
                        min: 0
                    },
                    y: {
                        min: 0
                    }
                },
                zoom: {
                    drag: {
                        enabled: true
                    },
                    mode: 'xy',
                }
            }
        } as any
    }

    return (
        <div>
            <Line
                ref={evacuationChartRef}
                data={chartData}
                options={chartOptions}
            />
        </div>
    )
});

export default GasFlowChart;
