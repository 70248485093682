export const formatSizeUnitsForSubmit = (values: any, flexAttributes: any, form: any) => {
    delete values.chamberSizeUnits;
    const sizeFlexAttributes = flexAttributes.filter((attr: string) => attr !== "chamberSizeUnits")
    // @ts-ignore
    const unitValues = sizeFlexAttributes.reduce((acc,curr)=> (acc[curr+"Unit"] = form.getFieldValue("chamberSizeUnits"), acc),{});

    let tmp = {
        ...values,
        ...unitValues
    }

    // set attributes and units null which are not in the form
    let sizeAttributes = ["chamberVolumeLength", "chamberVolumeWidth", "chamberVolumeHeight", "chamberVolumeRadius"]
    sizeAttributes = sizeAttributes.filter(val => !Object.keys(tmp).includes(val));
    const hiddenValues = sizeAttributes.reduce((acc,curr)=> ({
        ...acc,
        [curr]: null,
        [curr + "Unit"]: null
    }),{});

    tmp = {
        ...tmp,
        ...hiddenValues
    }

    return tmp;
}
