// @ts-nocheck
import React, {useState} from "react";
import "./index.css";
import {useTranslation} from "react-i18next";
import Recommendation from "../Recommendation";
import {Modal, Grid, Row, Col, Tooltip} from "antd";
import {Button, ProductCard, ProductCardFilterWrapper, FeedbackBox, KeycloakService} from "@dreebit/pv-components";
import {useSelector} from "react-redux";
import {getUser, isLoggedIn} from "../../redux/selectors/user";
import {useMutation} from "@apollo/client";
import {SAVE_CALCULATION_COLLECTION} from "../../graphql/mutations/saveCalculationCollection";
import {CREATE_CALCULATION_RESULT} from "../../graphql/mutations/createCalculationResult";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {getSolutionTitle} from "../../utils/getSolutionTitle";
import ShowMoreAndLessButton from "../ShowMoreAndLessButton";
import PumpingStationSwitch from "../PumpingStationSwitch";
import SummaryCard from "../SummaryCard";
import ResultSortSelect from "../ResultSortSelect";
import ResultActionButtons from "../ResultActionButtons";
import LegalNotice from "../LegalNotice";
import {UPDATE_CALCULATION_REPORT_CONFIG} from "../../graphql/mutations/updateCalculationReportConfig";
import SaveCalculationForm from "../SaveCalculationForm";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";
import ProductLinks from "../ProductLinks";
import DebugConsole from "../DebugConsole";
import getUserRoles from "../../hooks/getUserRoles";
import {CALCULATION_RESULT_SOLUTIONS} from "../../graphql/queries/calculationResult-solutions";
import {isMobileOrTablet} from "../../utils/isMobileOrTablet";

export interface Sort {
    key: string,
    direction: string
}

interface Props {
    calculationId: string,
    calculationCollection: any,
    calculationReportConfig: any,
    collectionId: string,
    onPumpCurveContent?: () => void,
    sort: Sort,
    onChangeSort: (value: string) => void,
    pumpType: string,
    onChangePumpType: (value: string) => void
}

export default function ChamberResultContent({calculationId, calculationCollection, collectionId, onPumpCurveContent, calculationReportConfig, sort, onChangeSort, pumpType, onChangePumpType}: Props) {

    const {t, i18n} = useTranslation()
    const history = useHistory();
    const loggedIn = useSelector(isLoggedIn);
    const user = useSelector(getUser);
    const isAuthorized = user.registrationStatus === "LEAD" || user.registrationStatus === "APPROVED"
    const roles = getUserRoles();
    const isExpertUser = roles.includes('expert_vacuum_calculator');
    const backendVar = _.get(window, '__BE__');
    const env = _.get(backendVar, 'env', null);

    const [saveCalculation] = useMutation(SAVE_CALCULATION_COLLECTION);
    const [updateCalculationReportConfig] = useMutation(UPDATE_CALCULATION_REPORT_CONFIG);
    const [createCalculationResult] = useMutation(CREATE_CALCULATION_RESULT)
    const [isSaveModalVisible, setSaveModalVisible] = useState(false);
    const [isDebugModalVisible, setDebugModalVisible] = useState(false);
    const [debugInformation, setDebugInformation] = useState(["No information"]);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const keycloak = KeycloakService.getInstance();

    const isMobile = isMobileOrTablet();

    const recommendation = _.get(calculationCollection, 'solutionList.solutions[0]', {})
    const otherOptionsFull = _.get(calculationCollection, 'solutionList.solutions', []).slice(1)
    const numberOfOtherOptions = 4;
    // pumpFinder results only have 1 calculation in each collection
    const firstCollectionCalculation = calculationCollection.calculations[0].id;

    const otherSuitableProducts = _.get(calculationCollection, 'calculations[0].otherSuitableProducts', [])

    const [showAll, setShowAll] = useState(false);

    const _saveCalculation = (values: any) => {
        const firstThreeSolutionIds = _.get(calculationCollection, 'solutionList.solutions', []).slice(0, 3).map((a: any) => a.id)

        updateCalculationReportConfig({
            variables: {
                calculationReportConfigId: _.get(calculationCollection, 'calculationReportConfig.id'),
                input: {
                    solutionType: pumpType,
                    solutionSort: {
                        key: sort.key,
                        direction: sort.direction
                    },
                    activeSolutionIds: firstThreeSolutionIds,
                }
            }
        })

        saveCalculation({
            variables: {
                id: collectionId,
                title: _.get(values, 'title')
            }
        }).then((res) => {
            setSaveModalVisible(false)
            toast.success(<ToastContent
                status={"success"}
                text={t('Calculation was saved successfully')}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)
            toast.error(<ToastContent
                status={"error"}
                headline={t('Error saving calculation')}
                text={`${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    }

    const _createResult = () => {
        createCalculationResult({
            variables: {
                id: calculationId,
            },
            refetchQueries: [
                {
                    query: CALCULATION_RESULT_SOLUTIONS,
                    variables: {
                        id: collectionId,
                        filter: {
                            solutionType: pumpType
                        },
                        sort: {
                            key: sortType.key,
                            direction: sortType.direction
                        }
                    }
                }
            ],
            awaitRefetchQueries: true
        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

            toast.error(<ToastContent
                status={"error"}
                headline={t('Calculation error')}
                text={`${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    }

    const _goToPumpCurves = () => {
        const firstThreeSolutionIds = _.get(calculationCollection, 'solutionList.solutions', []).slice(0, 3).map((a: any) => a.id);

        updateCalculationReportConfig({
            variables: {
                calculationReportConfigId: _.get(calculationCollection, 'calculationReportConfig.id'),
                input: {
                    solutionType: pumpType,
                    solutionSort: {
                        key: sort.key,
                        direction: sort.direction
                    },
                    activeSolutionIds: firstThreeSolutionIds,
                }
            }
        }).then(() => {
            return !(onPumpCurveContent) || onPumpCurveContent();
        })
    }

    const _formatFilterValues = (filters: any, count: number) => {
        const countFilter = {
            label: t('Count'),
            value: count,
            highlighted: true
        }

        if (filters.length) {
            let temp = filters.map((x: any) => {
                return {
                    label: x.name,
                    value: x.value,
                    highlighted: false
                }
            })
            temp.unshift(countFilter)

            return temp;
        }

        return [countFilter]

    }

    const _getMissingProductImg = () => {
        if (i18n?.language === 'de') {
            return "../../assets/images/missing_product_DE.jpg"
        } else {
            return "../../assets/images/missing_product_EN.jpg"
        }
    }

    function openProductUrl (url: string) {
        // @ts-ignore
        window.open(url, '_blank').focus()
    }

    if (calculationCollection?.solutionList?.total === 0) {
        return <div>
            <FeedbackBox
                type={'error'}
                title={t('No result found')}
                subtitle={t('no_result_sub')}
            />
            {
                firstCollectionCalculation ?
                    <Button
                        type={'ghost'}
                        className={'mt-32'}
                        onClick={() => history.push(`/pumpFinder/chamberEvacuation/${calculationId}/chamberStepPump`)}
                        icon={"arrow_back"}
                        iconPosition={"left"}
                    >
                        {t('Back')}
                    </Button>
                : null
            }
        </div>
    }

    return (
        <>
            <div className={'flex-col align-center'}>
                {
                    !calculationCollection?.calculations?.filter(calc => !calc.valid).length > 0 ?
                        <div className={"pb-24"}>
                            <FeedbackBox
                                type={'warning'}
                                title={t('Result not up to date')}
                                subtitle={t('Result_last_change_hint', {
                                    date: moment(calculationCollection.update).format("LLL"),
                                })}
                            >
                                {calculationId ?
                                    <Button
                                        type={"primary-inverted"}
                                        onClick={_createResult}
                                        icon={"arrow_forward"}
                                    >
                                        {t('Recalculate')}
                                    </Button>
                                    : null}
                            </FeedbackBox>
                        </div>
                    : null
                }
                <div>
                    <div className={`${screens.lg ? 'flex-row flex-space-between flex-align-items-center' : ''}`}>
                        <h5>
                            {t('Summary of results')}
                        </h5>
                        {
                            user.registrationStatus === "LEAD" ?
                                <Tooltip title={t('pumpCurve-hint_lead')} trigger={isMobile ? "click" : "hover"}>
                                    <div>
                                        <Button
                                            type={'primary'}
                                            title={t('pumpCurve-hint_lead')}
                                            className={`${screens.lg ? 'ml-8' : 'mt-24 mb-24'}`}
                                            style={{width: screens.lg ? 'auto' : '100%'}}
                                            disabled={true}
                                            noPointerEvents={true}
                                            icon={"arrow_forward"}
                                        >
                                            {t('Pump curve report')}
                                        </Button>
                                    </div>
                                </Tooltip>
                            :
                                <Button
                                    type={'primary'}
                                    className={`${screens.lg ? 'ml-8' : 'mt-24 mb-24'}`}
                                    style={{width: screens.lg ? 'auto' : '100%'}}
                                    onClick={loggedIn && isAuthorized ? _goToPumpCurves : () => keycloak.login({
                                        locale: i18n?.language
                                    })}
                                    icon={"arrow_forward"}
                                >
                                    {t('Pump curve report')}
                                </Button>

                        }
                    </div>
                    <LegalNotice className={'pb-16'}/>
                    <Row justify={'space-between'} align={"middle"} className={'pb-24'}>
                        <Col flex={screens.lg ? "none" : "5"}>
                            <PumpingStationSwitch
                                value={pumpType}
                                onChange={onChangePumpType}
                                pumpingStationDisabled={!_.get(calculationCollection, 'solutionList.pumpingStationFilter')}
                            />
                        </Col>
                        <Col flex={screens.lg ? "none" : "5"}>
                            <ResultSortSelect
                                value={`${sort.key}_${sort.direction}`}
                                onChange={onChangeSort}
                            />
                        </Col>
                    </Row>
                    {
                        pumpType === "pumpingStation" ?
                            <p>{t('pumping_station_hint')}</p>
                            : null
                    }
                    <p className={'pb-16'}>{t('gas_ballast_hint')}</p>
                    <Recommendation
                        type={'primary'}
                        title={getSolutionTitle(recommendation, t, true)}
                        showDebugBadge={isExpertUser && (env === "testing" || env === "staging")}
                        onDebugClick={() => {
                            setDebugInformation(JSON.parse(recommendation.debugInfo) || ["No information"])
                            setDebugModalVisible(true)
                        }}
                    >
                        {recommendation.components.map((comp: any, index: number) => {
                            return <ProductCard
                                key={index}
                                img={{
                                    alt: comp.title,
                                    src: comp.imageUrl ? comp.imageUrl : _getMissingProductImg()
                                }}
                                productName={comp.title}
                                productCategory= {comp.title}
                                onClick={comp.url ? () => openProductUrl(comp.url) : undefined}
                                width={screens.lg ? '261px' : '100%'}
                            >
                                <ProductCardFilterWrapper
                                    filters={_formatFilterValues(comp.filters, comp.count)}
                                />
                            </ProductCard>
                        })}
                        <SummaryCard
                            registrationStatus={user?.registrationStatus || null}
                            width={screens.lg ? '261px' : '100%'}
                            price={_.get(recommendation, 'price')}
                            weight={_.get(recommendation, 'weight')}
                            noise={_.get(recommendation, 'noise')}
                            pumpDownTime={_.get(recommendation, 'totalEstimatedTime')}
                        />
                    </Recommendation>
                </div>

                {otherOptionsFull.length > 0 ?
                    <div className={'pt-16'}>
                        {otherOptionsFull.slice(0, showAll ? otherOptionsFull.length : numberOfOtherOptions).map((solution: any) => {
                            return <div className={'pb-8'} key={solution.id}>
                                <Recommendation
                                    type={'secondary'}
                                    title={getSolutionTitle(solution, t)}
                                    onDebugClick={() => {
                                        setDebugInformation(JSON.parse(solution.debugInfo) || ["No information"])
                                        setDebugModalVisible(true)
                                    }}
                                    showDebugBadge={isExpertUser && (env === "testing" || env === "staging")}
                                >
                                    {solution.components.map((comp: any, index: number) => {
                                        return <ProductCard
                                            key={index}
                                            img={{
                                                alt: comp.title,
                                                src: comp.imageUrl
                                            }}
                                            productName={comp.title}
                                            productCategory= {comp.title}
                                            onClick={comp.url ? () => openProductUrl(comp.url) : undefined}
                                            width={screens.lg ? '261px' : '100%'}
                                        >
                                            <ProductCardFilterWrapper
                                                filters={_formatFilterValues(comp.filters, comp.count)}
                                            />
                                        </ProductCard>
                                    })}
                                    <SummaryCard
                                        registrationStatus={user?.registrationStatus || null}
                                        width={screens.lg ? '261px' : '100%'}
                                        price={_.get(solution, 'price')}
                                        weight={_.get(solution, 'weight')}
                                        noise={_.get(solution, 'noise')}
                                        pumpDownTime={_.get(solution, 'totalEstimatedTime')}
                                    />
                                </Recommendation>
                            </div>
                        })}
                    </div>
                :null
                }

                {
                    otherOptionsFull.length > numberOfOtherOptions &&
                        <ShowMoreAndLessButton
                            className={'mt-24'}
                            extended={showAll}
                            toggle={() => setShowAll(!showAll)}
                        />
                }

                {
                    otherSuitableProducts?.length > 0 &&
                        <ProductLinks
                            className={'mt-40'}
                            products={otherSuitableProducts}
                        />
                }

                <ResultActionButtons
                    className={'mt-40'}
                    buttons={firstCollectionCalculation ? ["back", "newCalculation", "createReport", "save"] : ["newCalculation", "createReport", "save"]}
                    onBack={() => history.push(`/pumpFinder/chamberEvacuation/${firstCollectionCalculation}/chamberStepPump`)}
                    onCreateReport={loggedIn && isAuthorized ? _goToPumpCurves : () => keycloak.login({
                        locale: i18n?.language
                    })}
                    onNewCalculation={() => history.push('/')}
                    onSave={(loggedIn && calculationId) ? () => setSaveModalVisible(true) : undefined}
                    showSaveHint={!(loggedIn && calculationId)}
                    showCurveHint={user.registrationStatus === "LEAD"}
                />
            </div>
            <Modal
                title={t("Save the calculation")}
                visible={isSaveModalVisible}
                footer={null}
                onCancel={() => setSaveModalVisible(false)}
            >
                <SaveCalculationForm
                    onSave={_saveCalculation}
                    onCancel={() => setSaveModalVisible(false)}
                />
            </Modal>
            <Modal
                title={t("Debug-Information")}
                visible={isDebugModalVisible}
                footer={null}
                width={800}
                onCancel={() => setDebugModalVisible(false)}
            >
                <DebugConsole
                    content={debugInformation}
                />
            </Modal>
        </>
    )
}
