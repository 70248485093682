export const logToLinear = (value: number | string): number => {
    let number = value;
    if (typeof number === 'string') {
        number = Number.parseFloat(number)
    }

    number = Math.pow(10, number)

    return number
}

export const linearToLog = (value: number | string): number => {
    let number = value;
    if (typeof number === 'string') {
        number = Number.parseFloat(number)
    }

    number = Math.log10(number)

    return number
}
