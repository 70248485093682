import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Empty, Pagination} from "antd"
import {DeleteFilled} from "@ant-design/icons"
import "./index.css"
import {useMutation, useQuery} from "@apollo/client";
import {LoadingSpinner} from "@dreebit/pv-components";
import _ from "lodash";
import {CALCULATION_COLLECTION_LIST} from "../../graphql/queries/calculationCollectionList";
import moment from "moment";
import "./index.css"
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";
import formatLongTitles from "../../utils/formatLongTitles";
import {DELETE_CALCULATION_COLLECTION} from "../../graphql/mutations/deleteCalculationCollection";

interface Props {
    calculationType?: string,
    calculationMode?: string,
    showCalculationType?: boolean,
    childrenCount?: number,
    hideDelete?: boolean,
    onSelect?: (calculationCollectionId: string) => void
}

export default function SavedCalculationsList({calculationType, calculationMode, showCalculationType, childrenCount, hideDelete, onSelect}: Props) {

    const {t} = useTranslation();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);

    const pageSize = 8;
    const [deleteCalculation] = useMutation(DELETE_CALCULATION_COLLECTION);
    const {loading, data} = useQuery(CALCULATION_COLLECTION_LIST, {
        variables: {
            start: (currentPage - 1) * pageSize,
            limit: pageSize,
            calculationType: calculationType || null,
            calculationMode: calculationMode || null,
            childrenCount: childrenCount || null
        }
    });

    const totalCount = _.get(data, 'calculationCollectionList.total')
    const calculationCollections = _.get(data, 'calculationCollectionList.calculationCollections')

    const _deleteCalc = (id: string) => {
        deleteCalculation({
            variables: {
                ids: [id],
            },
            refetchQueries: [
                {
                    query: CALCULATION_COLLECTION_LIST,
                    variables: {
                        start: (currentPage - 1)*8,
                        limit: 8,
                        calculationType: calculationType || null,
                        calculationMode: calculationMode || null
                    }
                }
            ],
            awaitRefetchQueries: true
        }).then((res) => {
            let success = _.get(res, 'data.deleteCalculationCollection.success')
            if (success) {
                toast.success(<ToastContent
                    status={"success"}
                    text={_.get(res, 'data.deleteCalculationCollection.message')}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            } else {
                toast.error(<ToastContent
                    status={"error"}
                    text={_.get(res, 'data.deleteCalculationCollection.message')}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            }
        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

            toast.error(<ToastContent
                status={"error"}
                text={t('Error') + `: ${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    }

    if (loading) {
        return <LoadingSpinner />
    }

    if (totalCount > 0) {
        return <div className={'full-width flex-col flex-center align-center'}>
            <table className={'full-width'} cellPadding={14}>
                <tr>
                    <th>{t('Name')}</th>
                    <th>{t('Date')}</th>
                    {
                        showCalculationType && <th>{t('calculationMode')}</th>
                    }
                    {
                        showCalculationType && <th>{t('calculationType')}</th>
                    }
                    {
                        hideDelete ? null : <th></th>
                    }
                </tr>
                {calculationCollections.map((coll: any) => {
                    return <tr>
                        <td
                            className={'font-bold calculation-title'}
                            onClick={() => {
                                const calculationId = _.get(coll, 'calculations[0].id')

                                if (onSelect) {
                                    onSelect(calculationId)
                                } else if (coll.calculationMode && coll.calculationType && coll.id) {
                                    history.push(`/${coll.calculationMode}/${coll.calculationType}/${calculationId}`)
                                } else {
                                    toast.error(<ToastContent
                                        status={"error"}
                                        text={t('Error opening calculation')}
                                    />, {
                                        autoClose: 7000,
                                        pauseOnHover: true
                                    })
                                }
                            }}
                        >
                            {formatLongTitles(coll.title, 24, true) || t('No title')}
                        </td>
                        <td>{moment(coll.insert).format("L")}</td>
                        {
                            showCalculationType && <td>{t(_.get(coll, 'calculationMode', ''))}</td>
                        }
                        {
                            showCalculationType && <td>{t(_.get(coll, 'calculationType', ''))}</td>
                        }
                        {
                            !hideDelete && <td className={'font-bold'}>
                                    <Button type={'link'} onClick={() => _deleteCalc(coll.id)}>
                                        <DeleteFilled />
                                    </Button>
                                </td>
                        }
                    </tr>
                })}
            </table>

            <Pagination
                style={{margin: "32px auto"}}
                defaultCurrent={1}
                current={currentPage}
                total={totalCount}
                defaultPageSize={pageSize}
                responsive={true}
                onChange={(page) => setCurrentPage(page)}
                showSizeChanger={false}
            />
        </div>

    } else {
        return (
            <div className={'full-width'}>
                <Empty
                    className={'mv-32'}
                    description={t('No calculations')}
                />
            </div>
        )
    }
}
