import React from "react";
import {Radio} from "antd";
import {useTranslation} from "react-i18next";


export interface Props {
    value: string,
    onChange: (value: string) => void,
    disabled?: boolean
    pumpingStationDisabled?: boolean
}

export default function PumpingStationSwitch(props: Props) {

    const {value, onChange, disabled, pumpingStationDisabled} = props;
    const {t} = useTranslation();

    return (
        <Radio.Group onChange={(e) => onChange(e.target.value)} defaultValue={value} disabled={disabled}>
            <Radio value={'single'}>{t('Individual pumps')}</Radio>
            <Radio value={'pumpingStation'} disabled={pumpingStationDisabled}>{t('Pumping stations')}</Radio>
        </Radio.Group>
    )
}
