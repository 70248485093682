import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {useQuery} from "@apollo/client";
import {FeedbackBox, LoadingSpinner} from "@dreebit/pv-components";
import ChamberResultPumpCurveContent from "../../../components/ChamberResultPumpCurveContent";
import {CALCULATION_RESULT_CURVES} from "../../../graphql/queries/calculationResult-curves";
import {stepConfig} from "../../../config/pumpFinderChamberEvacuationStepConfig";

interface Props {
    calculationMode: string
    calculationType: string
    onMobileContent: (content: string) => void,
    calculationReportConfig: any
}

export default function ChamberResultPumpCurveRoute({calculationMode, calculationType, onMobileContent, calculationReportConfig}: Props) {

    const {t} = useTranslation();
    const {collectionId} = useParams<any>();
    const history = useHistory();

    const [pumpType, setPumpType] = useState(_.get(calculationReportConfig, 'solutionType', 'single'));
    const [sortType, setSortType] = useState({
        key: _.get(calculationReportConfig, 'solutionSort.key', 'totalEstimatedTime'),
        direction: _.get(calculationReportConfig, 'solutionSort.direction', 'desc')
    })

    const changeSort = (value: string) => {
        // values contain sort key and sort direction in the format "key_direction"
        const key = value.split("_")[0] || "totalEstimatedTime";
        const direction = value.split("_")[1] || "desc";

        setSortType({
            key: key,
            direction: direction
        })
    }

    const {loading, error, data} = useQuery(CALCULATION_RESULT_CURVES, {
        variables: {
            id: collectionId,
            solutionParams: {
                filter: {
                    solutionType: pumpType
                },
                sort: {
                    key: sortType.key,
                    direction: sortType.direction
                }
            }
        }
    });

    const calculationCollection = _.get(data, 'calculationCollection', undefined)

    const onBackToResult = () => {
        history.push(`/${calculationMode}/${calculationType}/result/${collectionId}`);
    }

    if (loading) {
        return <LoadingSpinner />
    }

    if (error) {
        return <FeedbackBox
            type={'error'}
            title={t('Error')}
            subtitle={error.message}
        />
    }

    return (
        <>
            <div className={'pt-40'}>
                <div>
                    <h1 className={'font-bold'}>{t('Vacuum Calculator')}</h1>
                    <h2>{t('All pump curves in comparison')}</h2>
                </div>
                <ChamberResultPumpCurveContent
                    stepConfig={stepConfig}
                    calculationMode={calculationMode}
                    calculationType={calculationType}
                    calculationCollection={calculationCollection}
                    onBackToResult={onBackToResult}
                    calculationReportConfig={calculationReportConfig}
                    sort={{
                        key: sortType.key,
                        direction: sortType.direction
                    }}
                    onChangeSort={(value: string) => changeSort(value)}
                    pumpType={pumpType}
                    onChangePumpType={(value: string) => setPumpType(value)}
                    showGasCurve={false}
                    onMobileContent={onMobileContent}
                />
            </div>
        </>
    )
}
