import * as React from "react"
import PumpPartsSvg from "../PumpPartsSvg";
import {Col} from "antd";

interface Props {
    highlight?: "chamber";
    calculationValues?: any;
    props?: any,
    isChamberStep?: boolean
}

export default function CustomPumpSvg ({highlight, calculationValues, isChamberStep, props}: Props) {

    return (
        <Col>
            <PumpPartsSvg pumpSystemType={"chamber"} isChamberStep={isChamberStep} active={true} props={{height: "100px", width: "100%"}} />
            <PumpPartsSvg pumpSystemType={"turboPipe"} active={!!calculationValues.turboPipeLength} props={{height: "100px", width: "100%"}} />
            <PumpPartsSvg pumpSystemType={"turboPump"} active={!!(calculationValues.turboPump && calculationValues.turboPumpCount)} props={{height: "100px", width: "100%"}} />
            <PumpPartsSvg pumpSystemType={"primaryPipe"} active={!!calculationValues.primaryPipeLength} props={{height: "100px", width: "100%"}} />
            <PumpPartsSvg pumpSystemType={"rootsPump"} active={!!(calculationValues.rootsPump && calculationValues.rootsPumpCount)} props={{height: "100px", width: "100%"}} />
            <PumpPartsSvg pumpSystemType={"backingPump"} active={!!(calculationValues.backingPump && calculationValues.backingPumpCount)} props={{height: "100px", width: "100%"}} />
        </Col>
    )
}
