import React from "react";
import {Slider} from "antd";
import {linearToLog, logToLinear} from "../../utils/linearToLog";

interface DesorptionSliderConfig {
    min: number,
    max: number,
    minExponential: number,
    maxExponential: number,
    exponentMarks: {[key: number]: string}
}

export interface Props {
    value: number,
    onChange?: (value: number) => void,
    config: DesorptionSliderConfig
}

export default function DesorptionSlider(props: Props) {

    const {value, onChange, config} = props;

    return (
        <div>
            <Slider
                marks={config.exponentMarks}
                min={config.min}
                max={config.max}
                included={false}
                value={linearToLog(value)}
                step={1/50}
                onChange={(v) => {
                    if (onChange){
                        onChange(logToLinear(v))
                    }
                }}
                tooltipVisible={false}
            />
        </div>
    )
}
