import {Step} from "../types";
import {parseLocalizedFloat} from "./parseLocalizedFloat";
import {CalculationValue} from "./convertDesorptionRateForm";

/**
 *
Input
 {
    "targetPressure": "5.2",
    "targetPressureUnit": "mbar",
 }

 Output
 [{
    "key:" "targetPressure",
    "unit": "mbar",
    "value": 5.2
 }]

 Output ToDo:

 {
    "targetPressure": {
        "unit": "mbar",
        "value": 5.2
    }
 }

 * @param obj
 */

const formatFormData = (obj: {[key: string]: any}, step: Step): CalculationValue[] => {

    // parse form input values to floats etc. since the form will always return a string
    const formatValueType = (value: any, attributeId: string) => {

        const attr = step.attributes.find(x => x.id === attributeId)

        if (attr && attr.type === "number") {
            return parseLocalizedFloat(value)
        }
        return value;
    }

    // get all unit form values
    const unitKeys = Object.keys(obj).filter((key) => {
        return key.endsWith('Unit');
    });

    // get all actual values
    const valueKeys = Object.keys(obj).filter((key) => {
        return unitKeys.indexOf(key) === -1
    });

    let result: any = {}

    valueKeys.map(( key) => {

        const hasUnitKey = unitKeys.find((unitKey) => key+"Unit" === unitKey);

        if (hasUnitKey){
            result[key] = {
                unit: obj[hasUnitKey],
                value: formatValueType(obj[key], key)
            }
        } else {
            result[key] = {
                value: formatValueType(obj[key], key)
            }
        }
    });

    return result;
}
export default formatFormData;
