import { length, area, volume } from 'units-converter';
import _ from 'lodash'
import {parseLocalizedFloat} from "./parseLocalizedFloat";

export const calculateGeometry = (data: any): { volume: number, area: number } => {

    if (data.chamberVolumeGeometry === "cubic") {
        if (_.isNil(data.chamberVolumeHeight) || _.isNil(data.chamberVolumeHeightUnit) ||
            _.isNil(data.chamberVolumeLength) || _.isNil(data.chamberVolumeLengthUnit) ||
            _.isNil(data.chamberVolumeWidth) || _.isNil(data.chamberVolumeWidthUnit)) {
            return {volume: 0, area: 0}
        }

        // needs a check since NumberInput can return a string
        const heightToFloat = typeof data.chamberVolumeHeight === 'string' ? parseLocalizedFloat(data.chamberVolumeHeight) : data.chamberVolumeHeight
        const lengthToFloat = typeof data.chamberVolumeLength === 'string' ? parseLocalizedFloat(data.chamberVolumeLength) : data.chamberVolumeLength
        const widthToFloat = typeof data.chamberVolumeWidth === 'string' ? parseLocalizedFloat(data.chamberVolumeWidth) : data.chamberVolumeWidth


        const h = length(heightToFloat).from(data.chamberVolumeHeightUnit).to('m').value || 0
        const l = length(lengthToFloat).from(data.chamberVolumeLengthUnit).to('m').value || 0
        const w = length(widthToFloat).from(data.chamberVolumeWidthUnit).to('m').value || 0

        const v = l*w*h;
        const a = 2 * (h*l + h*w + l*w);

        return {
            volume: volume(v).from('m3').to(data.chamberVolumeUnit).value,
            area: area(a).from('m2').to(data.chamberSurfaceUnit).value
        }

    } else if (data.chamberVolumeGeometry === "cylindrical") {
        if (_.isNil(data.chamberVolumeHeight) || _.isNil(data.chamberVolumeHeightUnit) ||
            _.isNil(data.chamberVolumeRadius) || _.isNil(data.chamberVolumeRadiusUnit)) {
            return {volume: 0, area: 0}
        }

        const heightToFloat = typeof data.chamberVolumeHeight === 'string' ? parseLocalizedFloat(data.chamberVolumeHeight) : data.chamberVolumeHeight
        const radiusToFloat = typeof data.chamberVolumeRadius === 'string' ? parseLocalizedFloat(data.chamberVolumeRadius) : data.chamberVolumeRadius

        const h = length(heightToFloat).from(data.chamberVolumeHeightUnit).to('m').value || 0
        const r = length(radiusToFloat).from(data.chamberVolumeRadiusUnit).to('m').value || 0

        const v = Math.PI*Math.pow(r,2)*h;
        const a = 2*Math.PI*Math.pow(r,2) + 2*Math.PI*r*h;

        return {
            volume: volume(v).from('m3').to(data.chamberVolumeUnit).value,
            area: area(a).from('m2').to(data.chamberSurfaceUnit).value
        }

    } else if (data.chamberVolumeGeometry === "spherical") {
        if (_.isNil(data.chamberVolumeRadius) || _.isNil(data.chamberVolumeRadiusUnit)) {
            return {volume: 0, area: 0}
        }

        const radiusToFloat = typeof data.chamberVolumeRadius === 'string' ? parseLocalizedFloat(data.chamberVolumeRadius) : data.chamberVolumeRadius

        const r = length(radiusToFloat).from(data.chamberVolumeRadiusUnit).to('m').value

        const v = 4/3 * Math.PI * Math.pow(r, 3);
        const a = 4*Math.PI*Math.pow(r,2);

        return {
            volume: volume(v).from('m3').to(data.chamberVolumeUnit).value,
            area: area(a).from('m2').to(data.chamberSurfaceUnit).value
        }
    }

    return {volume: 0, area: 0}
}
