import React, {ReactNode, useState} from 'react';
import './index.css';
import {Tag} from "antd";

export interface Props {
    children?: ReactNode,
    title: string,
    type: 'primary' | 'secondary',
    showDebugBadge?: boolean,
    onDebugClick?: () => void
}

const Recommendation = (props: Props) => {

    const {title, type, showDebugBadge, onDebugClick} = props;
    const [contentVisible, setContentVisible] = useState(type === 'primary');

    return (
        <div >
            <div className={`recommendation-header ${type} ${contentVisible ? "contentVisible" : ""}`}>
                <div className={"left-content"}>
                    {title}
                    {
                        showDebugBadge ?
                            <Tag
                                color="default"
                                className={"debug-badge"}
                                onClick={onDebugClick ? onDebugClick : () => {}}
                            >
                                Debug
                            </Tag>
                            : null
                    }
                </div>
                {
                    type === 'secondary' && contentVisible ?
                        <i
                            className="material-icons"
                            onClick={() => setContentVisible(false)}
                        >
                            keyboard_arrow_up
                        </i>
                    : type === 'secondary' && !contentVisible ?
                            <i
                                className="material-icons"
                                onClick={() => setContentVisible(true)}
                            >
                                keyboard_arrow_down
                            </i>
                    : null
                }
            </div>
            {
                contentVisible ?
                    <div className={'recommendation-content'}>
                        {props.children}
                    </div>
                : null
            }
        </div>
    );
}


export default Recommendation;
