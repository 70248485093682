import {StepAttribute} from "../types";
import _ from "lodash";

const getPumpStatus = (attribute: StepAttribute, initialValues: {[key: string]: string}) => {
    if (attribute.pumpSystemType === "turboPipe" && !initialValues["turboPump"]) {
        return "unaddable"
    }

    if (attribute.pumpSystemType === "backingPump") {
        return "active"
    }

    if (initialValues[attribute.id] && initialValues[attribute.id] !== "") {
        return "active"
    }

    return "addable"
}


export const formatPumpInputAttributes = (attributes: StepAttribute[], initialValues: {[key: string]: string}): any[] => {

    let result: any[] = []

    attributes.forEach((attribute) => {
        if (!result.find(x => x.pumpSystemType === attribute.pumpSystemType)) {
            result.push({
                pumpSystemType: attribute.pumpSystemType,
                label: attribute.pumpSystemLabel,
                status: getPumpStatus(attribute, initialValues),
                pumpQueryTypes: attribute.pumpQueryTypes,
                attributes: _.groupBy(attributes, 'pumpSystemType')[attribute.pumpSystemType || "none"]
            })
        } else {
            const index = result.findIndex(x => x.pumpSystemType === attribute.pumpSystemType);

            if (result[index].pumpQueryTypes && attribute.pumpQueryTypes) {
                result[index].pumpQueryTypes.push(attribute.pumpQueryTypes);
            } else if (!result[index].pumpQueryTypes && attribute.pumpQueryTypes) {
                result[index].pumpQueryTypes = attribute.pumpQueryTypes
            }
        }
    })

    return result;
}
