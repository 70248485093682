import {Step, StepAttribute} from "../types";
import {stepConfig} from "../config/pumpFinderChamberEvacuationStepConfig";

const getFinishedSteps = (calculationValues: any, stepConfig: Step[]) => {

    const hasValidValue = (attribute: StepAttribute) => {
        if (attribute.optional !== true) {
            return calculationValues[attribute.id] && calculationValues[attribute.id] !== null
        }
    };

    let finishedSteps: string[] = [];

    // get all the finished step IDs
    stepConfig.forEach((step) => {
        const checkFinishedStep = (attributes: StepAttribute[]) => {
            if (attributes.length > 0 && calculationValues) {
                return attributes.every(hasValidValue)
            } else {
                return false;
            }
        }

        // if every validate key from the config is set, the step is finished and valid
        if (step.validate.every(v => {
            return calculationValues[v] && calculationValues[v] !== null
        })) {
            finishedSteps.push(step.id)
        }

        // if (checkFinishedStep(step.attributes)) {
        //     finishedSteps.push(step.id)
        // }
    })
    // TODO: This is only a workaround for the validation of steps which have another format
    // if (calculation.some(x => x.key === "chamberSurface")) {
    //     const stepName = "chamberStepChamberVolume"
    //     const index = stepConfig.findIndex((e) => e.id === stepName)
    //     finishedSteps.splice(index, 0, stepName);
    // }
    // if (calculation.some(x => x.key === "desorption")) {
    //     const stepName = "chamberStepDesorptionRate"
    //     const index = stepConfig.findIndex((e) => e.id === stepName)
    //     finishedSteps.splice(index, 0, stepName);
    // }

    finishedSteps = finishedSteps.filter(e => e !== "chamberResult");
    return finishedSteps;
}
export default getFinishedSteps;
