import React from "react";
import {Grid, Select} from "antd";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getUser, isLoggedIn} from "../../redux/selectors/user";

export interface Props {
    value: string,
    onChange: (value: string) => void,
    disabled?: boolean
}

export default function ResultSortSelect({value, onChange, disabled}: Props) {

    const {t} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const loggedIn = useSelector(isLoggedIn);
    const user = useSelector(getUser);
    const isApprovedUser = loggedIn && user.registrationStatus === "APPROVED";

    const {Option} = Select;

    // keys contain sort key and sort direction in the format "key_direction" so every key is unique
    const sortConfig = [
        {
            key: "totalEstimatedTime_desc",
            value: t('Pump down time (desc)')
        },
        {
            key: "totalEstimatedTime_asc",
            value: t('Pump down time (asc)')
        },
        {
            key: "relevancy_desc",
            value: t('Target pressure')
        },
        {
            key: "price_asc",
            value: t('Price'),
            disabled: !isApprovedUser
        },
        {
            key: "weight_asc",
            value: t('Weight'),
            disabled: true
        },
        {
            key: "noise_asc",
            value: t('Noise'),
            disabled: true
        }
    ]

    return (
        <Select
            value={value}
            onChange={(value ) => {
                onChange(value)
            }}
            style={{
                minWidth: screens.lg ? "200px" : "80%",
                margin: screens.lg ? "0" : "20px 0 0 28px"
        }}
            disabled={disabled}
        >
            {sortConfig.map((sortOption) => {
                return <Option
                    key={sortOption.key}
                    value={sortOption.key}
                    disabled={sortOption.disabled}
                >
                    {sortOption.value}
                </Option>
            })}
        </Select>
    )
}
