import {gql} from "@apollo/client";

export const SURFACE_LIST = gql`
    query SurfaceList($start: Int, $limit: Int) {
        surfaceList(start: $start, limit: $limit) {
            total
            surfaces {
                id
                insert
                update
                title
                desorptionRate
                desorptionRateUnit
            }
        }
    }
`;
