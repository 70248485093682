import {gql} from "@apollo/client";

export const COUNTRY_LIST = gql`
    query CountryList {
        countryList {
            total
            countries {
                isocode
                name
            }
        }
    }
`;
