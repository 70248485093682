import {gql} from "@apollo/client";

export const CREATE_CALCULATION_RESULT = gql`
    mutation CreateCalculationResult($id: ID!) {
        createCalculationResult(calculationId: $id) {
            id
            insert
            update
        }
    }
`;
