import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {useQuery} from "@apollo/client";
import {FeedbackBox, LoadingSpinner} from "@dreebit/pv-components";
import ChamberResultPumpCurveContent from "../../../components/ChamberResultPumpCurveContent";
import {CALCULATION_RESULT_CURVES} from "../../../graphql/queries/calculationResult-curves";
import {stepConfig} from "../../../config/fixedPumpSystemChamberEvacuationStepConfig";
import getUserRoles from "../../../hooks/getUserRoles";
import {getLastStepBeforeResult} from "../../../hooks/getPreviousStep";
import {toast} from "react-toastify";
import ToastContent from "../../../components/ToastContent";

interface Props {
    calculationMode: string
    calculationType: string
    onMobileContent: (content: string) => void,
    calculationReportConfig: any
}

export default function FixedPumpSystemResultPumpCurveRoute({calculationMode, calculationType, onMobileContent, calculationReportConfig}: Props) {

    const {t} = useTranslation();
    const {collectionId} = useParams<any>();
    const history = useHistory();

    const roles = getUserRoles();
    const isExpertUser = roles.includes('expert_vacuum_calculator');

    const {loading, error, data} = useQuery(CALCULATION_RESULT_CURVES, {
        variables: {
            id: collectionId
        },
        fetchPolicy: "network-only"
    });

    const calculationCollection = _.get(data, 'calculationCollection', undefined)
    const calculations = _.get(calculationCollection, 'calculations')
    const lastCollectionCalculationId = _.get(_.last(calculations), 'id')

    const lastStepBeforeResult = getLastStepBeforeResult(stepConfig);
    const lastStepId = _.get(lastStepBeforeResult, 'id', undefined)

    const onBackToResult = () => {
        if (lastCollectionCalculationId && lastStepId) {
            history.push(`/${calculationMode}/${calculationType}/${lastCollectionCalculationId}/${lastStepId}`);
        } else {
            toast.error(<ToastContent
                status={"error"}
                text={t('error_previous_step')}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        }
    }

    if (loading) {
        return <LoadingSpinner />
    }

    if (error) {
        return <FeedbackBox
            type={'error'}
            title={t('Error')}
            subtitle={error.message}
        />
    }

    return (
        <>
            <div className={'pt-40'}>
                <div>
                    <h1 className={'font-bold'}>{t('Vacuum Calculator')}</h1>
                    <h2>{t('All pump curves in comparison')}</h2>
                </div>
                <ChamberResultPumpCurveContent
                    stepConfig={stepConfig}
                    calculationMode={calculationMode}
                    calculationType={calculationType}
                    calculationCollection={calculationCollection}
                    onBackToResult={onBackToResult}
                    calculationReportConfig={calculationReportConfig}
                    showGasCurve={isExpertUser}
                    hideSortAndType={true}
                    onMobileContent={onMobileContent}
                />
            </div>
        </>
    )
}
