import {gql} from "@apollo/client";

export const DELETE_CALCULATION_COLLECTION = gql`
    mutation DeleteCalculationCollection($ids: [ID]!) {
        deleteCalculationCollection(calculationCollectionIds: $ids) {
            success
            message
        }
    }
`;
