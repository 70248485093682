import {gql} from "@apollo/client";

export const CALCULATION_COLLECTION_LIST = gql`
    query CalculationCollectionList($start: Int, $limit: Int, $calculationType: CalculationType, $calculationMode: CalculationMode, $childrenCount: Int) {
      calculationCollectionList(start: $start, limit: $limit, calculationType: $calculationType, calculationMode: $calculationMode, childrenCount: $childrenCount) {
        total
        calculationCollections {
          id
          calculations {
            id
          }
          calculationType
          calculationMode
          insert
          update
          title
        }
      }
    }
`;
