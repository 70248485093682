import React from "react";
import {useTranslation} from "react-i18next";
import "./index.css";
import MaterialIcon from "../MaterialIcon";
import {getPumpDownDisplayTime} from "../../utils/getPumpDownDisplayTime";
import {Grid} from "antd";
import getCurrentBreakpointsString from "../../utils/getCurrentBreakpointsString";

export interface Props {
    registrationStatus: "APPROVED" | "LEAD" | "OPEN" | "REJECTED" | null
    width: string,
    price?: string,
    noise?: string,
    weight?: string,
    pumpDownTime?: number
}

export default function SummaryCard(props: Props) {

    const {registrationStatus, width, price, noise, weight, pumpDownTime} = props;
    const {t} = useTranslation();

    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const matchingBreakpoints = getCurrentBreakpointsString(screens);

    if (registrationStatus === "APPROVED") {
        if (!price && !noise && !weight && !pumpDownTime) {
            return null;
        }

        return (
            <div
                className={`summary-card ${matchingBreakpoints}`}
                style={{width: width}}
            >
                <div className={'summary-card__headline'}>{t('Summary')}</div>
                <div className={'summary-card__text pt-8 pb-16'}>
                    {t('summary-card-text_approved-user')}
                </div>
                <div className={'info-list-wrapper'}>
                    {
                        pumpDownTime ?
                            <div className={'key-value-pair'}>
                                <div>{t('Pump down time')}</div>
                                <div className={'noise-label'}>≈ {getPumpDownDisplayTime(pumpDownTime)}</div>
                            </div>
                            : null
                    }
                    {/*{*/}
                    {/*    noise ?*/}
                    {/*        <div className={'key-value-pair'}>*/}
                    {/*            <div>{t('Noise')}</div>*/}
                    {/*            <div className={'noise-label'}>≈ {noise} dB(A)</div>*/}
                    {/*        </div>*/}
                    {/*        : null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    weight ?*/}
                    {/*        <div className={'key-value-pair'}>*/}
                    {/*            <div>{t('Weight')}</div>*/}
                    {/*            <div className={'noise-label'}>≈ {weight} kg</div>*/}
                    {/*        </div>*/}
                    {/*        : null*/}
                    {/*}*/}
                    {
                        price ?
                            <div className={'key-value-pair'}>
                                <div>{t('Price indication')}</div>
                                <div className={'price-label'}>≈ {price}</div>
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }

    return <div className={'user-hint-card'} style={{width: width, height: screens.lg ? "380px" : "auto"}}>
        <MaterialIcon name={'info_outline'} style={{fontSize: "22px"}}/>

        <p className={'user-message'}>
            {
                !registrationStatus ?
                    t('user-hint_not-logged-in')
                : registrationStatus === "LEAD" ?
                    t('user-hint_lead')
                : registrationStatus === "OPEN" ?
                    t('user-hint_open')
                : registrationStatus === "REJECTED" ?
                    t('user-hint_rejected')
                : null
            }
        </p>
    </div>

}
