import {gql} from "@apollo/client";

export const DELETE_SURFACE = gql`
    mutation DeleteSurface($id: ID!) {
        deleteSurface(surfaceId: $id) {
            success
            message
        }
    }
`;
