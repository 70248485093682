// @ts-nocheck
import React, {forwardRef, useImperativeHandle, useRef} from "react";
import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    ChartData
} from "chart.js";
import 'chart.js/auto';
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from 'chartjs-plugin-annotation';
import {useTranslation} from "react-i18next";
import {generateChartConfig} from "../../utils/generateChartConfig";
import {getDisplayUnitWithoutAttribute} from "../../utils/getDisplayUnit";
import {convertPressure, convertTime} from "../../utils/unitConverter";

ChartJS.register(zoomPlugin);
ChartJS.register(annotationPlugin);


export interface EvacuationChartSettings {
    xMin?: number,
    yMin?: number,
    xMax?: number,
    yMax?: number,
    xUnit?: "s" | "min" | "h",
    yUnit?: "Torr" | "Pa" | "hpa" | "mbar"
}

interface Props {
    chartData: ChartData<"line">,
    settings?: EvacuationChartSettings,
    targetPressure?: number,
    targetTime?: number
}

const EvacuationTimeChart = forwardRef(({chartData, settings, targetPressure, targetTime}: Props, ref) => {

    const {t} = useTranslation()
    const evacuationChartRef = useRef<ChartJS>(null);

    useImperativeHandle(ref, () => ({
        resetZoom() {
            evacuationChartRef.current.resetZoom();
        },
        returnChartConfig() {
            return generateChartConfig(evacuationChartRef?.current?.config);
        }
    }));

    const chartOptions = {
        hover: {
            mode: 'nearest',
            intersect: false
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: `${t('Time')} (${getDisplayUnitWithoutAttribute(settings?.xUnit)})`
                },
                min: settings?.xMin,
                max: settings?.xMax,
                display: true,
                type: 'linear',
                ticks:
                    settings?.xUnit === "h" ?
                        {
                            callback: function(value: string | number, index: any, ticks: any) {
                                return Math.round(value * 100) / 100
                            }
                        }
                    : settings?.xUnit === "min" ?
                        {
                            callback: function(value: string | number, index: any, ticks: any) {
                                return Math.round(value * 10) / 10
                            }
                        }
                    :
                        {
                            callback: function(value: string | number, index: any, ticks: any) {
                                return Math.round(value);
                            }
                        }
            },
            y: {
                title: {
                    display: true,
                    text: `${t('Pressure')} (${getDisplayUnitWithoutAttribute(settings?.yUnit)})`
                },
                min: settings?.yMin,
                max: settings?.yMax,
                display: true,
                type: "logarithmic",
                ticks: {
                    callback: function(value: string | number, index: any, ticks: any) {
                        const decadeNumbers = ["1e+5", "1e+4", "1e+3", "1e+2", "1e+1", "1e+0", "1e-1", "1e-2", "1e-3", "1e-4", "1e-5", "1e-6", "1e-7", "1e-8", "1e-9", "1e-10", "1e-11", "1e-12", "1e-13", "1e-14", "1e-15"]
                        const expValue = parseFloat(value).toExponential(0) || value.toExponential(0) || undefined

                        if (decadeNumbers.includes(expValue)) {
                            return expValue
                        }
                    }
                }
            }
        },
        elements: {
            point:{
                radius: 0
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    title: function (context) {
                        return context[0]?.dataset?.label || '';
                    },
                    beforeLabel(tooltipItem: Item): string | string[] {
                        if (settings?.xUnit === "d" || settings?.xUnit === "h") {
                            return Math.round(tooltipItem?.parsed?.x * 100) / 100 + ' ' + getDisplayUnitWithoutAttribute(settings?.xUnit) || '';
                        } else  if (settings?.xUnit === "min") {
                            return Math.round(tooltipItem?.parsed?.x * 10) / 10 + ' ' + getDisplayUnitWithoutAttribute(settings?.xUnit) || '';
                        }
                        return Math.round(tooltipItem?.parsed?.x) + ' ' + getDisplayUnitWithoutAttribute(settings?.xUnit) || '';
                    },
                    label: function(context) {
                        return context?.parsed?.y?.toExponential(2) + ' ' + getDisplayUnitWithoutAttribute(settings?.yUnit) || '';
                    }
                }
            },
            annotation: {
                annotations: {
                    targetTime: targetTime ? {
                        label: {
                            content: t('Target time'),
                            enabled: true,
                            position: 'start'
                        },
                        type: 'line',
                        xMin: convertTime(targetTime, settings?.xUnit),
                        xMax: convertTime(targetTime, settings?.xUnit),
                        borderColor: '#525252',
                        borderDash: [10, 15],
                        borderWidth: 1
                    } : null,
                    targetPressure: targetPressure ? {
                        label: {
                            content: t('Target pressure'),
                            enabled: true,
                            position: 'start'
                        },
                        type: 'line',
                        yMin: convertPressure(targetPressure, settings?.yUnit),
                        yMax: convertPressure(targetPressure, settings?.yUnit),
                        borderColor: '#525252',
                        borderDash: [10, 25],
                        borderWidth: 1
                    } : null
                }
            },
            zoom: {
                limits: {
                    x: {
                        min: 0
                    },
                    y: {
                        min: 0
                    }
                },
                zoom: {
                    drag: {
                        enabled: true
                    },
                    mode: 'xy',
                }
            }
        } as any
    }

    return (
        <div>
            <Line
                ref={evacuationChartRef}
                data={chartData}
                options={chartOptions}
            />
        </div>
    )
});

export default EvacuationTimeChart;
